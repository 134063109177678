/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useRef, useState } from "react";
import "./Product.css";
import { MdLibraryAdd } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import { Link } from "react-router-dom";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import Pagination from "react-js-pagination";
import {
  BiSolidRightArrow,
  BiSolidLeftArrow,
  BiSearchAlt,
} from "react-icons/bi";
import { TbFilterSearch } from "react-icons/tb";
import { ToastContainer, toast } from "react-toastify";
import { FaFilePdf } from "react-icons/fa6";
import { BsCardList } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import CurrencyFormat from "react-currency-format";
import moment from "moment/moment";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useFormik } from "formik";
import { AddProduct } from "../Schemas/Index";
import $ from "jquery";
import defaultImage from "../../Images/default.jpg";

export default function GetProducts() {
  const { state } = useContext(NoteState);
  const { branch_id, user_id } = state;
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [exportLink, setExportLink] = useState("");
  // update
  const [file, setFile] = useState("");
  const [supplier, setSupplier] = useState([]);
  const [category, setCategory] = useState([]);
  const [recordId, setRecordId] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("");
  const [selectedSale, setSelectedSale] = useState("");
  const [selectedQuantity, setSelectedQuantity] = useState("");
  const [selectedTax, setSelectedTax] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [showRow, setShowRow] = useState(false);

  const toggleRow = () => {
    setShowRow(!showRow);
  };
  var clickEvent = $.Event("click");
  const closeButtonRef = useRef(null);
  const getRecord = async (id) => {
    setLoading(true);
    try {
      const responce = await UserServices.getProductsInfo(id);
      if (responce.status === 200) {
        let data = responce.data.data.data;

        data.map((values) => {
          setRecordId(values.id);
          setSelectedName(values.name);
          setSelectedSupplier(values.supplier_id);
          setSelectedCategory(values.category_id);
          setSelectedProduct(values.product_code);
          setSelectedPrice(values.purchase_price);
          setSelectedSale(values.sale_price);
          setSelectedQuantity(values.quantity);
          setSelectedTax(values.tax);
          setSelectedDate(values.expire_date);
        });
        $("#product1").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const getProducts = async (page, limit) => {
    setTableLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.fethchPrdoucts(newLimit, pageNo);
      let myDataarr;

      if (responce.status === 200) {
        setExportLink(
          process.env.REACT_APP_API_Link +
            "exports/export-product?branch_id=" +
            branch_id +
            "&user_id=" +
            user_id
        );
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            name: values.name,
            type: values.type,
            status: values.status,
            sale_price: values.sale_price,
            purchase_price: values.purchase_price,
            supplier_name: values.supplier_name,
            product_code: values.product_code,
            tax: values.tax,
            expire_date: values.expire_date,
            category_name: values.category_name,
            quantity: values.quantity,
            image: values.image,
          });
          return null;
        });
        setTableLoading(false);
        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTableLoading(false);
        setExportLink();
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
      }
    }
  };
  const changeProductType = (e,page, limit) => {
    let productType = e;
    setTableLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    const getProductType = async () => {
      setTableLoading(true);

      try {
        const responce = await UserServices.filterPrdoucts(
          newLimit, pageNo, productType
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            name: values.name,
            type: values.type,
            status: values.status,
            sale_price: values.sale_price,
            purchase_price: values.purchase_price,
            supplier_name: values.supplier_name,
            product_code: values.product_code,
            tax: values.tax,
            expire_date: values.expire_date,
            category_name: values.category_name,
            quantity: values.quantity,
            image: values.image,
          });
          return null;
        });
        setTableLoading(false);
        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTableLoading(false);
          setExportLink();
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
        }
      }
    };
    getProductType();
  };
  const Suppliers = async () => {
    try {
      const responce = await UserServices.fethchSupplier();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setSupplier([{ name: "Select Supplier", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setSupplier([{ name: "No Supplier Found", id: "" }]);
    }
  };
  const Categories = async () => {
    try {
      const responce = await UserServices.fethchCategory();
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setCategory([{ name: "Select Category", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setCategory([{ name: "No Category Found", id: "" }]);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getProducts(currentPage, limit);
    Suppliers();
    Categories();
  }, []);
  const delRecord = async (id) => {
    try {
      const responce = await UserServices.productsActions(id, "deleted");
      if (responce.status === 200) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getProducts(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const makeActive = async (user_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.productsActions(user_Id, "active");
      if (responce.status === 200) {
        setLoading(false);
        toast.success("Activated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getProducts(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const makeInActive = async (user_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.productsActions(user_Id, "inactive");
      if (responce.status === 200) {
        setLoading(false);
        toast.error("Inactivated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getProducts(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  let searchTimer;
  const searchRecord = async (e) => {
    clearTimeout(searchTimer);

    searchTimer = setTimeout(async () => {
      var search_product = e.target.value;
      setLoading(true);
      try {
        const response = await UserServices.searchProduct(search_product);
        let myDataarr;
        if (response.status === 200) {
          myDataarr = response.data.data;
          const res = myDataarr;
          const results = [];
          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              type: values.type,
              status: values.status,
              sale_price: values.sale_price,
              purchase_price: values.purchase_price,
              supplier_name: values.supplier_name,
              product_code: values.product_code,
              tax: values.tax,
              expire_date: values.expire_date,
              category_name: values.category_name,
              image: values.image,
            });
            return null;
          });
          setMyData([...results]);
          setLoading(false);
        }
      } catch (err) {
        if (err.response?.status === 404) {
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 1000);
  };
  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");
    const tableOptions = {
      headStyles: { fillColor: [241, 132, 24], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10;
    let pageWidth = doc.internal.pageSize.width;
    let pageHeight = doc.internal.pageSize.height;
    tables.forEach((table, index) => {
      const captionText = table.getAttribute("data-caption");
      let tableHeight = doc.autoTable.previous.finalY - currentY;
      if (currentY + tableHeight + 20 > pageHeight) {
        doc.addPage();
        currentY = 10;
      }
      doc.text(captionText, 10, currentY);
      currentY += 5;
      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20;
      doc.setDrawColor(0);
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY);
      currentY = tableBottomY + 10;
    });
    doc.save("Products.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        {tableLoading ? (
          <SkeletonTheme height="40px">
            <Skeleton count={8} />
          </SkeletonTheme>
        ) : (
          <>
            <table
              className="table product-table table-striped"
              data-caption="Products"
            >
              <thead>
                <tr>
                  <th scope="col" className="no_sort">
                    Sr#
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("name")}
                    className={getClassNamesFor("name")}
                  >
                    Product
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("product_code")}
                    className={getClassNamesFor("product_code")}
                  >
                    SKU
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("category_name")}
                    className={getClassNamesFor("category_name")}
                  >
                    Category
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("supplier_name")}
                    className={getClassNamesFor("supplier_name")}
                  >
                    Supplier
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("sale_price")}
                    className={getClassNamesFor("sale_price")}
                  >
                    Sale Price
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("purchase_price")}
                    className={getClassNamesFor("purchase_price")}
                  >
                    Purchase Price
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("quantity")}
                    className={getClassNamesFor("quantity")}
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("tax")}
                    className={getClassNamesFor("tax")}
                  >
                    Tax
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("expire_date")}
                    className={getClassNamesFor("expire_date")}
                  >
                    Expire Date
                  </th>
                  <th scope="col" className="no_sort">
                    Status
                  </th>
                  <th scope="col" className="no_sort">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.length ? (
                  items.map((el, index) => (
                    <tr key={index}>
                      <td>
                        {currentPage === 1
                          ? index + 1
                          : (currentPage - 1) * limit + index + 1}
                      </td>
                      <td className="text-success fw-bold product-img">
                        {el.image !== "Null" ? (
                          <img src={el.image} alt="img" className="me-2" />
                        ) : (
                          <img
                            src={defaultImage}
                            alt="default"
                            className="me-2"
                          />
                        )}

                        {el.name}
                      </td>
                      <td>{el.product_code}</td>
                      <td>{el.category_name}</td>
                      <td>{el.supplier_name}</td>
                      <td>
                        <CurrencyFormat
                          value={el.sale_price}
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={el.purchase_price}
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td>{el.quantity}</td>
                      <td>
                        <CurrencyFormat
                          value={el.tax}
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          suffix={"%"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td>
                        {moment(el.expire_date, moment.ISO_8601).isValid()
                          ? moment(el.expire_date).format("ll")
                          : "N/A"}
                      </td>
                      <td>
                        <div className="d-none">{el.status}</div>
                        <div className="check-box mt-2">
                          <input
                            type="checkbox"
                            checked={el.status === "active"}
                            onChange={() => {
                              if (el.status === "active") {
                                makeInActive(el.id);
                              } else {
                                makeActive(el.id);
                              }
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="d-none">Delete</div>
                          <span
                            className="action-icons"
                            onClick={() => {
                              getRecord(el.id);
                            }}
                          >
                            <AiOutlineEdit />
                          </span>
                          <span
                            className="action-icons ms-2 text-danger"
                            onClick={() => delRecord(el.id)}
                          >
                            <RiDeleteBin5Line />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="14" className="text-center text-capitalize">
                      No record found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="d-flex">
              <div className=" col-md-1 mb-1 ms-1 d-flex">
                <div className="input-group limit-input">
                  <select
                    className="form-select"
                    value={limit}
                    onChange={(e) => getProducts("1", e.target.value)}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                  </select>
                </div>
              </div>
              <div className="ms-auto col-md-3">
                {totalResults > limit && totalPages > 1 ? (
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={parseInt(limit)}
                    totalItemsCount={totalResults}
                    onChange={(e) => {
                      getProducts(e, limit);
                    }}
                    pageRangeDisplayed={3}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText=<BiSolidLeftArrow />
                    lastPageText=<BiSolidRightArrow />
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  };
  const handleUpload = async (e) => {
    setFile(e.target.files[0]);
  };
  const updateProduct = async (values, action) => {
    const formData = new FormData();

    if (file !== "") {
      formData.append("image", file);
      formData.append("imageName", file.name);
    }

    formData.append("name", values.name);
    formData.append("category_id", values.category_id);
    formData.append("supplier_id", values.supplier_id);
    formData.append("product_code", values.product_code);
    formData.append("purchase_price", values.purchase_price);
    formData.append("sale_price", values.sale_price);
    formData.append("quantity", values.quantity);
    formData.append("tax", values.tax);
    formData.append("expire_date", values.expire_date);
    formData.append("id", recordId);
    try {
      setLoading(true);
      const response = await UserServices.ProductUpdate(formData);
      if (response.status === 200) {
        setLoading(false);
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        getProducts(currentPage, limit);
        closeButtonRef.current.click();
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  const initialValues = {
    name: selectedName,
    category_id: selectedCategory,
    supplier_id: selectedSupplier,
    product_code: selectedProduct,
    purchase_price: selectedPrice,
    sale_price: selectedSale,
    quantity: selectedQuantity,
    tax: selectedTax,
    expire_date: selectedDate,
  };
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      validationSchema: AddProduct,
      onSubmit: (values, action) => {
        updateProduct(values, action);
      },
    });
  return (
    <div className="wrapper">
      <ToastContainer />
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">Product</span> List
                </h4>
              </div>
              <div className="col-md-6 mt-2 d-flex justify-content-end">
                <Link to="/add-product" className="button active" type="button">
                  <MdLibraryAdd /> Add Product
                </Link>
              </div>
              <div className="row mt-3">
                <div className="main-card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 mt-2 heading">
                        <div className="d-flex">
                          <div onClick={toggleRow}>
                            {showRow ? (
                              <div className="filter-cancel">
                                <RxCross2 />
                              </div>
                            ) : (
                              <div className="filter-icon">
                                <TbFilterSearch />
                              </div>
                            )}
                          </div>
                          <form>
                            <div className="search-container ms-2">
                              <input
                                type="text"
                                placeholder="Search.."
                                onChange={(e) => {
                                  searchRecord(e);
                                }}
                              />
                              <div className="search-icon">
                                <BiSearchAlt />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="col-md-6 mt-2 d-flex justify-content-end mb-3">
                        <div
                          className="text-danger import-icons"
                          onClick={() => {
                            generatePdf(document.getElementById("products"));
                          }}
                        >
                          <FaFilePdf />
                        </div>
                        <a
                          href={exportLink}
                          rel="noreferrer"
                          className="text-success import-icons ms-2"
                        >
                          <BsCardList />
                        </a>
                      </div>
                    </div>
                    {showRow && (
                      <div className="row mb-3" >
                        <div className="col-md-2">
                          <div className="form-group custom-group">
                            <select
                              className="form-select"
                              name="type"
                              value={values.type}
                              onChange={(e) => changeProductType(e.target.value, currentPage, limit)}
                              onBlur={handleBlur}
                            >
                              <option disabled={true} defaultValue={true}>Select Type</option>
                              <option value="available">Available Products</option>
                              <option value="out_of_stock">Out Of stock Products</option>
                              <option value="expire">Expired Products</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-12 product">
                      <div className="table-responsive">
                        <Table data={myData} rowsPerPage={limit} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="product1"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update Product
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="card-body">
                  <p className="text-secondary">
                    <strong className="text-danger">!</strong> The Fields With{" "}
                    <strong className="text-danger">*</strong>
                    Must Required Or Fill.
                  </p>
                  <div className="row g-3">
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Product Name{" "}
                          <strong className="text-danger">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Product Name"
                          autoComplete="off"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name ? (
                          <p className="text-danger">{errors.name}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Supplier
                          <strong className="text-danger">*</strong>
                        </label>
                        {supplier.length === 0 ? (
                          <SkeletonTheme height="40px">
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        ) : (
                          <select
                            className="form-select"
                            name="supplier_id"
                            value={values.supplier_id || ""}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          >
                            {supplier.map((el, index) => {
                              return (
                                <option
                                  key={index}
                                  value={el.id}
                                  disabled={el.id === ""}
                                >
                                  {el.name}
                                </option>
                              );
                            })}
                          </select>
                        )}

                        {errors.supplier_id && touched.supplier_id ? (
                          <p className="text-danger">{errors.supplier_id}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Category
                          <strong className="text-danger">*</strong>
                        </label>
                        {category.length === 0 ? (
                          <SkeletonTheme height="40px">
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        ) : (
                          <select
                            className="form-select"
                            name="category_id"
                            value={values.category_id || ""}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          >
                            {category.map((el, index) => {
                              return (
                                <option
                                  key={index}
                                  value={el.id}
                                  disabled={el.id === ""}
                                >
                                  {el.name}
                                </option>
                              );
                            })}
                          </select>
                        )}

                        {errors.category_id && touched.category_id ? (
                          <p className="text-danger">{errors.category_id}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Product Code{" "}
                          <strong className="text-danger">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Product Code"
                          autoComplete="off"
                          name="product_code"
                          value={values.product_code}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.product_code && touched.product_code ? (
                          <p className="text-danger">{errors.product_code}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Purchase Price{" "}
                          <strong className="text-danger">*</strong>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Purchase Price"
                          autoComplete="off"
                          name="purchase_price"
                          value={values.purchase_price}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.purchase_price && touched.purchase_price ? (
                          <p className="text-danger">{errors.purchase_price}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Sale Price <strong className="text-danger">*</strong>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Sale Price"
                          autoComplete="off"
                          name="sale_price"
                          value={values.sale_price}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.sale_price && touched.sale_price ? (
                          <p className="text-danger">{errors.sale_price}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Quantity <strong className="text-danger">*</strong>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Quantity"
                          autoComplete="off"
                          name="quantity"
                          value={values.quantity}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.quantity && touched.quantity ? (
                          <p className="text-danger">{errors.quantity}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Tax Percentage{" "}
                          <strong className="text-danger">*</strong>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Tax percentage"
                          autoComplete="off"
                          name="tax"
                          value={values.tax}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.tax && touched.tax ? (
                          <p className="text-danger">{errors.tax}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">Expire Date</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="11-03-2022"
                          name="expire_date"
                          value={values.expire_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.expire_date && touched.expire_date ? (
                          <p className="text-danger">{errors.expire_date}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">Upload Image</label>
                        <div className="file-input-container">
                          {/* <label htmlFor="fileInput" className="upload-icon">
                            <span className="me-2 upload-image-icon">
                              <BiCloudUpload />
                            </span>
                            <span className="upload-image-label mt-2">
                              Upload Image
                            </span>
                          </label> */}
                          <input
                            id="fileInput"
                            className="form-control"
                            type="file"
                            onClick={(e) => {
                              e.target.value = null;
                              setFile("");
                            }}
                            onChange={handleUpload}
                            accept=".png, .jpg, .jpeg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 ">
                      <div className="col-12 ">
                        <button className="btn1" type="submit">
                          <span>Update</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="main-spin">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  );
}
