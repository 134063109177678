import { useContext, useEffect, useState } from "react";
import "./App.css";
import NavBar from "./Components/Includes/NavBar/NavBar";
import SideBar from "./Components/Includes/SideBar/SideBar";
import Dashboard from "./Components/Dashboard/Dashboard";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Expenses from "./Components/Expenses/Expenses";
import GetProducts from "./Components/Products/GetProducts";
import AddProducts from "./Components/Products/AddProducts";
import Login from "./Components/Login/Login";
import { NoteState } from "./context/notes/NoteState";
import jwtDecode from "jwt-decode";
import GroupPermission from "./Components/Permissions/GroupPermission";
import CreatePermission from "./Components/Permissions/CreatePermission";
import NewUser from "./Components/Permissions/NewUser";
import AddRole from "./Components/Permissions/AddRole";
import AddMenu from "./Components/Permissions/AddMenu";
import AddMainMenu from "./Components/Permissions/AddMainMenu";
import AddCategories from "./Components/Products/AddCategories";
import AddCompany from "./Components/Comapnies/AddCompany";
import AddBranches from "./Components/Comapnies/AddBranches";
import AddSupplier from "./Components/Products/AddSupplier";
import AddCustomers from "./Components/Permissions/AddCustomers";
import EmployeList from "./Components/Permissions/EmployeList";
import AddDesignation from "./Components/Permissions/AddDesignation";
import AddEmployee from "./Components/Permissions/AddEmployee";
import { ToastContainer, toast } from "react-toastify";
import ViewEmployee from "./Components/Permissions/ViewEmployee";
import EmployeProfile from "./Components/Permissions/EmployeProfile";
import ProductsCart from "./Components/Products/ProductsCart";
import BillingDetail from "./Components/Products/BillingDetail";
import AllBills from "./Components/Products/AllBills";
import ProductsHoldList from "./Components/Products/ProductsHoldList";
import OrdersList from "./Components/Sales/OrdersList";
import OrderDetail from "./Components/Sales/OrderDetail";
import OrderPaymentDetail from "./Components/Sales/OrderPaymentDetail";
import ProductsBarCodeGenerator from "./Components/Products/ProductsBarCodeGenerator";
import PurchaseCart from "./Components/Purchase/PurchaseCart";
import axios from "axios";
import PurchaseOrdersList from "./Components/Purchase/PurchaseOrdersList";
import PurchaseHoldList from "./Components/Purchase/PurchaseHoldList";
import PurchaseOrderDetail from "./Components/Purchase/PurchaseOrderDetail";
import PurchaseOrderPaymentDetail from "./Components/Purchase/PurchaseOrderPaymentDetail";
import ManagePaymentsTypes from "./Components/Payments/ManagePaymentsTypes";
import ManageTransactionTypes from "./Components/Ledgers/ManageTransactionTypes";
import ManageLedgersTypes from "./Components/Ledgers/ManageLedgersTypes";
import ManageBankAccounts from "./Components/Banks/ManageBankAccounts";
import AddCurrency from "./Components/DashboadSetting/AddCurrency";
import PurchasesImport from "./Components/Purchase/PurchasesImport";
import SalesReport from "./Components/Reports/SalesReport";
import PurchaseReport from "./Components/Reports/PurchaseReport";
import InventoryReport from "./Components/Reports/InventoryReport";
import SupplierReport from "./Components/Reports/SupplierReport";
import CustomerReport from "./Components/Reports/CustomerReport";
import AddExpense from "./Components/Expences/AddExpense";
import ViewExpense from "./Components/Expences/ViewExpense";
import ExpenseCategory from "./Components/Expences/ExpenseCategory";
import ExpenseSubCategory from "./Components/Expences/ExpenseSubCategory";
import UpdateExpense from "./Components/Expences/UpdateExpense";
import AddLiabilities from "./Components/Liabilities/AddLiabilities";
import ViewLiabilities from "./Components/Liabilities/ViewLiabilities";
import AddLedger from "./Components/Ledgers/AddLedger";
import ViewLedger from "./Components/Ledgers/ViewLedger";
import UpdateLedger from "./Components/Ledgers/UpdateLedger";
import UpdateLiabilities from "./Components/Liabilities/UpdateLiabilities";
import ReturnLiabilities from "./Components/Liabilities/ReturnLiabilities";
import BalanceSheet from "./Components/BalanceSheet/BalanceSheet";
import DailySaleCounter from "./Components/DailySaleCounter/DailySaleCounter";
import SalesReturn from "./Components/Returns/SalesReturn";
import PurchaseReturn from "./Components/Returns/PurchaseReturn";
function App() {
  const locate = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const { state, dispatch: ctxDispatch } = useContext(NoteState);
  const { accessToken } = state;
  const navigate = useNavigate();

  useEffect(() => {
    if (locate.pathname.match(/\/{2,}/)) {
      window.location.href = "/dashboard";
    }

    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 429) {
          toast.error("Too many requests!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (accessToken) {
      var decoded = jwtDecode(accessToken);
      if (Math.floor(new Date().getTime() / 1000) > decoded.exp) {
        ctxDispatch({
          type: "LOGOUT",
        });

        navigate("/");
      } else if (locate.pathname === "/") {
        navigate("/dashboard");
      }
    } else {
      navigate("/");
    }
  }, [locate.pathname]);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    const siteMainDesign = document.querySelector(".site-main-design");
    if (siteMainDesign) {
      siteMainDesign.classList.toggle("collapsed", !collapsed);
    }
  };
  return (
    <>
      {locate.pathname !== "/" ? (
        <>
          <NavBar collapsed={collapsed} onToggleSidebar={toggleSidebar} />
          <SideBar collapsed={collapsed} />
        </>
      ) : null}

      <ToastContainer />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/expenses" element={<Expenses />} />
        <Route path="/add-product" element={<AddProducts />} />
        <Route path="/product-list" element={<GetProducts />} />
        <Route path="/view-user" element={<GroupPermission />} />
        <Route path="/create-permissions" element={<CreatePermission />} />
        <Route path="/new-user" element={<NewUser />} />
        <Route path="/add-role" element={<AddRole />} />
        <Route path="/add-sub-menu" element={<AddMenu />} />
        <Route path="/add-menu" element={<AddMainMenu />} />
        <Route path="/add-category" element={<AddCategories />} />

        <Route path="/add-supplier" element={<AddSupplier />} />
        <Route path="/add-company" element={<AddCompany />} />
        <Route path="/add-branches" element={<AddBranches />} />
        <Route path="/manage-customers" element={<AddCustomers />} />
        <Route path="/employee-list" element={<EmployeList />} />
        <Route path="/manage-designation" element={<AddDesignation />} />
        <Route path="/add-employee" element={<AddEmployee />} />
        <Route path="/employee" element={<ViewEmployee />} />
        <Route path="/employe-profile/:id" element={<EmployeProfile />} />
        <Route path="/cart" element={<ProductsCart />} />
        <Route path="/orders" element={<OrdersList />} />
        <Route path="/order-detail/:id" element={<OrderDetail />} />
        <Route
          path="/order-payment-detail/:id"
          element={<OrderPaymentDetail />}
        />
        <Route path="/hold-list" element={<ProductsHoldList />} />

        <Route path="/purchase-cart" element={<PurchaseCart />} />
        <Route path="/purchase-hold" element={<PurchaseHoldList />} />
        <Route path="/purchase-orders" element={<PurchaseOrdersList />} />
        <Route
          path="/purchase-order-detail/:id"
          element={<PurchaseOrderDetail />}
        />

        <Route
          path="/purchase-order-payment-detail/:id"
          element={<PurchaseOrderPaymentDetail />}
        />

        <Route path="/billing-details/:id" element={<BillingDetail />} />
        <Route path="/all-bills" element={<AllBills />} />
        <Route
          path="/generate-barcode"
          element={<ProductsBarCodeGenerator />}
        />
        <Route path="/purchases-import" element={<PurchasesImport />} />
        <Route path="/payment-types" element={<ManagePaymentsTypes />} />
        {/* <Route path="/transaction-types" element={<ManageTransactionTypes />} />
        <Route path="/ledger-types" element={<ManageLedgersTypes />} /> */}
        <Route path="/bank-accounts" element={<ManageBankAccounts />} />

        {/* Setting Routes */}
        <Route path="/add-currency" element={<AddCurrency />} />

        {/* Reports */}
        <Route path="/sales-report" element={<SalesReport />} />
        <Route path="/purchase-report" element={<PurchaseReport />} />
        <Route path="/inventory-report" element={<InventoryReport />} />
        <Route path="/supplier-report" element={<SupplierReport />} />
        <Route path="/customer-report" element={<CustomerReport />} />

        {/* Expenses */}
        <Route path="/add-expense" element={<AddExpense />} />
        <Route path="/update-expense/:id" element={<UpdateExpense />} />
        <Route path="/view-expense" element={<ViewExpense />} />
        <Route path="/expense-category" element={<ExpenseCategory />} />

        <Route path="/add-liabilities" element={<AddLiabilities />} />
        <Route path="/view-liabilities" element={<ViewLiabilities />} />
        <Route path="/update-liabilities/:id" element={<UpdateLiabilities />} />
        <Route path="/return-liability/:id" element={<ReturnLiabilities />} />

        <Route path="/add-ledger" element={<AddLedger />} />
        <Route path="/view-ledger" element={<ViewLedger />} />
        <Route path="/update-ledger/:id" element={<UpdateLedger />} />


        <Route path="/daily-sale-counter" element={<DailySaleCounter />} />

        <Route path="/balance-sheet" element={<BalanceSheet />} />

        <Route path="/sales-return" element={<SalesReturn />} />
        <Route path="/purchase-return" element={<PurchaseReturn />} />
      </Routes>
    </>
  );
}

export default App;
