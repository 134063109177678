import React, { useEffect, useState } from "react";
import "./Permissions.css";
import { Link } from "react-router-dom";
import { BiSearchAlt } from "react-icons/bi";
import { useFormik } from "formik";
import { AddUser } from "../Schemas/Index";
import UserServices from "../../UserServices/UserServices";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export default function NewUser() {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [comapny, setCompany] = useState([]);
  const [brnch, setBrnch] = useState([]);

  const getRoles = async () => {
    try {
      const responce = await UserServices.UserRoles();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setRoles([{ name: "Select Role", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  /* eslint-disable react-hooks/exhaustive-deps */

  const getCompany = async () => {
    try {
      const responce = await UserServices.activeCompany();
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setCompany([{ name: "Select Company", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setCompany([]);
    }
  };
  const selectBranch = async (e) => {
    var company_Id = e;
  
    try {
      const responce = await UserServices.CompanyBranch(company_Id);
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setBrnch([{ name: "Select Branch", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setBrnch([]);
    }
  };
  useEffect(() => {
    getRoles();
    getCompany();
  }, []);

  const verifyEmail = async (e) => {
    var check_email = e.target.value;
    try {
      const response = await UserServices.checkEmail(check_email);
      if (response.status === 200) {
        setEmailError("");
      }
    } catch (err) {
      if (err.response?.status === 409) {
        setEmailError("Email already exists");
      }
    }
  };
  const addUsers = async (values, action) => {
    if (emailError === "") {
      try {
        setLoading(true);
        const response = await UserServices.UsersAdd(values);

        if (response.status === 200) {
          setLoading(false);
          toast.success("Added Successfully!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          action.resetForm();
        }
      } catch (err) {
        setLoading(false);
        if (err.response.status === 422) {
          toast.error("Unproccessable content!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (err.response.status === 500) {
          toast.error("Unproccessable content!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (err.response.status === 400) {
          toast.error("Unproccessable content!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };
  const initialValues = {
    name: "",
    email: "",
    password: "",
    role: "",
    company:"",
    branch:""
  };
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: AddUser,
      onSubmit: (values, action) => {
        addUsers(values, action);
      },
    });
  return (
    <div className="wrapper">
      <ToastContainer />
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">User </span> Management
                </h4>
                <p className="text-secondary">Add User</p>
              </div>
              <div className="col-md-6 mt-2 d-flex justify-content-end">
                <Link
                  to="/view-user"
                  className="button active"
                  type="button"
                >
                  <BiSearchAlt /> View Users
                </Link>
              </div>
            </div>
            <div className="row mt-3">
              <div className="main-card">
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div className="card-body">
                    <p className="text-secondary">
                      <strong className="text-danger">!</strong> The Fields With{" "}
                      <strong className="text-danger">*</strong>
                      Must Required Or Fill.
                    </p>
                    <div className="row g-3">
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Name <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter User Name"
                            autoComplete="off"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.name && touched.name ? (
                            <p className="text-danger">{errors.name}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Email <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter User Email"
                            autoComplete="off"
                            name="email"
                            value={values.email || ""}
                            onChange={async (e) => {
                              handleChange(e);
                              verifyEmail(e);
                            }}
                            onBlur={handleBlur}
                          />
                          {emailError ? (
                            <p className="text-danger">{emailError}</p>
                          ) : null}
                          {errors.email && touched.email ? (
                            <p className="text-danger">{errors.email}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Password <strong className="text-danger">*</strong>
                          </label>
                          <div className="password-input-container">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              placeholder="Enter Password"
                              autoComplete="off"
                              name="password"
                              value={values.password || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <span
                              className="toggle-password-icon"
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? (
                                <AiOutlineEyeInvisible />
                              ) : (
                                <AiOutlineEye />
                              )}
                            </span>
                          </div>
                          {errors.password && touched.password ? (
                            <p className="text-danger">{errors.password}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                    <label className="form-label">
                    Available Companies<strong className="text-danger">*</strong>
                          </label>
                      {comapny ? (
                        <select
                          className="form-select"
                          name="company"
                          value={values.company || ""}
                          onChange={(e) => {
                            handleChange(e);
                            selectBranch(e.target.value)
                          }}
                          onBlur={handleBlur}
                        >
                          {comapny.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.company && touched.company ? (
                        <p className="text-danger">{errors.company}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Select Branch<strong className="text-danger">*</strong>
                      </label>
                      {brnch.length ? (
                        <select
                          className="form-select"
                          name="branch"
                          value={values.branch}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {brnch.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        <select
                          className="form-select"
                          autoComplete="off"
                          name="branch"
                          value={values.branch}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">Select Branch</option>
                        </select>
                      )}
                      {errors.branch && touched.branch ? (
                        <p className="text-danger">{errors.branch}</p>
                      ) : null}
                    </div>{" "}
                  </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Role<strong className="text-danger">*</strong>
                          </label>
                          {roles ? (
                            <select
                              className="form-select"
                              name="role"
                              value={values.role || ""}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                            >
                              {roles.map((el, index) => {
                                return (
                                  <option key={index} value={el.id}>
                                    {el.name}
                                  </option>
                                );
                              })}
                            </select>
                          ) : null}
                          {errors.role && touched.role ? (
                            <p className="text-danger">{errors.role}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 ">
                        <button className="btn1"><span>Add</span></button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="main-spin">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  );
}
