/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef, useContext } from "react";
import { useFormik } from "formik";
import { CategoryAdd } from "../Schemas/Index";
import UserServices from "../../UserServices/UserServices";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaFilePdf } from "react-icons/fa6";
import { BsCardList } from "react-icons/bs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  BiSolidRightArrow,
  BiSolidLeftArrow,
  BiSearchAlt,
} from "react-icons/bi";
import $ from "jquery";
import { NoteState } from "../../context/notes/NoteState";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function AddCategories() {
  const { state } = useContext(NoteState);
  const { branch_id, user_id } = state;
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [categoryError, setCategoryError] = useState("");
  const [limit, setLimit] = useState("25");
  const [myData, setMyData] = useState([]);
  const [exportLink, setExportLink] = useState("");
  // update
  const [recordId, setRecordId] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedtype, setSelectedType] = useState("");
  const [description, setDescription] = useState("");
  const [updateError, setUpdateError] = useState("");

  var clickEvent = $.Event("click");
  const closeButtonRef = useRef(null);
  const getRecord = async (id) => {
    setLoading(true);
    try {
      const responce = await UserServices.getCategoryInfo(id);
      if (responce.status === 200) {
        let data = responce.data.data;

        data.map((values) => {
          setRecordId(values.id);
          setSelectedName(values.name);
          setDescription(values.description);
          setSelectedType(values.type);
        });
        $("#branch1").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const getCategory = async () => {
    setTableLoading(true);
    try {
      const responce = await UserServices.fethchCategory();
      let myDataarr;

      if (responce.status === 200) {
        setExportLink(
          process.env.REACT_APP_API_Link +
            "exports/export-category?branch_id=" +
            branch_id +
            "&user_id=" +
            user_id
        );
        myDataarr = responce.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            name: values.name,
            type: "products",
            status: values.status,
            description: values.description,
          });
          return null;
        });
        setTableLoading(false);
        setMyData([...results]);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTableLoading(false);
        setMyData([]);
        setExportLink();
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getCategory();
  }, []);
  const delRecord = async (id) => {
    try {
      const responce = await UserServices.categoryActions(id, "deleted");
      if (responce.status === 200) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getCategory();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const makeActive = async (user_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.categoryActions(user_Id, "active");
      if (responce.status === 200) {
        setLoading(false);
        toast.success("Activated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getCategory();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const makeInActive = async (user_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.categoryActions(user_Id, "inactive");
      if (responce.status === 200) {
        setLoading(false);
        toast.error("Inactivated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getCategory();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const verifyCategory = async (e) => {
    var check_cartegory = e.target.value;
    try {
      const response = await UserServices.checkCategory(check_cartegory);
      if (response.status === 200) {
        setCategoryError("");
      }
    } catch (err) {
      if (err.response?.status === 409) {
        setCategoryError("Category already exists");
      }
    }
  };
  const addCategory = async (values, action) => {
    if (categoryError === "") {
      try {
        setLoading(true);
        const response = await UserServices.CatgryAdd(values);
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          toast.success("Added Successfully!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          action.resetForm();
          getCategory();
        }
      } catch (err) {
        setLoading(false);
        if (err.response.status === 422) {
          toast.error("Unproccessable content!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (err.response.status === 500) {
          toast.error("Unproccessable content!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (err.response.status === 400) {
          toast.error("Unproccessable content!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };
  const initialValues = {
    name: "",
    type: "products",
    description: "N/A",
  };
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: CategoryAdd,
      onSubmit: (values, action) => {
        addCategory(values, action);
      },
    });
  let searchTimer;
  const searchRecord = async (e) => {
    clearTimeout(searchTimer);

    searchTimer = setTimeout(async () => {
      var search_category = e.target.value;
      setLoading(true);
      try {
        const response = await UserServices.searchCategory(search_category);
        let myDataarr;
        if (response.status === 200) {
          myDataarr = response.data.data;
          const res = myDataarr;
          const results = [];
          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              type: "products",
              status: values.status,
              description: values.description,
            });
            return null;
          });
          setMyData([...results]);
          setLoading(false);
        }
      } catch (err) {
        if (err.response?.status === 404) {
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 1000);
  };
  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    const captionText = table.getAttribute("data-caption");

    doc.text(captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [241, 132, 24], textColor: 255 },
      styles: { fontSize: 5 },
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("Categories.pdf");
  };
  const Table = ({ data }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, limit);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive ">
              {tableLoading ? (
                <SkeletonTheme height="40px">
                  <Skeleton count={7} />
                </SkeletonTheme>
              ) : (
                <table
                  className="table product-table table-striped"
                  id="categories"
                  data-caption="Categories"
                >
                  <thead>
                    <tr>
                      <th scope="col" className="no_sort">
                        Sr#
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("name")}
                        className={getClassNamesFor("name")}
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("type")}
                        className={getClassNamesFor("type")}
                      >
                        Type
                      </th>
                      <th scope="col" className="no_sort">
                        Description
                      </th>
                      <th scope="col" className="no_sort">
                        Status
                      </th>
                      <th scope="col" className="no_sort">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.length ? (
                      items.map((el, index) => (
                        <tr key={index}>
                          <td>
                            {page === 1
                              ? index + 1
                              : (page - 1) * limit + index + 1}
                          </td>
                          <td>{el.name}</td>
                          <td>{el.type}</td>
                          <td>{el.description}</td>
                          <td>
                            <div className="d-none">{el.status}</div>
                            <div className="check-box mt-2">
                              <input
                                type="checkbox"
                                checked={el.status === "active"}
                                onChange={() => {
                                  if (el.status === "active") {
                                    makeInActive(el.id);
                                  } else {
                                    makeActive(el.id);
                                  }
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="d-none">Delete</div>
                            <div className="d-flex">
                              <span
                                className="action-icons"
                                onClick={() => {
                                  getRecord(el.id);
                                }}
                              >
                                <AiOutlineEdit />
                              </span>
                              <span
                                className="action-icons ms-2 text-danger"
                                onClick={() => delRecord(el.id)}
                              >
                                <RiDeleteBin5Line />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="14"
                          className="text-center text-capitalize"
                        >
                          No record found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
              <div className="d-flex">
                <div className=" col-md-1 mb-1 d-flex ms-1">
                  {myData.length > 0 ? (
                    <div className="input-group limit-input">
                      <select
                        className="form-select"
                        onChange={(e) => {
                          setLimit(e.target.value);
                        }}
                        value={limit}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                        <option value={myData.length}>All</option>
                      </select>
                    </div>
                  ) : null}
                </div>
                <div className="ms-auto col-md-3">
                  {data.length && data.length > limit ? (
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={limit}
                      totalItemsCount={data.length}
                      onChange={(e) => {
                        setPage(e);
                      }}
                      pageRangeDisplayed={3}
                      itemClass="page-item"
                      linkClass="page-link"
                      firstPageText=<BiSolidLeftArrow />
                      lastPageText=<BiSolidRightArrow />
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const verifyCategoryUpdate = async (e) => {
    var update_category = e.target.value;
    try {
      const response = await UserServices.checkUpdateCategory(
        update_category,
        recordId
      );
      if (response.status === 200) {
        setUpdateError("");
      }
    } catch (err) {
      if (err.response?.status === 409) {
        setUpdateError("Category already exists");
      }
    }
  };
  const UpdateRecord = async (updateValues) => {
    if (updateError === "") {
      setLoading(true);

      try {
        const response = await UserServices.UpdateCategory(
          updateValues,
          recordId
        );
        if (response.status === 200) {
          setLoading(false);
          toast.success("Updated Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setLoading(false);
          getCategory();
          closeButtonRef.current.click();
        }
      } catch (err) {
        if (err.response.status === 422) {
          toast.error("Some data is missing", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setLoading(false);
        } else {
          toast.error("Some thing went wronge !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      }
    }
  };
  const updateinitialValues = {
    name: selectedName,
    type: selectedtype,
    description: description,
  };
  const {
    values: updateValues,
    errors: updateErrors,
    touched: updateTouched,
    handleBlur: updateHandleBlur,
    handleChange: updateHandleChange,
    handleSubmit: updateHandleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: updateinitialValues,
    validationSchema: CategoryAdd,
    onSubmit: (updateValues) => {
      UpdateRecord(updateValues);
    },
  });
  return (
    <div className="wrapper">
      <ToastContainer />
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">Products</span> Categories
                </h4>
                <p className="text-secondary">Add Categories</p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="main-card">
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <p className="text-secondary">
                      <strong className="text-danger">!</strong> The Field With
                      <strong className="text-danger">*</strong>
                      Must Required Or Fill.
                    </p>
                    <div className="row g-3">
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Name <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Category Name"
                            autoComplete="off"
                            name="name"
                            value={values.name}
                            onChange={async (e) => {
                              handleChange(e);
                              verifyCategory(e);
                            }}
                            onBlur={handleBlur}
                          />
                          {categoryError ? (
                            <p className="text-danger">{categoryError}</p>
                          ) : null}
                          {errors.name && touched.name ? (
                            <p className="text-danger">{errors.name}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Type <strong className="text-danger">*</strong>
                          </label>
                          <select
                            className="form-select"
                            name="type"
                            disabled
                            value={values.type}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          >
                            <option value={-1}>Select Type</option>
                            <option value="products">Products</option>
                            <option value="expenses">Expenses</option>
                          </select>
                          {errors.type && touched.type ? (
                            <p className="text-danger">{errors.type}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">Description</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Role Description"
                            autoComplete="off"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.description && touched.description ? (
                            <p className="text-danger">{errors.description}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 ">
                        <button className="btn1" type="submit">
                          <span>Add</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">View</span> Categories
                </h4>
                <p className="text-secondary">View/Edit Categories</p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="main-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6 mt-2 heading">
                      <form className="me-3">
                        <div className="search-container">
                          <input
                            type="text"
                            placeholder="Search.."
                            onChange={(e) => {
                              searchRecord(e);
                            }}
                          />
                          <div className="search-icon">
                            <BiSearchAlt />
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-6 mt-2 d-flex justify-content-end mb-3">
                      <div
                        className="text-danger import-icons"
                        onClick={() => {
                          generatePdf(document.getElementById("categories"));
                        }}
                      >
                        <FaFilePdf />
                      </div>
                      <a
                        href={exportLink}
                        rel="noreferrer"
                        className="text-success import-icons ms-2"
                      >
                        <BsCardList />
                      </a>
                    </div>
                  </div>
                  <div className="col-12">
                    <Table data={myData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="branch1"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update Category
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-3" onSubmit={updateHandleSubmit}>
                <div className="row g-3">
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Name <strong className="text-danger">*</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Category Name"
                        autoComplete="off"
                        name="name"
                        value={updateValues.name}
                        onChange={async (e) => {
                          updateHandleChange(e);
                          verifyCategoryUpdate(e);
                        }}
                        onBlur={updateHandleBlur}
                      />
                      {updateError ? (
                        <p className="text-danger">{updateError}</p>
                      ) : null}
                      {updateErrors.name && updateTouched.name ? (
                        <p className="text-danger">{updateErrors.name}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Type <strong className="text-danger">*</strong>
                      </label>
                      <select
                        className="form-select"
                        name="type"
                        disabled
                        value={updateValues.type}
                        onChange={(e) => {
                          updateHandleChange(e);
                        }}
                        onBlur={updateHandleBlur}
                      >
                        <option value={-1}>Select Type</option>
                        <option value="products">Products</option>
                        <option value="expenses">Expenses</option>
                      </select>
                      {updateErrors.type && updateTouched.type ? (
                        <p className="text-danger">{updateErrors.type}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Role Description"
                        autoComplete="off"
                        name="description"
                        value={updateValues.description}
                        onChange={updateHandleChange}
                        onBlur={updateHandleBlur}
                      />
                      {updateErrors.description && updateTouched.description ? (
                        <p className="text-danger">
                          {updateErrors.description}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 ">
                    <button className="btn1" type="submit">
                      <span>Update</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="main-spin">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  );
}
