import React, { useEffect, useState } from "react";

import { FaFilePdf } from "react-icons/fa6";

import useSortableData from "../../hooks/useSortableData";

import jsPDF from "jspdf";
import "jspdf-autotable";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import UserServices from "../../UserServices/UserServices";

import { useParams } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";

export default function OrderDetail() {
  const [myData, setMyData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [orderData, setOrderData] = useState([]);

  const { id } = useParams();

  const getHold = async () => {
    setTableLoading(true);

    try {
      const responce = await UserServices.fetchOrderDetail(id);
      let myDataarr;
      myDataarr = responce?.data?.data?.order_details;

      const res = myDataarr;
      const results = [];

      res.map((values) => {
        results.push({
          discount: values.discount ? values.discount : 0,
          code: values.code,
          name: values.name,
          price: values.price,
          quantity: values.quantity,
          tax: values.tax,
          total: values.total,
          id: values.id,
        });
        return null;
      });
      setTableLoading(false);
      setMyData([...results]);
      setCustomerData(responce?.data?.data?.customer_info);
      setOrderData(responce?.data?.data?.order);
    } catch (err) {
      console.log(err);
      setTableLoading(false);

      setMyData([]);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getHold();
  }, []);
  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");
    const tableOptions = {
      headStyles: { fillColor: [241, 132, 24], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10;
    let pageWidth = doc.internal.pageSize.width;
    let pageHeight = doc.internal.pageSize.height;
    tables.forEach((table, index) => {
      const captionText = table.getAttribute("data-caption");
      let tableHeight = doc.autoTable.previous.finalY - currentY;
      if (currentY + tableHeight + 20 > pageHeight) {
        doc.addPage();
        currentY = 10;
      }
      doc.text(captionText, 10, currentY);
      currentY += 5;
      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20;
      doc.setDrawColor(0);
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY);
      currentY = tableBottomY + 10;
    });
    doc.save("Order-Detail.pdf");
  };

  const returnOrder = async (pr_id) => {
    try {
      await UserServices.orderProductReturn(id, pr_id);
      toast.success("Order Return successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getHold();
    } catch (error) {
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Error in deleting order!",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };

  const Table = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <>
        {tableLoading ? (
          <SkeletonTheme height="40px">
            <Skeleton count={7} />
          </SkeletonTheme>
        ) : (
          <table
            className="table product-table table-striped"
            id="branches"
            data-caption="Order Detail"
          >
            <thead>
              <tr>
                <th scope="col" className="no_sort">
                  Sr#
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("code")}
                  className={getClassNamesFor("code")}
                >
                  Product Code
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("name")}
                  className={getClassNamesFor("name")}
                >
                  Product Name
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("price")}
                  className={getClassNamesFor("price")}
                >
                  Price
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("discount")}
                  className={getClassNamesFor("discount")}
                >
                  Discount
                </th>

                <th
                  scope="col"
                  onClick={() => requestSort("quantity")}
                  className={getClassNamesFor("quantity")}
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("tax")}
                  className={getClassNamesFor("tax")}
                >
                  Tax
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("total")}
                  className={getClassNamesFor("total")}
                >
                  Total Bill
                </th>
                <th scope="col" className="no_sort">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                items.map((el, index) => (
                  <tr key={index}>
                    <td>{1 ? index + 1 : -1 * +index + 1}</td>
                    <td>{el.code}</td>
                    <td>{el.name}</td>
                    <td>
                      <CurrencyFormat
                        value={el.price}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={el.discount}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                    <td>{el.quantity}</td>
                    <td>
                      <CurrencyFormat
                        value={el.tax}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={el.total}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                    <td>
                      <button
                        className="button active"
                        type="button"
                        onClick={() => {
                          returnOrder(el.id);
                        }}
                      >
                        Return
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </>
    );
  };

  return (
    <div className="wrapper">
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">{customerData?.name}</span>
                </h4>
              </div>
              <div className="col-md-6 mt-2 d-flex justify-content-end">
                <span className="d-head">Order No: </span>
                {id}
              </div>
              <div className="col-md-2">
                <div className="bill-contact-detail">
                  <div className="bill-contact d-flex justify-content-between">
                    <span className="fw-bold">Phone:</span>
                    <span className=""> {customerData?.phone}</span>
                  </div>

                  <div className="bill-contact d-flex justify-content-between">
                    <span className="fw-bold">Address:</span>
                    <span className=""> {customerData?.address}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="main-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-8  heading">
                      <h4>
                        <span className="d-head">Orders</span> Detail
                      </h4>
                    </div>
                    <div className="col-md-4 d-flex justify-content-end">
                      <div
                        className="text-danger import-icons"
                        onClick={() => {
                          generatePdf(document.getElementById("products"));
                        }}
                      >
                        <FaFilePdf />
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Table data={myData} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row  mt-3">
              <div className="col-md-6">
                <div className="bill-contact-detail">
                  <div className="bill-contact mt-3">
                    <span className="fw-bold">
                      Thank You for business with us!
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 ms-auto">
                <div className="bill-total-detail bg-white px-3 py-2">
                  <div className="bill-contact d-flex justify-content-between">
                    <span className="fw-bold">Subtotal:</span>
                    <span>
                      {" "}
                      <CurrencyFormat
                        value={orderData?.subtoTal}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </span>
                  </div>
                  <div className="bill-contact d-flex justify-content-between">
                    <span className="fw-bold">Discount:</span>
                    <span>
                      {" "}
                      <CurrencyFormat
                        value={orderData?.discount}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </span>
                  </div>
                  <div className="bill-contact d-flex justify-content-between">
                    <span className="fw-bold">Tax:</span>
                    <span>
                      {" "}
                      <CurrencyFormat
                        value={orderData?.tax}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </span>
                  </div>
                </div>
                <div className="cart-footer d-flex justify-content-between">
                  <span className="fw-bold">Amount:</span>
                  <span>
                    <CurrencyFormat
                      value={orderData?.grandTotal}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
