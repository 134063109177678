export default function authHeaderImage() {
  const token = localStorage.getItem("access-token").replaceAll('"', "");

  if (token) {
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    };
  } else {
    return {};
  }
}
