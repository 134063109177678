import React from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { AiOutlineHome, AiOutlineShoppingCart } from "react-icons/ai";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import { GoGraph } from "react-icons/go";
import { MdOutlineAssignment } from "react-icons/md";

import { BsFillPersonLinesFill } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";
import { GrCurrency } from "react-icons/gr";

import { FiUsers } from "react-icons/fi";
import { BiPurchaseTag } from "react-icons/bi";

import "./SideBAr.css";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
export default function SideBar({ collapsed }) {
  return (
    <div id="sidebar">
      <ToastContainer />
      <Sidebar collapsed={collapsed}>
        <Menu transitionDuration={600}>
          <MenuItem
            icon={<AiOutlineHome />}
            component={<Link to="/dashboard" />}
          >
            Dashboard
          </MenuItem>

          {/* <SubMenu icon={<HiOutlineCurrencyDollar />} label="Billing">
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/all-bills">
                All Bills
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/billing-details">
                Bill Details
              </Link>
            </li>
          </SubMenu> */}
          <SubMenu icon={<GoGraph />} label="Sales">
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/orders">
                Sales
              </Link>
            </li>
            <MenuItem component={<Link to="/cart" />}>POS</MenuItem>
            <MenuItem component={<Link to="/sales-return" />}>Sales Return</MenuItem>
          </SubMenu>
          <SubMenu icon={<BiPurchaseTag />} label="Purchases">
            <MenuItem component={<Link to="/purchase-cart" />}>
              Purchases
            </MenuItem>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/purchases-import">
                Import Purchases
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/purchase-orders">
                Purchase Order
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/purchase-return">
                Purchase Return
              </Link>
            </li>
          </SubMenu>

          <SubMenu icon={<BsFillPersonLinesFill />} label="People">
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/manage-customers">
                Customers
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/add-supplier">
                Suppliers
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/new-user">
                Users
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/new-user">
                Stores
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/add-branches">
                Branches
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/add-company">
                Comapnies
              </Link>
            </li>

            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/add-employee">
                New Employee
              </Link>
            </li>
            {/* <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/employee-list">
                Employee List
              </Link>
            </li> */}
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/employee">
                Employee List
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/manage-designation">
                Manage Designation
              </Link>
            </li>
          </SubMenu>

          <SubMenu icon={<MdOutlineAssignment />} label="Reports">
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/sales-report">
                Sales Report
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/purchase-report">
                Purchase Report
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/inventory-report">
                Inventory Report
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/supplier-report">
                Supplier Report
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/customer-report">
                Customer Report
              </Link>
            </li>
          </SubMenu>

          <SubMenu icon={<GrCurrency />} label="Finance & Accounts">
            <SubMenu label="Expense">
              <MenuItem component={<Link to="/expenses" />}>
                Finance Dashboard
              </MenuItem>
              <MenuItem component={<Link to="/add-expense" />}>
                Add Expenses
              </MenuItem>
              <MenuItem component={<Link to="/view-expense" />}>
                View Expenses
              </MenuItem>
              <MenuItem component={<Link to="/expense-category" />}>
                Expenses Category
              </MenuItem>
            </SubMenu>
            <SubMenu label="Ledgers">
              <MenuItem component={<Link to="/add-ledger" />}>
                Add Ledger
              </MenuItem>
              <MenuItem component={<Link to="/view-ledger" />}>
                View Ledger
              </MenuItem>
              {/* <MenuItem component={<Link to="/" />}>Customer Ledger</MenuItem> */}
              {/* <MenuItem component={<Link to="/ledger-types" />}>
                Ledger Types
              </MenuItem>
              <MenuItem component={<Link to="/transaction-types" />}>
                Transaction Types
              </MenuItem> */}
            </SubMenu>
            <SubMenu label="Liabilities">
              <MenuItem component={<Link to="/add-liabilities" />}>
                Add Liabilities
              </MenuItem>
              <MenuItem component={<Link to="/view-liabilities" />}>
                View Liabilities
              </MenuItem>
            </SubMenu>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/balance-sheet">
                Balance Sheet
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/daily-sale-counter">
                Daily Sale Counter
              </Link>
            </li>

            <MenuItem component={<Link to="/bank-accounts" />}>
              Manage Bank Accounts
            </MenuItem>
          </SubMenu>

          <SubMenu icon={<FiUsers />} label="User Management">
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/new-user">
                New User
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/view-user">
                User List
              </Link>
            </li>
          </SubMenu>

          <SubMenu icon={<AiOutlineShoppingCart />} label="Products">
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/add-product">
                New Products
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/add-category">
                Product Categories
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/product-list">
                Products List
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/generate-barcode">
                Generate Barcode
              </Link>
            </li>
          </SubMenu>

          <SubMenu icon={<IoSettingsOutline />} label="Settings">
            <SubMenu label="General Settings">
              <MenuItem component={<Link to="/add-menu" />}>
                Manage Menu
              </MenuItem>
              <MenuItem component={<Link to="/add-sub-menu" />}>
                Manage SubMenu
              </MenuItem>
            </SubMenu>

            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/payment-types">
                Payment Settings
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/add-currency">
                Currency Settings
              </Link>
            </li>

            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/add-role">
                Manage Roles
              </Link>
            </li>
            <li className="ps-menuitem-root css-1tqrhto">
              <Link className="ps-menu-button" to="/create-permissions">
                Group Permissions
              </Link>
            </li>
          </SubMenu>
        </Menu>
      </Sidebar>
    </div>
  );
}
