import React, { useEffect, useState } from "react";

import { FaFilePdf } from "react-icons/fa6";

import useSortableData from "../../hooks/useSortableData";

import jsPDF from "jspdf";
import "jspdf-autotable";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import UserServices from "../../UserServices/UserServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function PurchaseHoldList() {
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const navigate = useNavigate();
  const delRecord = async (id) => {
    try {
      await UserServices.deletePurchaseHoldItem(id);
      navigate("/purchase-cart");
    } catch (error) {
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Error in unholding!",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };

  const getHold = async () => {
    setTableLoading(true);

    try {
      const responce = await UserServices.fethchPurchaseHold();
      let myDataarr;

      myDataarr = responce?.data?.data;

      const res = myDataarr;
      const results = [];

      res.map((values) => {
        results.push({
          holder_name: values.holder_name,
        });
        return null;
      });
      setTableLoading(false);
      setMyData([...results]);
    } catch (err) {
      console.log(err);
      setTableLoading(false);

      setMyData([]);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getHold();
  }, []);
  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");
    const tableOptions = {
      headStyles: { fillColor: [241, 132, 24], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10;
    let pageWidth = doc.internal.pageSize.width;
    let pageHeight = doc.internal.pageSize.height;
    tables.forEach((table, index) => {
      const captionText = table.getAttribute("data-caption");
      let tableHeight = doc.autoTable.previous.finalY - currentY;
      if (currentY + tableHeight + 20 > pageHeight) {
        doc.addPage();
        currentY = 10;
      }
      doc.text(captionText, 10, currentY);
      currentY += 5;
      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20;
      doc.setDrawColor(0);
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY);
      currentY = tableBottomY + 10;
    });
    doc.save("Purchase-Hold-List.pdf");
  };

  const Table = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <>
        {tableLoading ? (
          <SkeletonTheme height="40px">
            <Skeleton count={7} />
          </SkeletonTheme>
        ) : (
          <table
            className="table product-table table-striped"
            id="branches"
            data-caption="Purchase Hold List"
          >
            <thead>
              <tr>
                <th scope="col" className="no_sort">
                  Sr#
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("holder_name")}
                  className={getClassNamesFor("holder_name")}
                >
                  Holder Name
                </th>

                <th scope="col" className="no_sort">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                items.map((el, index) => (
                  <tr key={index}>
                    <td>{1 ? index + 1 : -1 * +index + 1}</td>
                    <td>{el.holder_name}</td>

                    <td>
                      <div className="d-flex">
                        <button
                          className="button active"
                          type="button"
                          onClick={() => delRecord(el.holder_name)}
                        >
                          Unhold
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="14" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </>
    );
  };

  return (
    <div className="wrapper">
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="main-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-8  heading">
                      <h4>
                        <span className="d-head">Purchase Hold</span> List
                      </h4>
                    </div>
                    <div className="col-md-4 d-flex justify-content-end">
                      <div
                        className="text-danger import-icons"
                        onClick={() => {
                          generatePdf(document.getElementById("products"));
                        }}
                      >
                        <FaFilePdf />
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Table data={myData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="main-spin">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  );
}
