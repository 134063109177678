import React, { useEffect, useState } from "react";
import { FaHandHoldingUsd } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { AddCart, cartBill, purchasesBill } from "../Schemas/Index";
import { useFormik } from "formik";
import useSortableData from "../../hooks/useSortableData";
import "./ProductImport.css";
import { RiDeleteBin5Line } from "react-icons/ri";
import CurrencyFormat from "react-currency-format";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import UserServices from "../../UserServices/UserServices";
import { toast } from "react-toastify";
import $ from "jquery";

export default function PurchaseCart() {
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [customer, setCustomer] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [payments, setPayments] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const getSupplierS = async () => {
    try {
      const responce = await UserServices.fethchSupplier("active");
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setCustomer([{ name: "Select Supplier", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setCustomer([{ name: "No supplier Found", id: "" }]);
    }
  };
  const getCart = async () => {
    setTableLoading(true);

    try {
      const responce = await UserServices.fethchPurchaseCart();
      let myDataarr;
      myDataarr = responce?.data?.data?.data;

      const res = myDataarr;
      const results = [];

      res.map((values) => {
        results.push({
          id: values.id,
          code: values.code,
          discount: values.discount,
          price: values.purchase_price,
          product_name: values.product_name,
          qty: values.qty,
          tax: values.tax,
          total: values.total,
        });
        return null;
      });
      setTableLoading(false);
      setMyData([...results]);
      setGrandTotal(responce?.data?.data?.total_bill);
    } catch (err) {
      console.log(err);
      setTableLoading(false);

      setGrandTotal(0);
      setMyData([]);
    }
  };

  const getPaymentTypes = async () => {
    try {
      const responce = await UserServices.fethchPaymentTypes("active");
      const res = responce.data?.data?.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setPayments([{ name: "Select Payment", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setPayments([{ name: "No Payment Found", id: "" }]);
    }
  };
  const getTransactionsTypes = async () => {
    try {
      const responce = await UserServices.fethchTransactionTypes("active");

      const res = responce.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value,
          name: value,
        });
        return null;
      });
      setTransactions([{ name: "Select Payment", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setTransactions([{ name: "No Payment Found", id: "" }]);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getPaymentTypes();
    getTransactionsTypes();
    getSupplierS();
  }, []);

  const itemSell = async (values, action) => {
    try {
      setLoading(true);
      const response = await UserServices.ImportItem(values);

      if (response.status === 200) {
        setLoading(false);
        toast.success("Sold Successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Error in unholding!",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };
  const currentDate = new Date().toISOString().slice(0, 10);
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      received: "",
      excel_file: null,
      customer_id: "",
      payment_id: "",
      transaction_type: "",
      date: currentDate,
     
    },
    validationSchema: purchasesBill,
    onSubmit: (values, action) => {
      itemSell(values, action);
    },
  });

  // console.log(values);
  const handleFileChange = (event) => {
    setFieldValue("excel_file", event.target.files[0]);
  };

  return (
    <div className="wrapper">
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">Purchase</span> Import
                </h4>
              </div>
              <div className="col-md-6 mt-2 d-flex justify-content-end">
                <Link
                  to="/purchase-hold"
                  className="button active"
                  type="button"
                >
                  <FaHandHoldingUsd /> Hold List
                </Link>
              </div>
            </div>

            <div className="row mt-3">
              <div className="main-card">
                <div className="card-body">
                  <div className="row">
                    <form
                      onSubmit={handleSubmit}
                      className="col-md-9"
                      encType="multipart/form-data"
                    >
                      <div className="row">
                        <div className="col-md-4 mt-2">
                          <div className="form-group custom-group">
                            <label className="form-label">
                              Supplier
                              <strong className="text-danger">*</strong>
                            </label>
                            {customer.length === 0 ? (
                              <SkeletonTheme height="40px">
                                <Skeleton count={1} />
                              </SkeletonTheme>
                            ) : (
                              <select
                                className="form-select"
                                name="customer_id"
                                value={values.customer_id || ""}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                              >
                                {customer.map((el, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={el.id}
                                      disabled={el.id === ""}
                                    >
                                      {el.name}
                                    </option>
                                  );
                                })}
                              </select>
                            )}

                            {errors.customer_id && touched.customer_id ? (
                              <p className="text-danger">
                                Please Select Supplier
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-4 mt-2">
                          <div className="form-group custom-group">
                            <label className="form-label">
                              Total
                              <strong className="text-danger">*</strong>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Grand Total"
                              autoComplete="off"
                              name="grand_total"
                              value={grandTotal}
                              disabled={true}
                            />
                            {errors.grand_total && touched.grand_total ? (
                              <p className="text-danger">
                                {errors.grand_total}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-4 mt-2">
                          <div className="form-group custom-group">
                            <label className="form-label">
                              Payment
                              <strong className="text-danger">*</strong>
                            </label>
                            {payments.length === 0 ? (
                              <SkeletonTheme height="40px">
                                <Skeleton count={1} />
                              </SkeletonTheme>
                            ) : (
                              <select
                                className="form-select"
                                name="payment_id"
                                value={values.payment_id || ""}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                              >
                                {payments.map((el, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={el.id}
                                      disabled={el.id === ""}
                                    >
                                      {el.name}
                                    </option>
                                  );
                                })}
                              </select>
                            )}

                            {errors.payment_id && touched.payment_id ? (
                              <p className="text-danger">{errors.payment_id}</p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4 mt-2">
                          <div className="form-group custom-group">
                            <label className="form-label">
                              Transaction Type
                              <strong className="text-danger">*</strong>
                            </label>
                            {transactions.length === 0 ? (
                              <SkeletonTheme height="40px">
                                <Skeleton count={1} />
                              </SkeletonTheme>
                            ) : (
                              <select
                                className="form-select"
                                name="transaction_type"
                                value={values.transaction_type || ""}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                              >
                                {transactions.map((el, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={el.id}
                                      disabled={el.id === ""}
                                    >
                                      {el.name}
                                    </option>
                                  );
                                })}
                              </select>
                            )}

                            {errors.transaction_type &&
                            touched.transaction_type ? (
                              <p className="text-danger">
                                {errors.transaction_type}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-4 mt-2">
                          <div className="form-group custom-group">
                            <label className="form-label">
                              Paid
                              <strong className="text-danger">*</strong>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Paid Amount"
                              autoComplete="off"
                              name="received"
                              value={values.received}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.received && touched.received ? (
                              <p className="text-danger">{errors.received}</p>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-4 mt-2">
                          <div className="form-group custom-group">
                            <label className="form-label">
                              Date
                              <strong className="text-danger">*</strong>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Choose Date"
                              autoComplete="off"
                              name="date"
                              value={values.date}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.date && touched.date ? (
                              <p className="text-danger">{errors.date}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-md-6 d-flex justify-content-between">
                          {/* <button
                            className="btn1"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#holdBill"
                          >
                            <span>Hold Bill</span>
                          </button> */}
                          <button className="btn1" type="submit">
                            <span>Generate Bill</span>
                          </button>
                          {/* <button
                            className="btn1"
                            type="button"
                            onClick={cartEmpty}
                          >
                            <span>Empty Cart</span>
                          </button> */}
                        </div>
                      </div>

                      <div class="file-input">
                        <input
                          type="file"
                          name="excel_file"
                          id="file-input"
                          className="file-input__input"
                          accept=".xlsx"
                          onChange={handleFileChange}
                          onBlur={handleBlur}
                        />

                        <label class="file-input__label" for="file-input">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="upload"
                            class="svg-inline--fa fa-upload fa-w-16"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                            ></path>
                          </svg>
                          <span>Upload file</span>
                        </label>
                      </div>
                    </form>
                    <div className="col-md-3 ">
                      <div className="grand-total-card mt-4">
                        <div className="cart-total d-flex justify-content-between">
                          <span className="fw-bold">Total Items:</span>
                          <span className="me-2">{myData?.length}</span>
                        </div>
                        <div className="cart-total d-flex justify-content-between">
                          <span className="fw-bold">Total Discount:</span>
                          <span>
                            <CurrencyFormat
                              value={myData.reduce(
                                (a, v) => (a = a + v.discount),
                                0
                              )}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </span>
                        </div>

                        <div className="cart-footer d-flex justify-content-between">
                          <span className="fw-bolder">Total Amount:</span>
                          <span>
                            <CurrencyFormat
                              value={grandTotal}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="main-spin">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  );
}
