import * as Yup from "yup";

export const login = Yup.object({
  email: Yup.string().email().required("Please enter email"),
  password: Yup.string().min(6).required("Please enter password"),
});
export const AddProduct = Yup.object({
  name: Yup.string().min(2).max(25).required("Product Name is required"),
  category_id: Yup.string().required("Category is required"),
  supplier_id: Yup.string().required("Supplier is required"),
  product_code: Yup.string().required("Product Code is required"),
  purchase_price: Yup.string().required("Purchase Price is required"),
  sale_price: Yup.string().required("Sale Price is required"),
  quantity: Yup.string().required("Quantity is required"),
  tax: Yup.number()
    .min(0, "Percentage must be greater than or equal to 0")
    .max(100, "Percentage must be less than 100%")
    .required("Tax Percentage is required"),
});
export const AddPermission = Yup.object({
  role: Yup.string().required("Role is required"),
});
export const AddCart = Yup.object({
  product_code: Yup.string().required("Product Code is required"),
});
export const AddUser = Yup.object({
  name: Yup.string().min(2).max(25).required("Name is required"),
  email: Yup.string().email().required("Please enter email"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Please enter password"),
  role: Yup.string().required("Role is required"),
  company: Yup.string().required("Company is required"),
  branch: Yup.string().required("Branch is required"),
});
export const updateUser = Yup.object({
  name: Yup.string().min(2).max(25).required("Name is required"),
  email: Yup.string().email().required("Please enter email"),
  role: Yup.string().required("Role is required"),
});
export const AddRoles = Yup.object({
  name: Yup.string().min(2).max(25).required("Name is required"),
});
export const CategoryAdd = Yup.object({
  name: Yup.string().max(25).required("Name is required"),
  type: Yup.string().required("Type is required"),
});
export const CompanyAdd = Yup.object({
  name: Yup.string().max(25).required("Name is required"),
  address: Yup.string().required("Address is required"),
  phone: Yup.string().min(10).max(25).required("Phone is required"),
});
export const CurrencyAdd = Yup.object({
  name: Yup.string().max(25).required("Name is required"),
  code: Yup.string().required("Code is required"),
  symbol: Yup.string().required("Symbol is required"),
});
export const BranchesAdd = Yup.object({
  name: Yup.string().min(2).max(25).required("Name is required"),
  company: Yup.string().required("Company is required"),
  phone: Yup.string().min(10).max(25).required("Phone is required"),
  address: Yup.string().required("Address is required"),
});
export const BranchesUpdt = Yup.object({
  name: Yup.string().min(2).max(25).required("Name is required"),
  phone: Yup.string().min(10).max(25).required("Phone is required"),
  address: Yup.string().required("Address is required"),
});
export const EmployeeAdd = Yup.object({
  name: Yup.string().min(2).max(25).required("Name is required"),
  phone: Yup.string().min(10).max(25).required("Phone is required"),
  address: Yup.string().required("Address is required"),
  designation: Yup.string().required("Designation is required"),
  gender: Yup.string().required("Gender is required"),
  email: Yup.string().required("Email is required"),
  date: Yup.string().required("Joining Date is required"),
});

export const cartBill = Yup.object({
  received: Yup.number()
    .min(0, "Should be Entered as a Positive Value")
    .required("Required"),

  customer_id: Yup.string().required("Please Select Custmer"),
});
export const billPay = Yup.object({
  received: Yup.number()
    .min(0, "Should be Entered as a Positive Value")
    .required("Required"),
});

export const addBarcode = Yup.object({
  product_code: Yup.string().required("Product Code is required"),
  count: Yup.number()
    .min(1, "Should be Entered as a Positive Value")
    .required("Count is required"),
});

export const purchasesBill = Yup.object({
  received: Yup.number()
    .min(0, "Should be Entered as a Positive Value")
    .required("Required"),

  customer_id: Yup.string().required("Please Select Supplier"),
  payment_id: Yup.string().required("Please Select Payment"),
  transaction_type: Yup.string().required("Please Select Transaction Type"),
});

export const purchasesBillPay = Yup.object({
  received: Yup.number()
    .min(0, "Should be Entered as a Positive Value")
    .required("Required"),
  payment_id: Yup.string().required("Please Select Payment"),
  transaction_type: Yup.string().required("Please Select Transaction Type"),
});

export const AddExp = Yup.object({
  name: Yup.string().max(25).required("Name is required"),
  amount: Yup.string().required("Amount is required"),
  date: Yup.string().required("Date is required"),
});


export const AddLed = Yup.object({
  amount: Yup.string().required("Amount is required"),
  date: Yup.string().required("Date is required"),
  type: Yup.string().required("Type is required"),
  ledger_type: Yup.string().required("ledger type is required"),
  transaction_type: Yup.string().required("transaction type is required"),

});


export const AddLia = Yup.object({
  amount: Yup.string().required("Amount is required"),
  due_date: Yup.string().required("Due Date is required"),
  category_id: Yup.string().required("Category is required"),
  creditor_contact: Yup.string().required("Creditor Contact is required"),
  creditor_name: Yup.string().required("Creditor Name type is required"),

});


export const AddReturnLia = Yup.object({
  amount: Yup.string().required("Amount is required"),
  date: Yup.string().required("Date is required"),
  resource: Yup.string().required("Category is required"),
});

export const filter = Yup.object().shape({
  sDate: Yup.string().required("Start Date is required"),
  eDate: Yup.string()
    .required("End Date is required")
    .when('sDate', (sDate, schema) => {
      return schema.test({
        test: function (eDate) {
          // If either start date or end date is not defined, validation passes
          if (!sDate || !eDate) return true;
          // Convert strings to date objects
          const startDate = new Date(sDate);
          const endDate = new Date(eDate);
          // Check if end date is greater than start date
          return endDate > startDate;
        },
        message: 'End Date must be greater than Start Date',
      });
    }),
});