import React, { useState } from "react";

import { AddCart, addBarcode } from "../Schemas/Index";
import { useFormik } from "formik";
import Barcode from "react-barcode";
import UserServices from "../../UserServices/UserServices";
import { toast } from "react-toastify";

export default function ProductsBarCodeGenerator() {
  const [numBarcodes, setNumBarcodes] = useState(1);
  const [productCodes, setProductCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showBarcode, setShowBarcode] = useState(false);

  const generateBarcode = async (values, action) => {
    try {
      setLoading(true);
      await UserServices.checkForBarcode(values.product_code);

      const newProductCodes = Array.from(
        { length: numBarcodes },
        (_, index) => `${values.product_code}`
      );
      setProductCodes(newProductCodes);
      await setShowBarcode(true);
      action.resetForm();
      setNumBarcodes(parseInt(1));
      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Error in deleting order!",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };

  const initialValues = {
    product_code: "",
    count: 1,
  };
  const scan = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: addBarcode,
    onSubmit: (values, action) => {
      generateBarcode(values, action);
    },
  });

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="wrapper">
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">Generate</span> Product Barcodes
                </h4>
              </div>
            </div>
            <div className="row mt-3">
              <div className="main-card">
                <div className="card-body">
                  <div className="row">
                    <form onSubmit={scan.handleSubmit} className="col-md-12">
                      <div className="row">
                        <div className="col-md-4 mt-2 heading">
                          <div className="form-group custom-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Product Barcode"
                              autoComplete="off"
                              name="product_code"
                              value={scan.values.product_code}
                              onChange={(e) => {
                                scan.handleChange(e);
                              }}
                              onBlur={scan.handleBlur}
                            />
                            {scan.errors.product_code &&
                            scan.touched.product_code ? (
                              <p className="text-danger">
                                {scan.errors.product_code}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-3 mt-2 heading">
                          <div className="form-group custom-group">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Number of Barcodes"
                              autoComplete="off"
                              name="count"
                              value={scan.values.count}
                              onChange={(e) => {
                                scan.handleChange(e);
                                setNumBarcodes(parseInt(e.target.value));
                              }}
                              onBlur={scan.handleBlur}
                            />
                            {scan.errors.count && scan.touched.count ? (
                              <p className="text-danger">{scan.errors.count}</p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-3 mt-2">
                          <button className="btn1 d-inline-block" type="submit">
                            <span>Generate</span>
                          </button>
                          {showBarcode && (
                            <button
                              className="btn1 d-inline-block ms-2"
                              type="button"
                              onClick={handlePrint}
                            >
                              <span>Print</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                    {showBarcode && (
                      <div className="col-12">
                        <div id="barcode">
                          {productCodes.map((code, index) => (
                            <Barcode value={code} key={index} />
                          ))}
                          {/* <Barcode
                                value={scan.values.product_code}
                                id="barcode"
                              /> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="main-spin">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  );
}
