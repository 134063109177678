import React, { useState, useEffect, useRef, useContext } from "react";
import { useFormik } from "formik";
import { AddReturnLia } from "../Schemas/Index";
import UserServices from "../../UserServices/UserServices";
import { ToastContainer, toast } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";
import { useParams } from "react-router-dom";

export default function ReturnLiabilities() {
  const [loading, setLoading] = useState(false);
  const [categoryError, setCategoryError] = useState("");
  const [category, setCategory] = useState("");
  const [liabilities, setLiabilities] = useState("");
  const [transaction, setTransaction] = useState("");
  const { id } = useParams();
  const FetchLiabilities = async () => {
    try {
      const res = await UserServices.getLiabilities(id);
      setLiabilities(res.data?.data?.data[0]);
    } catch (error) {
      console.error("Error fetching Liabilities:", error);
    }
  };

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const res = await UserServices.CategoriesValues();
        setCategory(res.data?.data);
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    };

    const fetchTransaction = async () => {
      try {
        const res = await UserServices.TransactionValues();
        setTransaction(res.data);
      } catch (error) {
        console.error("Error fetching Payment:", error);
      }
    };
    fetchTransaction();
    FetchLiabilities();
    fetchCategory();
  }, []);

  const AddLiabilitiesValues = async (values, action) => {
    if (categoryError === "") {
      try {
        setLoading(true);
        const response = await UserServices.ReturnLiabilities(values, id);
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          toast.success("Added Successfully!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          action.resetForm();
          FetchLiabilities();
        }
      } catch (err) {
        setLoading(false);
        if (err.response.status === 422) {
          toast.error("Unproccessable content!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (err.response.status === 500) {
          toast.error("Unproccessable content!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (err.response.status === 400) {
          toast.error("Unproccessable content!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };

  const initialValues = {
    amount: "",
    date: UserServices.getFormattedDate(),
    resource: "",
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
       validationSchema: AddReturnLia,

      onSubmit: (values, action) => {
        AddLiabilitiesValues(values, action);
      },
    });

  return (
    <div className="wrapper">
      <ToastContainer />
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">Pay </span> Liabilities
                </h4>
                <p className="text-secondary">Liabilities</p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="main-card">
                <div className="d-flex mt-5 mx-3">
                  <div className="me-5">
                    <label>Total Amount :</label>
                    <span style={{ color: "red" }} className="mx-2">
                      {liabilities.amount}
                    </span>
                  </div>
                  <div className="mx-5">
                    <label>Received Amount :</label>
                    <span style={{ color: "red" }} className="mx-2">
                      {liabilities.paid}
                    </span>
                  </div>
                  <div className="mx-5">
                    <label>Remaining Amount :</label>
                    <span style={{ color: "red" }} className="mx-2">
                      {liabilities.amount - liabilities.paid}
                    </span>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <p className="text-secondary">
                      <strong className="text-danger">!</strong> The Field With
                      <strong className="text-danger">*</strong>
                      Must Required Or Fill.
                    </p>
                    <div className="row g-3">
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Amount <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Amount Name"
                            autoComplete="off"
                            name="amount"
                            value={values.amount}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {categoryError ? (
                            <p className="text-danger">{categoryError}</p>
                          ) : null}
                          {errors.amount && touched.amount ? (
                            <p className="text-danger">{errors.amount}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Date <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Enter Expense Name"
                            autoComplete="off"
                            name="date"
                            value={values.date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {categoryError ? (
                            <p className="text-danger">{categoryError}</p>
                          ) : null}
                          {errors.date && touched.date ? (
                            <p className="text-danger">{errors.due_date}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Resource
                            <strong className="text-danger">*</strong>
                          </label>
                          <select
                            className="form-select"
                            name="resource"
                            value={values.resource}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Resource Type</option>

                            {transaction &&
                              transaction.map((item, index) => (
                                <option value={item} key={index}>
                                  {item}
                                </option>
                              ))}
                          </select>
                          {errors.resource && touched.resource ? (
                            <p className="text-danger">{errors.resource}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 ">
                        <button className="btn1" type="submit">
                          <span>Update</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="main-spin">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  );
}
