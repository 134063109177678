import React, { useEffect, useState } from "react";
import { BranchesUpdt } from "../Schemas/Index";
import { useFormik } from "formik";
import { IoMdCloudUpload } from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import placeHolder from "../../Images/users.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import UserServices from "../../UserServices/UserServices";
import { toast } from "react-toastify";
import { MaskField } from "react-mask-field";
import { Link } from "react-router-dom";
import { BiSearchAlt } from "react-icons/bi";

export default function AddEmployee() {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const [picture, setPicture] = useState("");
  const [designation, setDesignation] = useState([]);
  // eslint-disable-next-line
  const [prfoImg, setPrfoImg] = useState("");
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");

  const handleUpload = async (event) => {
    setFile(event.target.files[0]);
    var image = document.getElementById("output");
    image.src = URL.createObjectURL(event.target.files[0]);
  };
  const Designations = async () => {
    try {
      const responce = await UserServices.fethchDesignation();
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setDesignation([{ name: "Select Designation", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setDesignation([{ name: "No Designation Found", id: "" }]);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    Designations();
  }, []);

  const addEmployee = async (values, action) => {
    const formData = new FormData();

    if (file !== "") {
      formData.append("image", file);
      formData.append("imageName", file.name);
    }
    formData.append("name", values.name);
    formData.append("designation_id", values.designation);
    formData.append("gender", values.gender);
    formData.append("phone", values.phone);
    formData.append("email", values.email);
    formData.append("id_card", values.id_card);
    formData.append("joining_date", values.date);
    formData.append("address", values.address);
    formData.append("salary", values.salary);
    try {
      setLoading(true);
      const response = await UserServices.EmployeeAdd(formData);

      if (response.status === 200) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  const initialValues = {
    name: "",
    gender: "",
    address: "",
    date: defaultValue,
    designation: "",
    salary: "",
    phone: "",
    email: "",
    id_card: "",
  };
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: BranchesUpdt,
    onSubmit: (values, action) => {
        addEmployee(values, action);
    },
  });
  return (
    <div className="wrapper">
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">Manage</span> Employee
                </h4>
                <p className="text-secondary">Add Employee</p>
              </div>
              <div className="col-md-6 mt-2 d-flex justify-content-end">
              <Link
                  to="/employee"
                  className="button active"
                  type="button"
                >
                  <BiSearchAlt /> View Employee
                </Link>
              </div>
            </div>
            <div className="row mt-3">
              <div className="main-card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row g-3">
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-6">
                            <div className="employe-card-head">
                              01.{" "}
                              <span className="ms-1">
                                {" "}
                                <span className="sub-head">Profile</span> Image
                              </span>
                            </div>
                          </div>
                          <div className="col-6 mt-3">
                            <div className="employe-card-row "></div>
                          </div>

                          <div className="clint-pic-upload mt-3">
                            <div className="avatar-upload">
                              <div className="avatar-edit">
                                <input
                                  type="file"
                                  id="imageUpload"
                                  onClick={(e) => {
                                    e.target.value = null;
                                    setFile("");
                                    setPicture("");
                                  }}
                                  onChange={handleUpload}
                                  accept=".png, .jpg, .jpeg"
                                />
                              </div>
                              <div className="avatar-preview">
                                <div
                                  id="imagePreview"
                                  style={
                                    file === "" && picture === ""
                                      ? prfoImg === ""
                                        ? {
                                            backgroundImage: `url(${placeHolder})`,
                                            overflow: "hidden",
                                          }
                                        : {
                                            overflow: "hidden",
                                            backgroundImage:
                                              'url("' + prfoImg + '")',
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                          }
                                      : {
                                          backgroundImage: "none",
                                          overflow: "hidden",
                                        }
                                  }
                                >
                                  <img
                                    id="output"
                                    alt="profile"
                                    style={
                                      file === "" && picture === ""
                                        ? {
                                            display: "none",
                                          }
                                        : {
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                          }
                                    }
                                  />
                                </div>
                              </div>
                              <div className="profile-pic-btn">
                                <label htmlFor="imageUpload">
                                  <span className="">
                                    {" "}
                                    <IoMdCloudUpload />{" "}
                                  </span>
                                  Upload Image
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="row g-3">
                          <div className="col-4">
                            <div className="employe-card-head">
                              02.{" "}
                              <span className="ms-1">
                                {" "}
                                <span className="sub-head">Personal</span>{" "}
                                Information
                              </span>
                            </div>
                          </div>
                          <div className="col-8 mt-3">
                            <div className="employe-card-row mt-3"></div>
                          </div>
                          <div className="col-6">
                            <div className="form-group custom-group">
                              <label className="form-label">
                                Name
                                <strong className="text-danger">*</strong>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Name"
                                autoComplete="off"
                                name="name"
                                value={values.name}
                                onChange={async (e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.name && touched.name ? (
                                <p className="text-danger">{errors.name}</p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group custom-group">
                              <label className="form-label">
                                Gender{" "}
                                <strong className="text-danger">*</strong>
                              </label>
                              <select
                                className="form-select"
                                name="gender"
                                value={values.gender}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                              >
                                <option value={-1}>Select gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="others">Others</option>
                              </select>
                              {errors.gender && touched.gender ? (
                                <p className="text-danger">{errors.gender}</p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group custom-group">
                              <label className="form-label">
                                Joining Date{" "}
                                <strong className="text-danger">*</strong>
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                name="date"
                                value={values.date}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.date && touched.date ? (
                                <p className="text-danger">{errors.date}</p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-6">
                          <div className="form-group custom-group">
                          <label className="form-label">
                            Designation
                            <strong className="text-danger">*</strong>
                          </label>
                          {designation.length === 0 ? (
                            <SkeletonTheme height="40px">
                              <Skeleton count={1} />
                            </SkeletonTheme>
                          ) : (
                            <select
                              className="form-select"
                              name="designation"
                              value={values.designation || ""}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                            >
                              {designation.map((el, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={el.id}
                                    disabled={el.id === ""}
                                  >
                                    {el.name}
                                  </option>
                                );
                              })}
                            </select>
                          )}

                          {errors.designation && touched.designation ? (
                            <p className="text-danger">{errors.designation}</p>
                          ) : null}
                        </div>
                          </div>
                          <div className="col-6">
                          <div className="form-group custom-group">
                              <label className="form-label">
                                Salary
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Salary"
                                autoComplete="off"
                                name="salary"
                                value={values.salary}
                                onChange={async (e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.salary && touched.salary ? (
                                <p className="text-danger">{errors.salary}</p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="employe-card-head">
                          03.{" "}
                          <span className="ms-1">
                            {" "}
                            <span className="sub-head">Contact</span> Details
                          </span>
                        </div>
                      </div>
                      <div className="col-10 mt-3">
                        <div className="employe-card-row mt-3"></div>
                      </div>
                      <div className="col-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Phone <strong className="text-danger">*</strong>
                          </label>
                          <PhoneInput
                            country={"pk"}
                            name="phone"
                            onChange={(phone) => {
                              setFieldValue("phone", phone);
                            }}
                            onBlur={() => {
                              setFieldTouched("phone", true);
                            }}
                          />
                          {errors.phone && touched.phone ? (
                            <p className="text-danger">{errors.phone}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Email
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Email"
                            autoComplete="off"
                            name="email"
                            value={values.email}
                            onChange={async (e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.email && touched.email ? (
                            <p className="text-danger">{errors.email}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            ID Card
                          </label>
                          <MaskField
                            className="form-control"
                            placeholder="Enter Id Card Number"
                            name="id_card"
                            value={values.id_card}
                            onChange={async (e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            mask="_____-_______-_-"
                            replacement={{ _: /\d/ }}
                          />
                          {errors.id_card && touched.id_card ? (
                            <p className="text-danger">{errors.id_card}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Address
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Address"
                            autoComplete="off"
                            name="address"
                            value={values.address}
                            onChange={async (e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.address && touched.address ? (
                            <p className="text-danger">{errors.address}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12">
                        <button className="btn1" type="submit">
                          <span>Add</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="main-spin">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  );
}
