import { createContext, useReducer } from "react";
import secureLocalStorage from "react-secure-storage";

export const NoteState = createContext();
const accessToken = localStorage.getItem("access-token");
const branch_id = secureLocalStorage.getItem("branchId");
const user_id = secureLocalStorage.getItem("userId");

const initialState = {
  accessToken: accessToken ? JSON.parse(accessToken) : null,
  branch_id: branch_id ? JSON.parse(branch_id) : null,
  user_id: user_id ? JSON.parse(user_id) : null,

};

function reducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("access-token", JSON.stringify(action.accessToken));
      secureLocalStorage.setItem("branchId", JSON.stringify(action.branchId));
      secureLocalStorage.setItem("userId", JSON.stringify(action.userId));
      return {
        ...state,
        accessToken: action.accessToken,
        branch_id: action.branchId,
        user_id: action.userId,

      };
    case "LOGOUT":
      localStorage.removeItem("access-token"); 
      localStorage.removeItem("branchId"); 
      localStorage.removeItem("userId"); 

      return { ...state, accessToken: null, branch_id: null, user_id: null };
    default:
      return state;
  }
}
export function UserProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return (
    <NoteState.Provider value={value}>{props.children}</NoteState.Provider>
  );
}
