import React from "react";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import "./Expenses.css";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
export const data3 = {
  labels: ["Red", "Blue", "Yellow"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3],
      backgroundColor: ["#FF9F0D", "#1FB184", "#1D75BD"],
      borderColor: ["#FF9F0D", "#1FB184", "#1D75BD"],
      borderWidth: 1,
    },
  ],
};

export const options3 = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return `${context.label}: ${(context.parsed * 100).toFixed(2)}%`;
        },
      },
    },
  },
};

export const data2 = {
  labels: ["Red", "Green", "Yellow"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 5, 8],
      backgroundColor: ["#CE3F4E", "#1D75BD", "#FF9F0D"],
      borderColor: ["#CE3F4E", "#1D75BD", "#FF9F0D"],
      borderWidth: 1,
    },
  ],
};

export const options2 = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return `${context.label}: ${(context.parsed * 100).toFixed(2)}%`;
        },
      },
    },
  },
};
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        // usePointStyle: true,
        boxWidth: 10,
      },
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        borderDash: [3, 3],
        drawBorder: false,
      },
    },
  },
};

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"];

export const data = {
  labels,
  datasets: [
    {
      label: "Net Profit",
      data: [200, 390, 60, 90, 400, 120, 300, 500],
      backgroundColor: "#CE3F4E",
      barThickness: 12,
      borderRadius: 3,
    },
    {
      label: "Revenew",
      data: [100, 70, 100, 450, 300, 200, 500, 600],
      backgroundColor: "#FF9F0D",
      barThickness: 12,
      borderRadius: 3,
    },
    {
      label: "Free Cash Flow",
      data: [60, 190, 110, 300, 500, 200, 120, 170],
      backgroundColor: "#1D75BD",
      barThickness: 12,
      borderRadius: 3,
    },
  ],
};

const Table = ({ data, rowsPerPage }) => {
  const { slice } = useTable(data, 1, rowsPerPage);
  const { requestSort, sortConfig } = useSortableData(slice);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <>
      <table className="table product-table table-striped">
        <thead>
          <tr>
            <th scope="col" className="no_sort">
              Sr#
            </th>
            <th
              scope="col"
              onClick={() => requestSort("product_name")}
              className={getClassNamesFor("product_name")}
            >
              Transaction Type
            </th>
            <th
              scope="col"
              onClick={() => requestSort("date")}
              className={getClassNamesFor("date")}
            >
              Category
            </th>
            <th
              scope="col"
              onClick={() => requestSort("date")}
              className={getClassNamesFor("date")}
            >
              Sub-Category
            </th>
            <th
              scope="col"
              onClick={() => requestSort("date")}
              className={getClassNamesFor("date")}
            >
              Payment Method
            </th>
            <th
              scope="col"
              onClick={() => requestSort("date")}
              className={getClassNamesFor("date")}
            >
              Description
            </th>
            <th
              scope="col"
              onClick={() => requestSort("date")}
              className={getClassNamesFor("date")}
            >
              Amount
            </th>
            <th
              scope="col"
              onClick={() => requestSort("date")}
              className={getClassNamesFor("date")}
            >
              Date
            </th>
            <th
              scope="col"
              onClick={() => requestSort("date")}
              className={getClassNamesFor("date")}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Sales Counter</td>
            <td>Abc</td>
            <td>Test</td>
            <td>Cash</td>
            <td>N/A</td>
            <td>
              {" "}
              <CurrencyFormat
                value={10000}
                displayType={"text"}
                thousandSpacing={"3"}
                thousandSeparator={true}
                prefix={"Rs "}
                suffix={"/-"}
                renderText={(value) => <span>{value}</span>}
              />
            </td>
            <td>
              { moment(11 - 10 - 2023).format("ll")}
            </td>
            <td>
              <div className="d-flex">
                <div className="d-none">Delete</div>
                <span
                  className="action-icons"
                  onClick={() => {
                    //  getRecord(el.id);
                  }}
                >
                  <AiOutlineEdit />
                </span>
                <span
                  className="action-icons ms-2 text-danger"
                  //  onClick={() => delRecord(el.id)}
                >
                  <RiDeleteBin5Line />
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default function Expenses() {
  const chartRef = React.createRef();

  return (
    <div className="wrapper">
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 mt-2 heading">
                <h4>
                  <span className="d-head">Manage</span> Expenses
                </h4>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <div className="main-card">
                  <div className="card-heading">
                    <h4>Expenses</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="expence-card">
                          <div className="d-flex mb-2">
                            <h5>Total Expenses</h5>
                            <span className="ms-auto expense-card-span">
                              Rs: 150000
                            </span>
                          </div>
                          <Pie data={data3} options={options3} ref={chartRef} />
                          <div className="percentage-list mt-3">
                            {data3.labels.map((label, index) => (
                              <div
                                key={index}
                                className="percentage-item d-flex justify-content-between"
                              >
                                <span
                                  className="legend-color"
                                  style={{
                                    backgroundColor:
                                      data3.datasets[0].backgroundColor[index],
                                    width: "15px",
                                    height: "15px",
                                    borderRadius: "50%",
                                  }}
                                />
                                <span className="legend-label">{label}</span>
                                <span className="legend-percentage">
                                  {(
                                    (data3.datasets[0].data[index] /
                                      data3.datasets[0].data.reduce(
                                        (acc, curr) => acc + curr,
                                        0
                                      )) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="expence-card">
                          <div className="d-flex mb-2">
                            <h5>Last Week Expenses</h5>
                            <span className="ms-auto expense-card-span">
                              Rs: 450000
                            </span>
                          </div>
                          <Bar options={options} data={data} className="mt-5" />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="expence-card">
                          <div className="d-flex mb-2">
                            <h5>Total Income</h5>
                            <span className="ms-auto expense-card-span">
                              Rs: 300000
                            </span>
                          </div>
                          <Doughnut
                            data={data2}
                            options={options2}
                            ref={chartRef}
                          />
                          <div className="percentage-list mt-3">
                            {data2.labels.map((label, index) => (
                              <div
                                key={index}
                                className="percentage-item d-flex justify-content-between"
                              >
                                <span
                                  className="legend-color"
                                  style={{
                                    backgroundColor:
                                      data2.datasets[0].backgroundColor[index],
                                    width: "15px",
                                    height: "15px",
                                    borderRadius: "50%",
                                  }}
                                />
                                <span className="legend-label">{label}</span>
                                <span className="legend-percentage">
                                  {(
                                    (data2.datasets[0].data[index] /
                                      data2.datasets[0].data.reduce(
                                        (acc, curr) => acc + curr,
                                        0
                                      )) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="table-responsive mt-5">
                            <Table data={[]} rowsPerPage={20} />
                      </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
