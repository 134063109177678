import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import { RiMenu2Fill } from "react-icons/ri";
import { IoMdNotificationsOutline } from "react-icons/io";
import { BiMessage } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import "./NavBAr.css";
import { Link, useNavigate } from "react-router-dom";
import logoImage from "../../../Images/logo.png";
import { NoteState } from "../../../context/notes/NoteState";

export default function NavBar({ onToggleSidebar }) {
  const navigate = useNavigate();
  const { dispatch: ctxDispatch } = useContext(NoteState);

  const LogOut = (e) => {
    e.preventDefault();
    ctxDispatch({
      type: "LOGOUT",
    });
    navigate("/");
  };

  return (
    <Navbar expand="lg" className="Navbar-color">
      <Container fluid>
        <Link to="/dashboard" className="Navbar-logo">
          <img src={logoImage} alt="logo" />
        </Link>
        <button className="collaps-button ms-2" onClick={onToggleSidebar}>
          <RiMenu2Fill />
        </button>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          ></Nav>
          <Link to="/dashboard" className="text-white nav-icon me-3">
            <span className="message-badge">6</span>
            <IoMdNotificationsOutline />
          </Link>
          <Link to="/dashboard" className="text-white nav-icon me-3">
            <span className="notification-badge">4</span>
            <BiMessage />
          </Link>
          <Link to="/dashboard" className="text-white nav-icon me-3">
            <FiSettings />
          </Link>
          <NavDropdown
            drop="start"
            className="text-white nav-img"
            title={ <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnTt6oQ102Y-TEHPHgKnMVvKjRIqyrgYEJvA&usqp=CAU"
              alt="img"
            />}
            id="navbarScrollingDropdown"
          >
            <Link
              className="dropdown-item"
              onClick={(e) => {
                LogOut(e);
              }}
            >
              LogOut
            </Link>
          </NavDropdown>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
