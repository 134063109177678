import React, { useContext, useEffect, useState } from "react";

import { FaFilePdf } from "react-icons/fa6";

import useSortableData from "../../hooks/useSortableData";

import jsPDF from "jspdf";
import "jspdf-autotable";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import UserServices from "../../UserServices/UserServices";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import Pagination from "react-js-pagination";
import { RxCross2 } from "react-icons/rx";
import { TbFilterSearch } from "react-icons/tb";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import { TbDotsVertical } from "react-icons/tb";
import { BsCardList } from "react-icons/bs";
import { NoteState } from "../../context/notes/NoteState";

export default function ViewExpense() {
  const [myData, setMyData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [exportLink, setExportLink] = useState("");
  const { state } = useContext(NoteState);
  const { branch_id, user_id } = state;
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [customer, setCustomer] = useState([]);
  const [showRow, setShowRow] = useState(false);
  const [category, setCategory] = useState("");
  const [payment, setPayment] = useState("");
  const [banks, setBanks] = useState("");
  const [transaction, setTransaction] = useState("");

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const res = await UserServices.CategoriesValues();
        setCategory(res.data?.data);
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    };

    const fetchPayments = async () => {
      try {
        const res = await UserServices.PaymentValues();
        setPayment(res.data?.data?.data);
      } catch (error) {
        console.error("Error fetching Payment:", error);
      }
    };

    const fetchBank = async () => {
      try {
        const res = await UserServices.BankValues();
        setBanks(res.data?.data);
      } catch (error) {
        console.error("Error fetching Payment:", error);
      }
    };

    const fetchTransaction = async () => {
      try {
        const res = await UserServices.TransactionValues();
        setTransaction(res.data);
        console.log(res.data);
      } catch (error) {
        console.error("Error fetching Payment:", error);
      }
    };

    fetchTransaction();

    fetchBank();
    fetchPayments();
    fetchCategory();
  }, []);

  // const HandleDelete = async (UsrId) => {
  //   try {
  //     const response = await UserServices.DeleteExpenses(UsrId, "deleted");
  //     console.log(response);
  //   } catch (err) {
  //     toast.error("Error deleting user");
  //   }
  // };
  const delRecord = async (id) => {
    try {
      await UserServices.DeleteExpenses(id, "delete");
      toast.success("Successfully deleted!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getExp(currentPage, limit, values);
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Error in deleting order!",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };

  const getExp = async (page, limit, values) => {
    setTableLoading(true);

    try {
      const responce = await UserServices.FetchExpenses(page, limit, values);

      setExportLink(
        process.env.REACT_APP_API_Link +
          "exports/export-expense?page=" +
          page +
          "&limit=" +
          limit +
          "&payment_type_id=" +
          values.payment_type_id +
          "&start_date=" +
          values.start_date +
          "&end_date=" +
          values.end_date +
          "&user_id=" +
          user_id +
          "&branch_id=" +
          branch_id
      );
      let myDataarr;

      myDataarr = responce?.data?.data?.data;
      const res = myDataarr;
      const results = [];

      res.map((values) => {
        results.push({
          name: values.name,
          amount: values.amount,
          date: values.date,
          description: values.description,
          id: values.id,
          created_at: values.created_at,
          transaction_type: values.transaction_type,
          payment_type_info: values?.payment_type_info?.name,
          bank_name: values?.bank_info?.name,
          bank_title: values?.bank_info?.account_title,
          category_info: values?.category_info?.name,
        });
        return null;
      });
      setTableLoading(false);
      setMyData([...results]);
      setLimit(responce?.data?.data?.per_page);
      setTotalResults(responce?.totalRecord);
      setTotalPages(responce?.totalPages);
      setCurrentPage(responce?.data?.data?.current_page);
    } catch (err) {
      console.log(err);
      setTableLoading(false);
      setTotalResults("0");
      setTotalPages("1");
      setMyData([]);
    }
  };

  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");
    const tableOptions = {
      headStyles: { fillColor: [241, 132, 24], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10;
    let pageWidth = doc.internal.pageSize.width;
    let pageHeight = doc.internal.pageSize.height;
    tables.forEach((table, index) => {
      const captionText = table.getAttribute("data-caption");
      let tableHeight = doc.autoTable.previous.finalY - currentY;
      if (currentY + tableHeight + 20 > pageHeight) {
        doc.addPage();
        currentY = 10;
      }
      doc.text(captionText, 10, currentY);
      currentY += 5;
      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20;
      doc.setDrawColor(0);
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY);
      currentY = tableBottomY + 10;
    });
    doc.save("Sales-Report.pdf");
  };

  const Table = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <>
        {tableLoading ? (
          <SkeletonTheme height="40px">
            <Skeleton count={7} />
          </SkeletonTheme>
        ) : (
          <>
            <table
              className="table product-table table-striped"
              id="branches"
              data-caption="Sales Report"
            >
              <thead>
                <tr>
                  <th scope="col" className="no_sort">
                    Sr#
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("customer_name")}
                    className={getClassNamesFor("customer_name")}
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("date")}
                    className={getClassNamesFor("date")}
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("date")}
                    className={getClassNamesFor("date")}
                  >
                    Category
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("tax")}
                    className={getClassNamesFor("tax")}
                  >
                    Transaction Type
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("discount")}
                    className={getClassNamesFor("discount")}
                  >
                    Payment Type
                  </th>

                  <th
                    scope="col"
                    onClick={() => requestSort("payment_status")}
                    className={getClassNamesFor("payment_status")}
                  >
                    Bank
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("payment_status")}
                    className={getClassNamesFor("payment_status")}
                  >
                    Account Title
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("total_bill")}
                    className={getClassNamesFor("total_bill")}
                  >
                    Amount
                  </th>
                  <th scope="col" className="no_sort">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.length ? (
                  items.map((el, index) => (
                    <tr key={index}>
                      <td>
                        {currentPage === 1
                          ? index + 1
                          : (currentPage - 1) * limit + index + 1}
                      </td>
                      <td>{el.created_at}</td>
                      <td>{el.name}</td>
                      <td>{el.category_info}</td>
                      <td>{el.transaction_type}</td>
                      <td>{el?.payment_type_info}</td>
                      <td>{el?.bank_name}</td>
                      <td>{el.bank_title}</td>
                      <td>
                        <CurrencyFormat
                          value={el.amount}
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>

                      <td>
                        <div className="d-none">Delete</div>
                        <div className="d-flex">
                          <Link to={`/update-expense/${el.id}`}>
                            <span className="action-icons">
                              <AiOutlineEdit />
                            </span>
                          </Link>
                          <span
                            className="action-icons ms-2 text-danger"
                            onClick={() => delRecord(el.id)}
                          >
                            <RiDeleteBin5Line />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9" className="text-center text-capitalize">
                      No record found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex">
              <div className=" col-md-1 mb-1 ms-1 d-flex">
                {myData.length > 0 ? (
                  <div className="input-group limit-input">
                    <select
                      className="form-select"
                      value={limit}
                      onChange={(e) => getExp("1", e.target.value, values)}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                ) : null}
              </div>
              <div className="ms-auto col-md-3">
                {totalResults > limit && totalPages > 1 ? (
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={parseInt(limit)}
                    totalItemsCount={totalResults}
                    onChange={(e) => {
                      getExp(e, limit, values);
                    }}
                    pageRangeDisplayed={3}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const Customers = async () => {
    try {
      const responce = await UserServices.fethchCustomer("active");
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setCustomer([{ name: "Select Customer", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setCustomer([{ name: "No Customer Found", id: "" }]);
    }
  };

  const toggleRow = () => {
    if (!customer.length) {
      Customers();
    }
    setShowRow(!showRow);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        payment_type_id: "",
        sDate: "",
        eDate: "",
        payStatus: "",
        transaction_type: "",
      },
      validationSchema: "",
      onSubmit: (values) => {
        getExp(currentPage, limit, values);
      },
    });

  useEffect(() => {
    getExp(currentPage, limit, values);
  }, []);

  return (
    <div className="wrapper">
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="main-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-8  heading">
                      <h4>
                        <div
                          onClick={toggleRow}
                          className="d-inline-block w-auto align-bottom me-2"
                        >
                          {showRow ? (
                            <div className="filter-cancel">
                              <RxCross2 />
                            </div>
                          ) : (
                            <div className="filter-icon">
                              <TbFilterSearch />
                            </div>
                          )}
                        </div>
                        <span className="d-head">View</span> Expense
                      </h4>
                    </div>
                    <div className="col-md-4 d-flex justify-content-end">
                      <div
                        className="text-danger import-icons"
                        onClick={() => {
                          generatePdf(document.getElementById("products"));
                        }}
                      >
                        <FaFilePdf />
                      </div>
                      <a
                        href={exportLink}
                        rel="noreferrer"
                        className="text-success import-icons ms-2"
                      >
                        <BsCardList />
                      </a>
                    </div>
                  </div>

                  {showRow && (
                    <form onSubmit={handleSubmit} className="row mb-3">
                      <div className="col-md-2 mt-2">
                        <div className="form-group custom-group">
                          <label className="form-label">From</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Enter Date"
                            autoComplete="off"
                            name="sDate"
                            value={values.sDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.sDate && touched.sDate ? (
                            <p className="text-danger">{errors.sDate}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-2 mt-2">
                        <div className="form-group custom-group">
                          <label className="form-label">To</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Enter Date"
                            autoComplete="off"
                            name="eDate"
                            value={values.eDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.eDate && touched.eDate ? (
                            <p className="text-danger">{errors.eDate}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-2 mt-2">
                        <div className="form-group custom-group">
                          <label className="form-label">Payment type</label>

                          <select
                            className="form-select"
                            name="payment_type_id"
                            value={values.payment_type_id || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Payment</option>
                            {payment &&
                              payment.map((el) => (
                                <option key={el.id} value={el.id}>
                                  {el.name}
                                </option>
                              ))}
                          </select>

                          {errors.payment_type_id && touched.payment_type_id ? (
                            <p className="text-danger">
                              {errors.payment_type_id}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-2 mt-2">
                        <div className="form-group custom-group">
                          <label className="form-label">Transaction type</label>

                          <select
                            className="form-select"
                            name="transaction_type"
                            value={values.transaction_type || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Transaction</option>
                            {transaction &&
                              transaction.map((el, index) => (
                                <option key={index} value={el}>
                                  {el}
                                </option>
                              ))}
                          </select>

                          {errors.transaction_type &&
                          touched.transaction_type ? (
                            <p className="text-danger">
                              {errors.transaction_type}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-3 mt-3">
                        <button
                          className="btn1 mt-md-4 d-inline-block"
                          type="submit"
                        >
                          <span>Search</span>
                        </button>
                        <button
                          className="btn1 mt-md-4 d-inline-block ms-2"
                          type="button"
                          onClick={() => {
                            window.location.reload(true);
                          }}
                        >
                          <span>Reset</span>
                        </button>
                      </div>
                    </form>
                  )}
                  <div className="table-responsive">
                    <Table data={myData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
