import React, { useContext, useEffect, useState } from "react";

import { FaFilePdf } from "react-icons/fa6";

import useSortableData from "../../hooks/useSortableData";

import jsPDF from "jspdf";
import "jspdf-autotable";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import UserServices from "../../UserServices/UserServices";

import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import Pagination from "react-js-pagination";
import { RxCross2 } from "react-icons/rx";
import { TbFilterSearch } from "react-icons/tb";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import { TbDotsVertical } from "react-icons/tb";
import { BsCardList } from "react-icons/bs";
import { NoteState } from "../../context/notes/NoteState";

export default function PurchaseOrdersList() {
  const [myData, setMyData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [exportLink, setExportLink] = useState("");
  const { state } = useContext(NoteState);
  const { branch_id, user_id } = state;
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [customer, setCustomer] = useState([]);

  const [showRow, setShowRow] = useState(false);

  const getOrders = async (page, limit, values) => {
    setTableLoading(true);

    try {
      const responce = await UserServices.fetchPurchaseOrders(
        page,
        limit,
        values
      );

      setExportLink(
        process.env.REACT_APP_API_Link +
          "exports/export-purchase?page=" +
          page +
          "&limit=" +
          limit +
          "&payment_status=" +
          values.payStatus +
          "&start_date=" +
          values.sDate +
          "&end_date=" +
          values.eDate +
          "&customer=" +
          values.customer_id +
          "&user_id=" +
          user_id +
          "&branch_id=" +
          branch_id
      );
      let myDataarr;

      myDataarr = responce?.data?.data?.data;

      const res = myDataarr;
      const results = [];

      res.map((values) => {
        results.push({
          supplier_name: values.supplier_name,
          date: values.Date,

          payment_status: values.payment_status,
          total_bill: values.total_bill,
          tax: values.tax,
          id: values.id,
        });
        return null;
      });
      setTableLoading(false);
      setMyData([...results]);
      setLimit(responce?.data?.data?.data?.per_page);
      setTotalResults(responce?.data?.totalRecord);
      setTotalPages(responce?.data?.totalPages);
      setCurrentPage(responce?.data?.data?.current_page);
    } catch (err) {
      console.log(err);
      setTableLoading(false);
      setTotalResults("0");
      setTotalPages("1");
      setMyData([]);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */

  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");
    const tableOptions = {
      headStyles: { fillColor: [241, 132, 24], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10;
    let pageWidth = doc.internal.pageSize.width;
    let pageHeight = doc.internal.pageSize.height;
    tables.forEach((table, index) => {
      const captionText = table.getAttribute("data-caption");
      let tableHeight = doc.autoTable.previous.finalY - currentY;
      if (currentY + tableHeight + 20 > pageHeight) {
        doc.addPage();
        currentY = 10;
      }
      doc.text(captionText, 10, currentY);
      currentY += 5;
      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20;
      doc.setDrawColor(0);
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY);
      currentY = tableBottomY + 10;
    });
    doc.save("Purchase-List.pdf");
  };

  const Table = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <>
        {tableLoading ? (
          <SkeletonTheme height="40px">
            <Skeleton count={7} />
          </SkeletonTheme>
        ) : (
          <>
            <table
              className="table product-table table-striped"
              id="branches"
              data-caption="Purchase List"
            >
              <thead>
                <tr>
                  <th scope="col" className="no_sort">
                    Sr#
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("supplier_name")}
                    className={getClassNamesFor("supplier_name")}
                  >
                    Supplier Name
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("date")}
                    className={getClassNamesFor("date")}
                  >
                    Purchase Date
                  </th>

                  <th
                    scope="col"
                    onClick={() => requestSort("tax")}
                    className={getClassNamesFor("tax")}
                  >
                    Tax
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("total_bill")}
                    className={getClassNamesFor("total_bill")}
                  >
                    Total Bill
                  </th>
                  <th
                    scope="col"
                    onClick={() => requestSort("payment_status")}
                    className={getClassNamesFor("payment_status")}
                  >
                    Payment Status
                  </th>

                  <th scope="col" className="no_sort">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.length ? (
                  items.map((el, index) => (
                    <tr key={index}>
                      <td>
                        {currentPage === 1
                          ? index + 1
                          : (currentPage - 1) * limit + index + 1}
                      </td>
                      <td>{el.supplier_name}</td>
                      <td>{el.date}</td>

                      <td>
                        <CurrencyFormat
                          value={el.tax}
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={el.total_bill}
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td>{el.payment_status}</td>

                      <td>
                        <div className="btn-group dropstart">
                          <span
                            className="action-icons ms-2 "
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <TbDotsVertical />
                          </span>

                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item text-dark"
                                to={`/purchase-order-detail/${el.id}`}
                              >
                                View Purchase
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item text-dark"
                                to={`/purchase-order-payment-detail/${el.id}`}
                              >
                                Payment History
                              </Link>
                            </li>
                            <li>
                              <button
                                className="dropdown-item text-dark"
                                onClick={() => delRecord(el.id)}
                              >
                                Delete Purchase
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item text-dark"
                                onClick={() => returnOrder(el.id)}
                              >
                                Return Purchase
                              </button>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9" className="text-center text-capitalize">
                      No record found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="d-flex">
              <div className=" col-md-1 mb-1 ms-1 d-flex">
                {myData.length > 0 ? (
                  <div className="input-group limit-input">
                    <select
                      className="form-select"
                      value={limit}
                      onChange={(e) => getOrders("1", e.target.value, values)}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                ) : null}
              </div>
              <div className="ms-auto col-md-3">
                {totalResults > limit && totalPages > 1 ? (
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={parseInt(limit)}
                    totalItemsCount={totalResults}
                    onChange={(e) => {
                      getOrders(e, limit, values);
                    }}
                    pageRangeDisplayed={3}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const getSupplierS = async () => {
    try {
      const responce = await UserServices.fethchSupplier("active");
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setCustomer([{ name: "Select Supplier", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setCustomer([{ name: "No supplier Found", id: "" }]);
    }
  };

  const toggleRow = () => {
    if (!customer.length) {
      getSupplierS();
    }

    setShowRow(!showRow);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        customer_id: "",
        sDate: "",
        eDate: "",
        payStatus: "",
      },
      validationSchema: "",
      onSubmit: (values) => {
        getOrders(currentPage, limit, values);
      },
    });

  useEffect(() => {
    getOrders(currentPage, limit, values);
  }, []);

  const delRecord = async (id) => {
    try {
      await UserServices.deletePurchaseOrder(id);
      toast.success("Successfully deleted!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getOrders(currentPage, limit, values);
    } catch (error) {
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Error in deleting order!",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };

  const returnOrder = async (id) => {
    try {
      await UserServices.orderReturnPurchase(id);
      toast.success("Order Return successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      getOrders(currentPage, limit, values);
    } catch (error) {
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Error in deleting order!",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };

  return (
    <div className="wrapper">
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="main-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-8  heading">
                      <h4>
                        <div
                          onClick={toggleRow}
                          className="d-inline-block w-auto align-bottom me-2"
                        >
                          {showRow ? (
                            <div className="filter-cancel">
                              <RxCross2 />
                            </div>
                          ) : (
                            <div className="filter-icon">
                              <TbFilterSearch />
                            </div>
                          )}
                        </div>
                        <span className="d-head">Purchase</span> List
                      </h4>
                    </div>
                    <div className="col-md-4 d-flex justify-content-end">
                      <div
                        className="text-danger import-icons"
                        onClick={() => {
                          generatePdf(document.getElementById("products"));
                        }}
                      >
                        <FaFilePdf />
                      </div>
                      <a
                        href={exportLink}
                        rel="noreferrer"
                        className="text-success import-icons ms-2"
                      >
                        <BsCardList />
                      </a>
                    </div>
                  </div>

                  {showRow && (
                    <form onSubmit={handleSubmit} className="row mb-3">
                      <div className="col-md-3 mt-2">
                        <div className="form-group custom-group">
                          <label className="form-label">Supplier</label>
                          {customer.length === 0 ? (
                            <SkeletonTheme height="40px">
                              <Skeleton count={1} />
                            </SkeletonTheme>
                          ) : (
                            <select
                              className="form-select"
                              name="customer_id"
                              value={values.customer_id || ""}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                            >
                              {customer.map((el, index) => {
                                return (
                                  <option key={index} value={el.id}>
                                    {el.name}
                                  </option>
                                );
                              })}
                            </select>
                          )}

                          {errors.customer_id && touched.customer_id ? (
                            <p className="text-danger">{errors.customer_id}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-2 mt-2">
                        <div className="form-group custom-group">
                          <label className="form-label">From</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Enter Date"
                            autoComplete="off"
                            name="sDate"
                            value={values.sDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.sDate && touched.sDate ? (
                            <p className="text-danger">{errors.sDate}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-2 mt-2">
                        <div className="form-group custom-group">
                          <label className="form-label">To</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Enter Date"
                            autoComplete="off"
                            name="eDate"
                            value={values.eDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.eDate && touched.eDate ? (
                            <p className="text-danger">{errors.eDate}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-2 mt-2">
                        <div className="form-group custom-group">
                          <label className="form-label">Payment Status</label>

                          <select
                            className="form-select"
                            name="payStatus"
                            value={values.payStatus || ""}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Status</option>
                            <option value="Paid">Paid</option>
                            <option value="Pending">Pending</option>
                            <option value="Partial">Partial</option>
                          </select>

                          {errors.payStatus && touched.payStatus ? (
                            <p className="text-danger">{errors.payStatus}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-3 mt-3">
                        <button
                          className="btn1 mt-md-4 d-inline-block"
                          type="submit"
                        >
                          <span>Search</span>
                        </button>
                        <button
                          className="btn1 mt-md-4 d-inline-block ms-2"
                          type="button"
                          onClick={() => {
                            window.location.reload(true);
                          }}
                        >
                          <span>Reset</span>
                        </button>
                      </div>
                    </form>
                  )}
                  <div className="table-responsive">
                    <Table data={myData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
