import axios from "axios";
import authHeader from "./auth-header";
import authHeaderImage from "./auth-header-image";
import secureLocalStorage from "react-secure-storage";
const API_URL = process.env.REACT_APP_API_Link;

const Login = (values) => {
  return axios.post(API_URL + "login", {
    email: values.email,
    password: values.password,
  });
};
const UserRoles = () => {
  return axios.get(API_URL + "admin/active-role", {
    headers: authHeader(),
  });
};
const checkEmail = (check_email) => {
  return axios.post(
    API_URL + "admin/email-check",
    {
      email: check_email,
    },
    { headers: authHeader() }
  );
};
const checkEmailUpdate = (check_email, id) => {
  return axios.post(
    API_URL + "admin/email-check",
    {
      email: check_email,
      id: id,
    },
    { headers: authHeader() }
  );
};

const UsersAdd = (values) => {
  return axios.post(
    API_URL + "admin/add-user",
    {
      name: values.name,
      email: values.email,
      password: values.password,
      role: values.role,
      company: values.company,
      branch: values.branch,
    },
    { headers: authHeader() }
  );
};
const fethchUser = (limitNo, pageNo) => {
  return axios.get(API_URL + "admin/fetch-user", {
    params: {
      limit: limitNo,
      page: pageNo,
    },
    headers: authHeader(),
  });
};
const getUserInfo = (id) => {
  return axios.get(API_URL + "admin/fetch-user/" + id, {
    headers: authHeader(),
  });
};
const EditUser = (values, id) => {
  return axios.put(
    API_URL + "admin/edit-user",
    {
      id: id,
      name: values.name,
      email: values.email,
      role: values.role,
    },

    { headers: authHeader() }
  );
};
const userActions = (id, status) => {
  return axios.put(
    API_URL + "admin/change-status-user",
    { id: id, status: status },
    { headers: authHeader() }
  );
};
const searchUser = (search_name) => {
  return axios.get(API_URL + "admin/name-search-user", {
    params: {
      name: search_name,
    },
    headers: authHeader(),
  });
};

const checkRole = (check_role) => {
  return axios.get(API_URL + "admin/role-check", {
    params: {
      name: check_role,
    },
    headers: authHeader(),
  });
};
const RolesAdd = (values) => {
  return axios.post(
    API_URL + "admin/add-role",
    {
      name: values.name,
      description: values.description,
    },
    { headers: authHeader() }
  );
};
const fethchRole = (limitNo, pageNo) => {
  return axios.get(API_URL + "admin/fetch-role", {
    params: {
      limit: limitNo,
      page: pageNo,
    },
    headers: authHeader(),
  });
};
const roleActions = (id, status) => {
  return axios.put(
    API_URL + "admin/change-status-role",
    { id: id, status: status },
    { headers: authHeader() }
  );
};
const searchRole = (search_role) => {
  return axios.get(API_URL + "admin/name-search-role", {
    params: {
      name: search_role,
    },
    headers: authHeader(),
  });
};
const getRoleInfo = (id) => {
  return axios.get(API_URL + "admin/fetch-role/" + id, {
    headers: authHeader(),
  });
};
const checkRoleUpdate = (update_role, id) => {
  return axios.get(API_URL + "admin/role-check", {
    params: {
      name: update_role,
      id: id,
    },
    headers: authHeader(),
  });
};
const UpdateRoleRecord = (values, id) => {
  return axios.put(
    API_URL + "admin/edit-role",
    {
      id: id,
      name: values.name,
      description: values.description,
    },

    { headers: authHeader() }
  );
};
const subMenuAdd = (values) => {
  return axios.post(
    API_URL + "admin/add-submenu",
    {
      name: values.name,
      description: values.description,
    },
    { headers: authHeader() }
  );
};
const checkSubMenu = (check_sub) => {
  return axios.get(API_URL + "admin/submenu-check", {
    params: {
      name: check_sub,
    },
    headers: authHeader(),
  });
};
const fethchSubMenu = (limitNo, pageNo) => {
  return axios.get(API_URL + "admin/fetch-submenu", {
    params: {
      limit: limitNo,
      page: pageNo,
    },
    headers: authHeader(),
  });
};
const subMenuActions = (id, status) => {
  return axios.put(
    API_URL + "admin/change-status-submenu",
    { id: id, status: status },
    { headers: authHeader() }
  );
};
const searchSubMenu = (search_sub) => {
  return axios.get(API_URL + "admin/name-search-submenu", {
    params: {
      name: search_sub,
    },
    headers: authHeader(),
  });
};
const getSubMenuInfo = (id) => {
  return axios.get(API_URL + "admin/fetch-submenu/" + id, {
    headers: authHeader(),
  });
};
const checkSubUpdate = (update_role, id) => {
  return axios.get(API_URL + "admin/submenu-check", {
    params: {
      name: update_role,
      id: id,
    },
    headers: authHeader(),
  });
};
const UpdateSubMenuRecord = (values, id) => {
  return axios.put(
    API_URL + "admin/edit-submenu",
    {
      id: id,
      name: values.name,
      description: values.description,
    },

    { headers: authHeader() }
  );
};
const checkMenu = (check_menu) => {
  return axios.get(API_URL + "admin/menu-check", {
    params: {
      name: check_menu,
    },
    headers: authHeader(),
  });
};
const mainMenuAdd = (values) => {
  return axios.post(
    API_URL + "admin/add-menu",
    {
      name: values.name,
      description: values.description,
    },
    { headers: authHeader() }
  );
};
const fethchMenu = (limitNo, pageNo) => {
  return axios.get(API_URL + "admin/fetch-menu", {
    params: {
      limit: limitNo,
      page: pageNo,
    },
    headers: authHeader(),
  });
};
const getMenuInfo = (id) => {
  return axios.get(API_URL + "admin/fetch-menu/" + id, {
    headers: authHeader(),
  });
};
const menuActions = (id, status) => {
  return axios.put(
    API_URL + "admin/change-status-menu",
    { id: id, status: status },
    { headers: authHeader() }
  );
};
const searchMenu = (search_sub) => {
  return axios.get(API_URL + "admin/name-search-menu", {
    params: {
      name: search_sub,
    },
    headers: authHeader(),
  });
};
const UpdateMenu = (values, id) => {
  return axios.put(
    API_URL + "admin/edit-menu",
    {
      id: id,
      name: values.name,
      description: values.description,
    },

    { headers: authHeader() }
  );
};
const checkCategory = (check_cartegory) => {
  return axios.get(API_URL + "categories/check-category", {
    params: {
      name: check_cartegory,
    },
    headers: authHeader(),
  });
};
const checkUpdateCategory = (check_cartegory, id) => {
  return axios.get(API_URL + "categories/check-category", {
    params: {
      name: check_cartegory,
      id: id,
    },
    headers: authHeader(),
  });
};

const CatgryAdd = (values) => {
  return axios.post(
    API_URL + "categories/add-category",
    {
      name: values.name,
      type: values.type,
      description: values.description,
    },
    { headers: authHeader() }
  );
};
const UpdateCategory = (values, id) => {
  return axios.put(
    API_URL + "categories/edit-category",
    {
      id: id,
      name: values.name,
      type: values.type,
      description: values.description,
    },

    { headers: authHeader() }
  );
};
const fethchCategory = (status) => {
  return axios.get(API_URL + "categories/fetch-category", {
    params: {
      status: status ? status : "",
    },
    headers: authHeader(),
  });
};

const fetchCategory = (status, type) => {
  return axios.get(API_URL + "categories/fetch-category", {
    params: {
      status: status ? status : "",
      type: type ? type : "",
    },
    headers: authHeader(),
  });
};
const getCategoryInfo = (id) => {
  return axios.get(API_URL + "categories/fetch-category/" + id, {
    headers: authHeader(),
  });
};
const categoryActions = (id, status) => {
  return axios.put(
    API_URL + "categories/change-status",
    { id: id, status: status },
    { headers: authHeader() }
  );
};
const searchCategory = (search_category) => {
  return axios.get(API_URL + "categories/name-search-category", {
    params: {
      name: search_category,
    },
    headers: authHeader(),
  });
};
const AddCompanies = (values) => {
  return axios.post(
    API_URL + "admin/add-company",
    {
      name: values.name,
      phone: values.phone,
      address: values.address,
      description: values.description,
    },
    { headers: authHeader() }
  );
};

const fethchCompany = (limitNo, pageNo) => {
  return axios.get(API_URL + "admin/fetch-company", {
    params: {
      limit: limitNo,
      page: pageNo,
    },
    headers: authHeader(),
  });
};
const comapnyActions = (id, status) => {
  return axios.put(
    API_URL + "admin/change-status-company",
    { id: id, status: status },
    { headers: authHeader() }
  );
};
const SearchComapny = (search_comapny) => {
  return axios.get(API_URL + "admin/name-search-company", {
    params: {
      name: search_comapny,
    },
    headers: authHeader(),
  });
};
const UpdateCompanyRecord = (values, id) => {
  return axios.put(
    API_URL + "admin/edit-company",
    {
      id: id,
      name: values.name,
      phone: values.phone,
      address: values.address,
      description: values.description,
    },

    { headers: authHeader() }
  );
};
const getComapnyInfo = (id) => {
  return axios.get(API_URL + "admin/fetch-company/" + id, {
    headers: authHeader(),
  });
};
const GetCompanies = () => {
  return axios.get(API_URL + "admin/active-company", {
    headers: authHeader(),
  });
};
const checkBranch = (check_branch) => {
  return axios.get(API_URL + "admin/branch-check", {
    params: {
      name: check_branch,
    },
    headers: authHeader(),
  });
};
const AddBranch = (values) => {
  return axios.post(
    API_URL + "admin/add-branch",
    {
      name: values.name,
      company: values.company,
      phone: values.phone,
      address: values.address,
      description: values.description,
    },
    { headers: authHeader() }
  );
};
const fethchBranch = () => {
  return axios.get(API_URL + "admin/fetch-branch", {
    headers: authHeader(),
  });
};
const branchActions = (id, status) => {
  return axios.put(
    API_URL + "admin/change-status-branch",
    { id: id, status: status },
    { headers: authHeader() }
  );
};
const searchBranch = (search_branch) => {
  return axios.get(API_URL + "admin/name-search-branch", {
    params: {
      name: search_branch,
    },
    headers: authHeader(),
  });
};
const getBranchInfo = (id) => {
  return axios.get(API_URL + "admin/fetch-branch/" + id, {
    headers: authHeader(),
  });
};
const UpdateBranch = (values, id) => {
  return axios.put(
    API_URL + "admin/edit-branch",
    {
      id: id,
      name: values.name,
      phone: values.phone,
      address: values.address,
      description: values.description,
    },

    { headers: authHeader() }
  );
};
const checkBranchUpdate = (update_branch, id) => {
  return axios.get(API_URL + "admin/branch-check", {
    params: {
      name: update_branch,
      id: id,
    },
    headers: authHeader(),
  });
};
const activeCompany = () => {
  return axios.get(API_URL + "admin/active-company", {
    headers: authHeader(),
  });
};
const CompanyBranch = (company) => {
  return axios.get(API_URL + "admin/branch-by-company", {
    params: {
      company_id: company,
    },
    headers: authHeader(),
  });
};
const FethchMenues = () => {
  return axios.get(API_URL + "admin/active-menu", {
    headers: authHeader(),
  });
};
const FethchSubMenues = () => {
  return axios.get(API_URL + "admin/active-submenu", {
    headers: authHeader(),
  });
};
const AddSupliers = (values) => {
  return axios.post(
    API_URL + "suppliers/add-supplier",
    {
      name: values.name,
      phone: values.phone,
      address: values.address,
      description: values.description,
    },
    { headers: authHeader() }
  );
};
const checkSuppliers = (check_supplier) => {
  return axios.get(API_URL + "suppliers/supplier-check", {
    params: {
      name: check_supplier,
    },
    headers: authHeader(),
  });
};
const checkSuppliersUpdate = (check_supplier, id) => {
  return axios.get(API_URL + "suppliers/supplier-check", {
    params: {
      name: check_supplier,
      id: id,
    },
    headers: authHeader(),
  });
};
const fethchSupplier = (status) => {
  return axios.get(API_URL + "suppliers/fetch-supplier", {
    params: {
      status: status ? status : "",
    },
    headers: authHeader(),
  });
};
const searchSupplier = (search_supplier) => {
  return axios.get(API_URL + "suppliers/name-search-suppplier", {
    params: {
      name: search_supplier,
    },
    headers: authHeader(),
  });
};
const supplierActions = (id, status) => {
  return axios.put(
    API_URL + "suppliers/change-status-supplier",
    { id: id, status: status },
    { headers: authHeader() }
  );
};
const getSupplierInfo = (id) => {
  return axios.get(API_URL + "suppliers/fetch-supplier/" + id, {
    headers: authHeader(),
  });
};
const UpdateSupplierRecord = (values, id) => {
  return axios.put(
    API_URL + "suppliers/edit-supplier",
    {
      id: id,
      name: values.name,
      phone: values.phone,
      address: values.address,
      description: values.description,
    },

    { headers: authHeader() }
  );
};

const ProductAdd = (values) => {
  return axios.post(API_URL + "products/add-product", values, {
    headers: authHeaderImage(),
  });
};
const ProductUpdate = (values) => {
  return axios.post(API_URL + "products/edit-product", values, {
    headers: authHeaderImage(),
  });
};
const fethchPrdoucts = (limitNo, pageNo) => {
  return axios.get(API_URL + "products/fetch-product", {
    params: {
      limit: limitNo,
      page: pageNo,
    },
    headers: authHeader(),
  });
};
const filterPrdoucts = (limitNo, pageNo, typ) => {
  return axios.get(API_URL + "products/fetch-product", {
    params: {
      limit: limitNo,
      page: pageNo,
      type: typ,
    },
    headers: authHeader(),
  });
};
const fethchOutOfStockPrdoucts = (limitNo, pageNo) => {
  return axios.get(API_URL + "products/fetch-product", {
    params: {
      limit: limitNo,
      page: pageNo,
      qty: "0",
    },
    headers: authHeader(),
  });
};
const productsActions = (id, status) => {
  return axios.put(
    API_URL + "products/change-status-product",
    { id: id, status: status },
    { headers: authHeader() }
  );
};
const searchProduct = (search_product) => {
  return axios.get(API_URL + "products/name-search-product", {
    params: {
      name: search_product,
    },
    headers: authHeader(),
  });
};
const getProductsInfo = (id) => {
  return axios.get(API_URL + "products/fetch-product/" + id, {
    headers: authHeader(),
  });
};
const AddPermission = (values, selectedIds) => {
  return axios.post(
    API_URL + "admin/add-permission",
    {
      role_id: values.role,
      permission: selectedIds,
    },
    { headers: authHeader() }
  );
};
const seletedRole = (value) => {
  return axios.get(API_URL + "admin/fetch-permission", {
    params: {
      role_id: value,
    },
    headers: authHeader(),
  });
};
const UpdatePermission = (values, selectedIds) => {
  return axios.put(
    API_URL + "admin/edit-permission",
    {
      role_id: values.role,
      permission: selectedIds,
    },

    { headers: authHeader() }
  );
};
const checkCustomer = (check_customer) => {
  return axios.get(API_URL + "customers/name-check", {
    params: {
      name: check_customer,
    },
    headers: authHeader(),
  });
};
const AddCustomer = (values) => {
  return axios.post(
    API_URL + "customers/add-customer",
    {
      name: values.name,
      phone: values.phone,
      address: values.address,
      balance: values.balance,
    },
    { headers: authHeader() }
  );
};
const fethchCustomer = (status) => {
  return axios.get(API_URL + "customers/fetch-customer", {
    params: {
      status: status ? status : "",
    },
    headers: authHeader(),
  });
};
const customerActions = (id, status) => {
  return axios.put(
    API_URL + "customers/change-status",
    { id: id, status: status },
    { headers: authHeader() }
  );
};
const getCustomerInfo = (id) => {
  return axios.get(API_URL + "customers/fetch-customer/" + id, {
    headers: authHeader(),
  });
};
const checkCustomerUpdate = (update_customer, id) => {
  return axios.get(API_URL + "customers/name-check", {
    params: {
      name: update_customer,
      id: id,
    },
    headers: authHeader(),
  });
};
const searchCustomer = (search_customer) => {
  return axios.get(API_URL + "customers/name-search", {
    params: {
      name: search_customer,
    },
    headers: authHeader(),
  });
};
const UpdateCustomer = (values, id) => {
  return axios.put(
    API_URL + "customers/edit-customer",
    {
      id: id,
      name: values.name,
      phone: values.phone,
      address: values.address,
      balance: values.balance,
    },

    { headers: authHeader() }
  );
};
const AddDesignation = (values) => {
  return axios.post(
    API_URL + "designations/add-designation",
    {
      name: values.name,
      description: values.description,
    },
    { headers: authHeader() }
  );
};
const checkDesignation = (check_designation) => {
  return axios.get(API_URL + "designations/name-check", {
    params: {
      name: check_designation,
    },
    headers: authHeader(),
  });
};
const fethchDesignation = () => {
  return axios.get(API_URL + "designations/fetch-designation", {
    headers: authHeader(),
  });
};
const designationActions = (id, status) => {
  return axios.put(
    API_URL + "designations/change-status",
    { id: id, status: status },
    { headers: authHeader() }
  );
};
const searchDesignation = (search_designation) => {
  return axios.get(API_URL + "designations/name-search", {
    params: {
      name: search_designation,
    },
    headers: authHeader(),
  });
};
const getDesignationInfo = (id) => {
  return axios.get(API_URL + "designations/fetch-designation/" + id, {
    headers: authHeader(),
  });
};
const checkUpdateDesignation = (check_designation, id) => {
  return axios.get(API_URL + "designations/name-check", {
    params: {
      name: check_designation,
      id: id,
    },
    headers: authHeader(),
  });
};
const UpdateDesignation = (values, id) => {
  return axios.put(
    API_URL + "designations/edit-designation",
    {
      id: id,
      name: values.name,
      description: values.description,
    },

    { headers: authHeader() }
  );
};
const EmployeeAdd = (values) => {
  return axios.post(API_URL + "employees/add-employee", values, {
    headers: authHeaderImage(),
  });
};
const fethchEmployee = () => {
  return axios.get(API_URL + "employees/fetch-employee", {
    headers: authHeader(),
  });
};
const getEmployeInfo = (id) => {
  return axios.get(API_URL + "employees/fetch-employee/" + id, {
    headers: authHeader(),
  });
};
const EmployeeUpdate = (values) => {
  return axios.post(API_URL + "employees/edit-employee", values, {
    headers: authHeaderImage(),
  });
};
const employeeActions = (id, status) => {
  return axios.put(
    API_URL + "employees/change-status-employee",
    { id: id, status: status },
    { headers: authHeader() }
  );
};
const searchEmploye = (search_employe) => {
  return axios.get(API_URL + "employees/name-search-employee", {
    params: {
      name: search_employe,
    },
    headers: authHeader(),
  });
};
const AddProductToCart = (values) => {
  return axios.post(
    API_URL + "sales/add-to-cart",
    {
      product_code: values.product_code,
    },
    { headers: authHeader() }
  );
};

const fethchCart = () => {
  return axios.get(API_URL + "sales/fetch-cart", {
    headers: authHeader(),
  });
};
const editCart = (id, data, action) => {
  return axios.put(
    API_URL + "sales/edit-cart",
    { action: action, data: data, id: id },
    {
      headers: authHeader(),
    }
  );
};
const deleteCartItem = (id) => {
  return axios.delete(API_URL + "sales/delete-product", {
    params: {
      id: id,
    },
    headers: authHeader(),
  });
};

const emptyCart = () => {
  return axios.delete(API_URL + "sales/delete-cart", {
    headers: authHeader(),
  });
};

const AddProductToHold = (values) => {
  return axios.post(
    API_URL + "sales/hold-sale",
    {
      holder_name: values.product_code,
    },
    { headers: authHeader() }
  );
};

const deleteHoldItem = (name) => {
  return axios.post(
    API_URL + "sales/unhold-sale",
    {
      holder_name: name,
    },
    { headers: authHeader() }
  );
};

const fethchHold = () => {
  return axios.get(API_URL + "sales/hold-sale-list", {
    headers: authHeader(),
  });
};

const soldItem = (values) => {
  return axios.post(
    API_URL + "sales/bill",
    {
      received: values.received,
      date: values.date,
      customer: values.customer_id,
    },
    { headers: authHeader() }
  );
};

const fetchOrders = (page, limit, values) => {
  return axios.get(API_URL + "reports/sale-report", {
    params: {
      page: page,
      limit: limit,
      payment_status: values.payStatus,
      start_date: values.sDate,
      end_date: values.eDate,
      customer: values.customer_id,
    },
    headers: authHeader(),
  });
};

const fetchOrderDetail = (id) => {
  return axios.get(API_URL + "reports/sale-order-details", {
    params: {
      order_id: id,
    },
    headers: authHeader(),
  });
};

const deleteOrder = (id) => {
  return axios.put(
    API_URL + "sales/delete-order",
    {
      id: id,
    },
    { headers: authHeader() }
  );
};
const orderReturn = (id) => {
  return axios.put(
    API_URL + "sales/full-sale-return",
    {
      id: id,
    },
    { headers: authHeader() }
  );
};

const fetchOrderPaymentDetail = (id) => {
  return axios.get(API_URL + "sales/view-sale-payment", {
    params: {
      id: id,
    },
    headers: authHeader(),
  });
};

const payNewSell = (values, id) => {
  return axios.post(
    API_URL + "sales/new-sale-payment",
    {
      received: values.received,
      id: id,
    },
    { headers: authHeader() }
  );
};

const orderProductReturn = (id, details_id) => {
  return axios.put(
    API_URL + "sales/partial-sale-return",
    {
      id: id,
      details_id: details_id,
    },
    { headers: authHeader() }
  );
};

const checkForBarcode = (code) => {
  return axios.get(API_URL + "products/check-barcode", {
    params: {
      code: code,
    },
    headers: authHeader(),
  });
};

const AddPurchaseToCart = (values) => {
  return axios.post(
    API_URL + "purchases/add-to-cart",
    {
      product_code: values.product_code,
    },
    { headers: authHeader() }
  );
};

const AddImportPurchase = (values) => {
  return axios.post(
    API_URL + "purchases/import-purchase",
    {
      product_code: values.product_code,
    },
    { headers: authHeader() }
  );
};

const fethchPurchaseCart = () => {
  return axios.get(API_URL + "purchases/fetch-cart", {
    headers: authHeader(),
  });
};
const editPurchaseCart = (id, data, action) => {
  return axios.put(
    API_URL + "purchases/edit-cart",
    { action: action, data: data, id: id },
    {
      headers: authHeader(),
    }
  );
};
const deletePurchaseCartItem = (id) => {
  return axios.delete(API_URL + "purchases/delete-cart-item", {
    params: {
      id: id,
    },
    headers: authHeader(),
  });
};

const emptyPurchaseCart = () => {
  return axios.delete(API_URL + "purchases/delete-cart", {
    headers: authHeader(),
  });
};

const fethchPaymentTypes = (status) => {
  return axios.get(API_URL + "ptypes/fetch-payment-type", {
    params: {
      status: status ? status : "",
    },
    headers: authHeader(),
  });
};
const fethchTransactionTypes = (status) => {
  return axios.get(API_URL + "ledgers/transaction-type", {
    params: {
      status: status ? status : "",
    },
    headers: authHeader(),
  });
};

const purchaseItem = (values) => {
  return axios.post(
    API_URL + "purchases/add-purchase",
    {
      received: values.received,
      payment_id: values.payment_id,
      transaction_type: values.transaction_type,
      supplier_id: values.customer_id,
    },
    { headers: authHeader() }
  );
};

const ImportItem = (values) => {
  const formData = new FormData();
  formData.append("received", values.received);
  formData.append("payment_id", values.payment_id);
  formData.append("transaction_type", values.transaction_type);
  formData.append("supplier_id", values.customer_id);
  formData.append("file", values.excel_file);
  formData.append("date", values.date);

  return axios.post(API_URL + "purchases/import-purchase", formData, {
    headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
  });
};

const AddPurchaseToHold = (values) => {
  return axios.post(
    API_URL + "purchases/hold-purchase",
    {
      holder_name: values.product_code,
    },
    { headers: authHeader() }
  );
};

const fetchPurchaseOrders = (page, limit, values) => {
  return axios.get(API_URL + "reports/purchase-report", {
    params: {
      page: page,
      limit: limit,
      supplier: values.customer_id,
      payment_status: values.payStatus,
      start_date: values.sDate,
      end_date: values.eDate,
    },
    headers: authHeader(),
  });
};

const deletePurchaseOrder = (id) => {
  return axios.put(
    API_URL + "purchases/delete-order",
    {
      id: id,
    },
    { headers: authHeader() }
  );
};
const orderReturnPurchase = (id) => {
  return axios.put(
    API_URL + "purchases/full-purchase-return",
    {
      id: id,
    },
    { headers: authHeader() }
  );
};
const orderProductReturnPurchase = (id, details_id) => {
  return axios.put(
    API_URL + "purchases/partial-purchase-return",
    {
      id: id,
      details_id: details_id,
    },
    { headers: authHeader() }
  );
};

const fetchPurchaseOrderDetail = (id) => {
  return axios.get(API_URL + "reports/purchase-order-details", {
    params: {
      id: id,
    },
    headers: authHeader(),
  });
};

const fetchPurchasePaymentDetail = (id) => {
  return axios.get(API_URL + "purchases/view-purchase-payment", {
    params: {
      id: id,
    },
    headers: authHeader(),
  });
};

const payNewPurchase = (values, id) => {
  return axios.post(
    API_URL + "purchases/new-purchase-payment",
    {
      received: values.received,
      id: id,
      payment_id: values.payment_id,
      transaction_type: values.transaction_type,
    },
    { headers: authHeader() }
  );
};

const deletePurchaseHoldItem = (name) => {
  return axios.post(
    API_URL + "purchases/unhold-purchase",
    {
      holder_name: name,
    },
    { headers: authHeader() }
  );
};

const fethchPurchaseHold = () => {
  return axios.get(API_URL + "purchases/hold-purchase-list", {
    headers: authHeader(),
  });
};

const fethchPayments = (limitNo, pageNo) => {
  return axios.get(API_URL + "ptypes/fetch-payment-type", {
    params: {
      limit: limitNo,
      page: pageNo,
    },
    headers: authHeader(),
  });
};

const paymentsActions = (id, status) => {
  return axios.put(
    API_URL + "ptypes/change-status",
    { id: id, status: status },
    { headers: authHeader() }
  );
};

const addPayment = (values) => {
  return axios.post(
    API_URL + "ptypes/add-payment-type",
    {
      name: values.name,
      description: values.description,
    },
    { headers: authHeader() }
  );
};

const checkPayment = (check_menu, recordId) => {
  return axios.get(API_URL + "ptypes/name-check", {
    params: {
      name: check_menu,
      id: recordId ? recordId : "",
    },
    headers: authHeader(),
  });
};

const searchPaymen = (search_sub) => {
  return axios.get(API_URL + "ptypes/name-search", {
    params: {
      name: search_sub,
    },
    headers: authHeader(),
  });
};

const getPaymentsInfo = (id) => {
  return axios.get(API_URL + "ptypes/fetch-payment-type/" + id, {
    headers: authHeader(),
  });
};

const UpdatePayment = (values, id) => {
  return axios.put(
    API_URL + "ptypes/edit-payment-type",
    {
      id: id,
      name: values.name,
      description: values.description,
    },

    { headers: authHeader() }
  );
};

const fethchTransactions = (limitNo, pageNo) => {
  return axios.get(API_URL + "ledgers/transaction-type", {
    params: {
      limit: limitNo,
      page: pageNo,
    },
    headers: authHeader(),
  });
};

const fethchLedgers = (limitNo, pageNo) => {
  return axios.get(API_URL + "ledgers/ledger-type", {
    params: {
      limit: limitNo,
      page: pageNo,
    },
    headers: authHeader(),
  });
};

const fethchBanks = (limitNo, pageNo) => {
  return axios.get(API_URL + "banks/fetch-bank", {
    params: {
      limit: limitNo,
      page: pageNo,
    },
    headers: authHeader(),
  });
};

const banksActions = (id, status) => {
  return axios.put(
    API_URL + "banks/change-status-bank",
    { id: id, status: status },
    { headers: authHeader() }
  );
};

const addBank = (values) => {
  return axios.post(
    API_URL + "banks/add-bank",
    {
      name: values.name,
      description: values.description,
    },
    { headers: authHeader() }
  );
};

const searchBank = (search_sub) => {
  return axios.get(API_URL + "banks/name-search-bank", {
    params: {
      name: search_sub,
    },
    headers: authHeader(),
  });
};

const getBanksInfo = (id) => {
  return axios.get(API_URL + "banks/fetch-bank/" + id, {
    headers: authHeader(),
  });
};

const UpdateBank = (values, id) => {
  return axios.put(
    API_URL + "banks/edit-bank",
    {
      id: id,
      name: values.name,
      description: values.description,
    },

    { headers: authHeader() }
  );
};

/////////////////////////////////////////////////////////Settings-Currency//////////////////////////////////////////////////

const searchCurrency = (search_category) => {
  return axios.get(API_URL + "currencies/name-search-currency", {
    params: {
      name: search_category,
    },
    headers: authHeader(),
  });
};

const CurrencyAdd = (values) => {
  return axios.post(
    API_URL + "currencies/add-currency",
    {
      name: values.name,
      code: values.code,
      symbol: values.symbol,
    },
    { headers: authHeader() }
  );
};

const UpdateCurrency = (values, id) => {
  return axios.put(
    API_URL + "currencies/edit-currency",
    {
      id: id,
      name: values.name,
      code: values.code,
      symbol: values.symbol,
    },

    { headers: authHeader() }
  );
};

const fetchCurrency = (status) => {
  return axios.get(API_URL + "currencies/fetch-currency", {
    params: {
      status: status ? status : "",
    },
    headers: authHeader(),
  });
};

const getCurrencyInfo = (id) => {
  return axios.get(API_URL + "currencies/fetch-currency/" + id, {
    headers: authHeader(),
  });
};

const currencyActions = (id, status) => {
  return axios.put(
    API_URL + "currencies/change-status-currency",
    { id: id, status: status },
    { headers: authHeader() }
  );
};

const checkUpdateCurrency = (update_currency, id) => {
  return axios.get(API_URL + "currencies/name-check-currency", {
    params: {
      name: update_currency,
      id: id,
    },
    headers: authHeader(),
  });
};

const checkCurrency = (check_currency) => {
  return axios.get(API_URL + "currencies/name-check-currency", {
    params: {
      name: check_currency,
    },
    headers: authHeader(),
  });
};

///////////////////////////////////////////////////////////////All-Bills///////////////////////////////////////////////
const getBills = () => {
  return axios.get(API_URL + "reports/sale-report", {
    headers: authHeader(),
  });
};

const billExport = () => {
  const userId = secureLocalStorage.getItem("userId");
  const branchId = secureLocalStorage.getItem("branchId");
  return axios.get(API_URL + "exports/all-bill-export", {
    params: {
      branch_id: branchId,
      user_id: userId,
    },
    headers: authHeader(),
  });
};

const billingDetail = (id) => {
  return axios.get(API_URL + `reports/sale-order-details`, {
    params: {
      order_id: id,
    },
    headers: authHeader(),
  });
};

///////////////////////////////////////////Reports//////////////////////////////////////////
const CustomerReport = (page, limit, values) => {
  return axios.get(API_URL + "reports/customer-report", {
    params: {
      page: page,
      limit: limit,
      payment_status: values.payStatus,
      start_date: values.sDate,
      end_date: values.eDate,
      customer: values.customer_id,
    },
    headers: authHeader(),
  });
};
const SupplierReport = (page, limit, values) => {
  return axios.get(API_URL + "reports/supplier-report", {
    params: {
      page: page,
      limit: limit,
      payment_status: values.payStatus,
      start_date: values.sDate,
      end_date: values.eDate,
      supplier_id: values.customer_id,
      product_id: values.product_id,
    },
    headers: authHeader(),
  });
};
const InventoryReport = (page, limit, values) => {
  return axios.get(API_URL + "reports/stock-report", {
    params: {
      page: page,
      limit: limit,
      payment_status: values.payStatus,
      start_date: values.sDate,
      end_date: values.eDate,
      supplier: values.customer_id,
      category: values.category_id,
    },
    headers: authHeader(),
  });
};

const ProductList = () => {
  return axios.get(API_URL + "products/product-list", {
    headers: authHeader(),
  });
};

////////////////////////////////////////////Filters/////////////////////////////////////////

const CategoriesValues = () => {
  return axios.get(API_URL + "categories/fetch-category", {
    params: {
      type: "expenses",
    },
    headers: authHeader(),
  });
};

const TransactionValues = () => {
  return axios.get(API_URL + "ledgers/transaction-type", {
    params: {
      status: "active",
    },
    headers: authHeader(),
  });
};

const PaymentValues = () => {
  return axios.get(API_URL + "ptypes/fetch-payment-type", {
    headers: authHeader(),
  });
};

const BankValues = () => {
  return axios.get(API_URL + "banks/fetch-bank", {
    headers: authHeader(),
  });
};

///////////////////////////////////////////Expanses///////////////////////////////////////
const AddExpenses = (values) => {
  return axios.post(
    API_URL + "expenses/add-expense",
    {
      name: values.name,
      amount: values.amount,
      date: values.date,
      transaction_type: values.transaction_type,
      category_id: values.category_id,
      payment_type_id: values.payment_type_id,
      description: values.description,
      bank_id: values.bank_id,
      check_number: values.check_number,
      status: "active",
    },
    { headers: authHeader() }
  );
};

const UpdateExpenses = (values) => {
  return axios.put(
    API_URL + "expenses/edit-expense",
    {
      id: values.id,
      name: values.name,
      amount: values.amount,
      date: values.date,
      transaction_type: values.transaction_type,
      category_id: values.category_id,
      payment_type_id: values.payment_type_id,
      description: values.description,
      bank_id: values.bank_id,
      check_number: values.check_number,
      status: "active",
    },
    { headers: authHeader() }
  );
};

const DeleteExpenses = (id) => {
  return axios.put(
    API_URL + "expenses/change-status",
    {
      id: id,
      status: "deleted",
    },
    { headers: authHeader() }
  );
};

const FetchExpenses = (limit, page, values) => {
  return axios.get(API_URL + "expenses/fetch-expense", {
    params: {
      // limit: limit,
      // page: page,
      start_date: values.sDate,
      end_date: values.eDate,
      payment_type_id: values.payment_type_id,
      transaction_type: values.transaction_type,
      status: "active",
    },
    headers: authHeader(),
  });
};

const getExpenses = (id) => {
  return axios.get(API_URL + `expenses/fetch-expense/${id}`, {
    headers: authHeader(),
  });
};

function getFormattedDate() {
  const today = new Date();
  const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 to month since it's zero-based
  const day = today.getDate().toString().padStart(2, "0");
  const year = today.getFullYear();

  return `${year}-${month}-${day}`;
}

//Ledger

const AddLedgerData = (values) => {
  return axios.post(
    API_URL + "ledgers/add-ledger",
    {
      type: values.type,
      amount: values.amount,
      date: values.date,
      transaction_type: values.transaction_type,
      ledger_type: values.ledger_type,
      bank_id: values.bank_id,
      description: values.description,
      // status: "active",
    },
    { headers: authHeader() }
  );
};

const FetchLedger = (limit, page, values) => {
  return axios.get(API_URL + "ledgers/fetch-ledger", {
    params: {
      // limit: limit,
      // page: page,
      start_date: values.sDate,
      end_date: values.eDate,
      // payment_type_id: values.payment_type_id,
      transaction_type: values.transaction_type,
      ledger_type: values.ledger_type,
      // status: "active",
    },
    headers: authHeader(),
  });
};

const getLedger = (id) => {
  return axios.get(API_URL + `ledgers/fetch-ledger/${id}`, {
    headers: authHeader(),
  });
};

const DeleteLedger = (id) => {
  return axios.put(
    API_URL + "ledgers/delete-ledger",
    {
      id: id,
      status: "deleted",
    },
    { headers: authHeader() }
  );
};
const UpdateLedgerData = (values) => {
  return axios.put(
    API_URL + `ledgers/edit-ledger`,
    {
      type: values.type,
      amount: values.amount,
      date: values.date,
      transaction_type: values.transaction_type,
      ledger_type: values.ledger_type,
      bank_id: values.bank_id,
      description: values.description,
      id: values.id,
      // status: "active",
    },
    { headers: authHeader() }
  );
};

//Liabilities
const FetchLiabilities = (limit, page, values) => {
  return axios.get(API_URL + "liabilities/fetch-liability", {
    params: {
      start_date: values.sDate,
      end_date: values.eDate,
      category_id: values.category_id,
      return_status: values.return_status,
    },
    headers: authHeader(),
  });
};

const AddLiabilities = (values) => {
  return axios.post(
    API_URL + "liabilities/add-liability",
    {
      due_date: values.due_date,
      amount: values.amount,
      category_id: values.category_id,
      creditor_contact: values.creditor_contact,
      creditor_name: values.creditor_name,
      description: values.description,
      // status: "active",
    },
    { headers: authHeader() }
  );
};

const DeleteLiabilities = (id) => {
  return axios.put(
    API_URL + "liabilities/delete-liability",
    {
      id: id,
      status: "deleted",
    },
    { headers: authHeader() }
  );
};

const getLiabilities = (id) => {
  return axios.get(API_URL + `liabilities/fetch-liability/${id}`, {
    headers: authHeader(),
  });
};

const UpdateLiabilities = (values) => {
  return axios.put(
    API_URL + "liabilities/edit-liability",
    {
      due_date: values.due_date,
      id: values.id,
      amount: values.amount,
      category_id: values.category_id,
      creditor_contact: values.creditor_contact,
      creditor_name: values.creditor_name,
      description: values.description,
      // status: "active",
    },
    { headers: authHeader() }
  );
};

const ReturnLiabilities = (values, id) => {
  return axios.put(
    API_URL + "liabilities/return-liability",
    {
      amount: values.amount,
      date: values.date,
      resource: values.resource,
      id: id,
      // status: "active",
    },
    { headers: authHeader() }
  );
};

//BalanceSheet
const BalanceSheetSale = async (values) => {
  
  return axios.get(API_URL + "balance-sheet/balance-sheet-sale", {
    params: {
      start_date: values?.sDate,
      end_date: values?.eDate,
    },
    headers: authHeader(),
  });
};

const BalanceSheetExp = async (values) => {
  console.log(values);
  return axios.get(API_URL + "balance-sheet/balance-sheet-expense", {
    params: {
      start_date: values?.sDate,
      end_date: values?.eDate,
    },
    headers: authHeader(),
  });
};


const saleCounter = (page, limit, values) => {
  return axios.get(API_URL + "sale-counter/sale-by-product", {
    params: {
      page: page,
      limit: limit,
      payment_status: values.payStatus,
      start_date: values.sDate,
      end_date: values.eDate,
      customer: values.customer_id,
    },
    headers: authHeader(),
  });
};

const saleReturnValues = (page, limit, values) => {
  return axios.get(API_URL + "reports/sale-return-report", {
    params: {
      page: page,
      limit: limit,
      payment_status: values.payStatus,
      customer_id: values.customer_id,
      start_date: values.sDate,
      end_date: values.eDate,

    },
    headers: authHeader(),
  });
};


const purchaseReturnValues = (page, limit, values) => {
  return axios.get(API_URL + "reports/purchase-return-report", {
    params: {
      page: page,
      limit: limit,
      payment_status: values.payStatus,
      supplier_id: values.customer_id,
      start_date: values.sDate,
      end_date: values.eDate,

    },
    headers: authHeader(),
  });
};

const UserServices = {
  Login,
  UserRoles,
  checkEmail,
  checkEmailUpdate,
  UsersAdd,
  fethchUser,
  getUserInfo,
  EditUser,
  userActions,
  searchUser,
  checkRole,
  RolesAdd,
  fethchRole,
  roleActions,
  searchRole,
  getRoleInfo,
  checkRoleUpdate,
  UpdateRoleRecord,
  subMenuAdd,
  checkSubMenu,
  fethchSubMenu,
  subMenuActions,
  searchSubMenu,
  getSubMenuInfo,
  checkSubUpdate,
  UpdateSubMenuRecord,
  checkMenu,
  mainMenuAdd,
  fethchMenu,
  getMenuInfo,
  menuActions,
  searchMenu,
  UpdateMenu,
  checkCategory,
  checkUpdateCategory,
  CatgryAdd,
  UpdateCategory,
  fethchCategory,
  getCategoryInfo,
  categoryActions,
  searchCategory,
  AddCompanies,
  fethchCompany,
  comapnyActions,
  SearchComapny,
  UpdateCompanyRecord,
  getComapnyInfo,
  GetCompanies,
  checkBranch,
  AddBranch,
  fethchBranch,
  branchActions,
  searchBranch,
  getBranchInfo,
  UpdateBranch,
  checkBranchUpdate,
  activeCompany,
  CompanyBranch,
  FethchMenues,
  FethchSubMenues,
  AddSupliers,
  checkSuppliers,
  checkSuppliersUpdate,
  fethchSupplier,
  searchSupplier,
  supplierActions,
  getSupplierInfo,
  UpdateSupplierRecord,
  ProductAdd,
  ProductUpdate,
  fethchPrdoucts,
  filterPrdoucts,
  fethchOutOfStockPrdoucts,
  productsActions,
  searchProduct,
  getProductsInfo,
  AddPermission,
  seletedRole,
  UpdatePermission,
  checkCustomer,
  AddCustomer,
  fethchCustomer,
  customerActions,
  getCustomerInfo,
  checkCustomerUpdate,
  searchCustomer,
  UpdateCustomer,
  AddDesignation,
  checkDesignation,
  fethchDesignation,
  designationActions,
  searchDesignation,
  getDesignationInfo,
  checkUpdateDesignation,
  UpdateDesignation,
  EmployeeAdd,
  fethchEmployee,
  getEmployeInfo,
  EmployeeUpdate,
  employeeActions,
  searchEmploye,
  AddProductToCart,
  fethchCart,
  editCart,
  deleteCartItem,
  emptyCart,
  AddProductToHold,
  fethchHold,
  deleteHoldItem,
  soldItem,
  fetchOrders,
  fetchOrderDetail,
  deleteOrder,
  orderReturn,
  fetchOrderPaymentDetail,
  payNewSell,
  orderProductReturn,
  checkForBarcode,
  AddPurchaseToCart,
  fethchPurchaseCart,
  editPurchaseCart,
  deletePurchaseCartItem,
  emptyPurchaseCart,
  purchaseItem,
  fethchPurchaseHold,
  fethchPayments,
  paymentsActions,
  addPayment,
  checkPayment,
  searchPaymen,
  getPaymentsInfo,
  UpdatePayment,
  deletePurchaseHoldItem,
  AddPurchaseToHold,
  fetchPurchaseOrders,
  deletePurchaseOrder,
  orderReturnPurchase,
  orderProductReturnPurchase,
  fetchPurchaseOrderDetail,
  fetchPurchasePaymentDetail,
  payNewPurchase,
  fethchPaymentTypes,
  fethchTransactionTypes,
  fethchTransactions,
  fethchLedgers,
  UpdateBank,
  getBanksInfo,
  searchBank,
  addBank,
  banksActions,
  fethchBanks,
  fetchCategory,
  //settings
  CurrencyAdd,
  UpdateCurrency,
  fetchCurrency,
  getCurrencyInfo,
  currencyActions,
  searchCurrency,
  checkUpdateCurrency,
  checkCurrency,
  AddImportPurchase,
  //Bills
  getBills,
  billExport,
  billingDetail,

  //Reports
  CustomerReport,
  SupplierReport,
  InventoryReport,
  ProductList,

  //filters
  CategoriesValues,
  BankValues,
  PaymentValues,
  TransactionValues,

  ImportItem,
  //Expenses
  AddExpenses,
  FetchExpenses,
  getFormattedDate,
  DeleteExpenses,
  getExpenses,
  UpdateExpenses,

  //Ledger
  AddLedgerData,
  FetchLedger,
  DeleteLedger,
  getLedger,
  UpdateLedgerData,

  //Liabilities
  FetchLiabilities,
  AddLiabilities,
  DeleteLiabilities,
  getLiabilities,
  UpdateLiabilities,
  ReturnLiabilities,

  //BalanceSheet
  BalanceSheetSale,
  BalanceSheetExp,

  saleCounter,
  saleReturnValues,
  purchaseReturnValues,
};

export default UserServices;
