import React, { useContext, useState } from "react";
import "./Login.css";
import { useFormik } from "formik";
import { login } from "../Schemas/Index";
import UserServices from "../../UserServices/UserServices";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";
import "react-toastify/dist/ReactToastify.css";
import logoImage from "../../Images/logo1.png";
import loadingImage from "../../Images/loading-icon.png";
import { AiOutlineUser } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
export default function Login() {
  const { dispatch: ctxDispatch } = useContext(NoteState);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const Login = async (values) => {
    try {
      setLoading(true);
      const response = await UserServices.Login(values);
      if (response.status === 200) {
        ctxDispatch({
          type: "LOGIN",
          accessToken: response.data.data.token,
          branchId: response.data.branch_id,
          userId: response.data.user_id,
        });
        setLoading(false);
        navigate("/dashboard");
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 401) {
        toast.error("Wrong username or password!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 422) {
        toast.error("Unproccessable content!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const initialValues = {
    email: "",
    password: "",
  };
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: login,
      onSubmit: (values) => {
        Login(values);
      },
    });
  return (
    <>
      <ToastContainer />
      <div id="login">
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="login-header">
            <img src={logoImage} alt="logo" />
          </div>
          <div className="login-heading">
            <span>
              <span className="text-dark">Sign</span> In
            </span>
          </div>
          <div className="text-secondary login-sub-heading">
            <p>Please enter your username and password to access the site.</p>
          </div>
          <div className="login-fields">
            <div className="form-group">
              <input
                type="email"
                name="email"
                placeholder="Email"
                autoComplete="off"
                value={values.email || ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label>
                <AiOutlineUser /> Email
              </label>
              {errors.email && touched.email ? (
                <p className="text-danger">{errors.email}</p>
              ) : null}
            </div>
            <div className="form-group">
              <input
                type="password"
                name="password"
                placeholder="Password"
                autoComplete="off"
                value={values.password || ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label>
                <RiLockPasswordLine /> Password
              </label>
              {errors.password && touched.password ? (
                <p className="text-danger">{errors.password}</p>
              ) : null}
            </div>
            <button className="mt-3 mb-5 login-button" type="submit">
              Login
            </button>
          </div>
          {/* <div className="login-footer">
            <p className="text-secondary">
              Don't have access to account?{" "}
              <span className="text-dark ms-2">
                <strong>Forget Password</strong>
              </span>
            </p>
            <p className="text-secondary">
              Don't have an account?{" "}
              <span className="text-dark ms-2">
                <strong>Sign Up</strong>
              </span>
            </p>
          </div> */}
        </form>
      </div>
      <div className={`login-loader ${loading ? "in" : ""}`}>
        <img src={loadingImage} alt="loading" />
        <div class="jumping-dots-loader">
          {" "}
          <span></span> <span></span> <span></span>{" "}
        </div>
        <div class="moving-gradient"></div>
      </div>
    </>
  );
}
