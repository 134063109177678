import React, { useContext, useEffect, useState } from "react";
import { filter } from "../Schemas/Index";
import jsPDF from "jspdf";
import "jspdf-autotable";
import UserServices from "../../UserServices/UserServices";
import { Pie } from "react-chartjs-2";
import "../../App.css";

import { useFormik } from "formik";

export default function ViewLiabilities() {
  const [saleData, setSaleData] = useState([]);
  const [expData, setExpData] = useState([]);

  const [saleTotal, setSaleTotal] = useState(0);
  const pieChartData = {
    labels: saleData.map((item) => item.product),
    datasets: [
      {
        label: "Sales Data",
        data: saleData.map((item) => item.total_sum),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const { values, touched, errors, handleChange, handleSubmit, handleBlur } =
    useFormik({
      validationSchema: filter,
      initialValues: {
        sDate: "",
        eDate: "",
      },
      onSubmit: (values, action) => {
        FetchBalanceSale(values);
        FetchBalanceExp(values);
        action.resetForm();
      },
    });
  const FetchBalanceSale = async (values) => {
    const response = await UserServices.BalanceSheetSale(values);
    setSaleData(response?.data?.data);
  };
  const FetchBalanceExp = async (values) => {
    const response = await UserServices.BalanceSheetExp(values);
    setExpData(response?.data?.data);
  };

  useEffect(() => {
    FetchBalanceExp();
    FetchBalanceSale();
  }, []);

  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");
    const tableOptions = {
      headStyles: { fillColor: [241, 132, 24], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10;
    let pageWidth = doc.internal.pageSize.width;
    let pageHeight = doc.internal.pageSize.height;
    tables.forEach((table, index) => {
      const captionText = table.getAttribute("data-caption");
      let tableHeight = doc.autoTable.previous.finalY - currentY;
      if (currentY + tableHeight + 20 > pageHeight) {
        doc.addPage();
        currentY = 10;
      }
      doc.text(captionText, 10, currentY);
      currentY += 5;
      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20;
      doc.setDrawColor(0);
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY);
      currentY = tableBottomY + 10;
    });
    doc.save("Sales-Report.pdf");
  };

  return (
    <div className="wrapper">
      <div className="site-main-design">
        <div className="content">
          <div className="">
            <div className="mt-2 mb-5">
              <form className="row mb-3 ms-5" onSubmit={handleSubmit}>
                <div className="col-md-2 mt-2">
                  <div className="form-group custom-group">
                    <label className="form-label">From</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter Date"
                      autoComplete="off"
                      name="sDate"
                      value={values.sDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.sDate && touched.sDate ? (
                      <p className="text-danger">{errors.sDate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2 mt-2">
                  <div className="form-group custom-group">
                    <label className="form-label">To</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter Date"
                      autoComplete="off"
                      name="eDate"
                      value={values.eDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.eDate && touched.eDate ? (
                      <p className="text-danger">{errors.eDate}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-3 mt-3">
                  <button className="btn1 mt-md-4 d-inline-block" type="submit">
                    <span>Search</span>
                  </button>
                  <button
                    className="btn1 mt-md-4 d-inline-block ms-2"
                    type="button"
                    onClick={() => {
                      window.location.reload(true);
                    }}
                  >
                    <span>Reset</span>
                  </button>
                </div>
              </form>
            </div>

            <div className="row ss s rounded mb-4">
              <div className="col-12 bb mb-4   rounded">
                <h4 className="h">Sales Breakup</h4>
              </div>
              <div className="col-sm-6">
                <Table1 data={saleData} />
              </div>
              <div
                className="col-sm-4 mb-4 "
                style={{ position: "relative", height: "40vh" }}
              >
                <Pie data={pieChartData} fullSize={false} />
              </div>
            </div>
            <div className="row s   rounded mb-4">
              <div className="col-12 bb mb-4 rounded">
                <h4 className="h">Expenses Breakup</h4>
              </div>
              <div className="col">
                <Table data={expData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Table1 = ({ data }) => {
  return (
    <div style={{ overflowX: "auto" }}>
      <table
        className="table product-table table-striped rounded"
        id="branches"
        data-caption="Sales Report"
        style={{ transform: "scale(0.9)", transformOrigin: "top" }}
      >
        <thead>
          <tr>
            <th scope="col" className="no_sort">
              Particulars
            </th>
            <th scope="col">Percentage</th>
            <th scope="col">Quantity</th>
            <th scope="col">Credit</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((el, index) => (
              <tr key={index}>
                <td>{el.product}</td>
                <td>{el.percentage} %</td>
                <td>{el.Quantity}</td>
                <td>{el.total_sum}</td>
              </tr>
            ))}
          {data && (
            <tr>
              <th>Grand Total</th>
              <th>{data.reduce((total, el) => total + el.percentage, 0)} %</th>
              <th></th>
              <th>{data.reduce((total, el) => total + el.total_sum, 0)}</th>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const Table = ({ data }) => {
  return (
    <>
      <table
        className="table product-table table-striped rounded"
        id="branches"
        data-caption="Sales Report"
      >
        <thead>
          <tr>
            <th scope="col" className="no_sort">
              Particulars
            </th>
            <th scope="col">Percentage</th>

            <th scope="col">Quantity</th>

            <th scope="col">Credit</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((el, index) => (
              <tr key={index}>
                <td>{el.category}</td>
                <td>{el.percentage} %</td>
                <td>{el.total_quantity}</td>
                <td>{el.total_sum}</td>
              </tr>
            ))}
          {data && (
            <tr>
              <th>Grand Total</th>
              <th>{data.reduce((total, el) => total + el.percentage, 0)} %</th>
              <th></th>
              <th>{data.reduce((total, el) => total + el.total_sum, 0)}</th>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};
