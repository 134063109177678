/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { BranchesUpdt } from "../Schemas/Index";
import { useFormik } from "formik";
import { IoMdCloudUpload } from "react-icons/io";
import placeHolder from "../../Images/users.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import UserServices from "../../UserServices/UserServices";
import { MaskField } from "react-mask-field";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BiSearchAlt } from "react-icons/bi";

export default function EmployeProfile() {
    const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const [picture, setPicture] = useState("");
  const [designation, setDesignation] = useState([]);
  const [name, setName] = useState("");
  const [prfoImg, setPrfoImg] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [idCard, setIdCard] = useState("");
  const [salary, setSalary] = useState("");
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const { id } = useParams();

  const handleUpload = async (event) => {
    setFile(event.target.files[0]);
    var image = document.getElementById("output");
    image.src = URL.createObjectURL(event.target.files[0]);
  };
  const Designations = async () => {
    try {
      const responce = await UserServices.fethchDesignation();
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setDesignation([{ name: "Select Designation", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setDesignation([{ name: "No Designation Found", id: "" }]);
    }
  };
  const getEmployee = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getEmployeInfo(id);

      if (responce.status === 200) {
        let data = responce.data.data;
        await data.map((value) => {
          setName(value.name);
          setPhone(value.phone);
          setEmail(value.email);
          setAddress(value.address);
          setGender(value.gender);
          setPrfoImg(value.image);
          setIdCard(value.id_card);
          setSalary(value.salary);
          setSelectedDesignation(value.designation_id);
          setJoiningDate(value.joining_date);
        });
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.message === "Network Error") {
        setLoading(false);
      } else if (err.response.status === 401) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    Designations();
    getEmployee();
  }, []);

  const updateEmployee = async (values, action) => {
    const formData = new FormData();

    if (file !== "") {
      formData.append("image", file);
      formData.append("imageName", file.name);
    }
    formData.append("name", values.name);
    formData.append("designation_id", values.designation);
    formData.append("gender", values.gender);
    formData.append("phone", values.phone);
    formData.append("email", values.email);
    formData.append("id_card", values.id_card);
    formData.append("joining_date", values.date);
    formData.append("address", values.address);
    formData.append("salary", values.salary);
    formData.append("id", id);
    try {
      setLoading(true);
      const response = await UserServices.EmployeeUpdate(formData);

      if (response.status === 200) {
        setLoading(false);
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        navigate("/employee")
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  const initialValues = {
    name: name,
    gender: gender,
    address: address,
    date: joiningDate,
    designation: selectedDesignation,
    salary: salary,
    phone: phone,
    email: email,
    id_card: idCard,
  };
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      validationSchema: BranchesUpdt,
      onSubmit: (values, action) => {
        updateEmployee(values, action);
      },
    });
  return (
    <div className="wrapper">
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">Employee</span> Profile
                </h4>
                <p className="text-secondary">Update Employee</p>
              </div>
              <div className="col-md-6 mt-2 d-flex justify-content-end">
                <Link to="/employee" className="button active" type="button">
                  <BiSearchAlt /> View Employee
                </Link>
              </div>
            </div>
            <div className="row mt-3">
              <div className="main-card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row g-3">
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-6">
                            <div className="employe-card-head">
                              01.{" "}
                              <span className="ms-1">
                                {" "}
                                <span className="sub-head">Profile</span> Image
                              </span>
                            </div>
                          </div>
                          <div className="col-6 mt-3">
                            <div className="employe-card-row "></div>
                          </div>

                          <div className="clint-pic-upload mt-3">
                            <div className="avatar-upload">
                              <div className="avatar-edit">
                                <input
                                  type="file"
                                  id="imageUpload"
                                  onClick={(e) => {
                                    e.target.value = null;
                                    setFile("");
                                    setPicture("");
                                  }}
                                  onChange={handleUpload}
                                  accept=".png, .jpg, .jpeg"
                                />
                              </div>
                              <div className="avatar-preview">
                                <div
                                  id="imagePreview"
                                  style={
                                    file === "" && picture === ""
                                      ? prfoImg === ""
                                        ? {
                                            backgroundImage: `url(${placeHolder})`,
                                            overflow: "hidden",
                                          }
                                        : {
                                            overflow: "hidden",
                                            backgroundImage:
                                              'url("' + prfoImg + '")',
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                          }
                                      : {
                                          backgroundImage: "none",
                                          overflow: "hidden",
                                        }
                                  }
                                >
                                  <img
                                    id="output"
                                    alt="profile"
                                    style={
                                      file === "" && picture === ""
                                        ? {
                                            display: "none",
                                          }
                                        : {
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                          }
                                    }
                                  />
                                </div>
                              </div>
                              <div className="profile-pic-btn">
                                <label htmlFor="imageUpload">
                                  <span className="">
                                    {" "}
                                    <IoMdCloudUpload />{" "}
                                  </span>
                                  Upload Image
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="row g-3">
                          <div className="col-4">
                            <div className="employe-card-head">
                              02.{" "}
                              <span className="ms-1">
                                {" "}
                                <span className="sub-head">Personal</span>{" "}
                                Information
                              </span>
                            </div>
                          </div>
                          <div className="col-8 mt-3">
                            <div className="employe-card-row mt-3"></div>
                          </div>
                          <div className="col-6">
                            <div className="form-group custom-group">
                              <label className="form-label">
                                Name
                                <strong className="text-danger">*</strong>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Name"
                                autoComplete="off"
                                name="name"
                                value={values.name}
                                onChange={async (e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.name && touched.name ? (
                                <p className="text-danger">{errors.name}</p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group custom-group">
                              <label className="form-label">
                                Gender{" "}
                                <strong className="text-danger">*</strong>
                              </label>
                              <select
                                className="form-select"
                                name="gender"
                                value={values.gender}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                              >
                                <option disabled={true}>Select gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="others">Others</option>
                              </select>
                              {errors.gender && touched.gender ? (
                                <p className="text-danger">{errors.gender}</p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group custom-group">
                              <label className="form-label">
                                Joining Date{" "}
                                <strong className="text-danger">*</strong>
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                name="date"
                                value={values.date}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.date && touched.date ? (
                                <p className="text-danger">{errors.date}</p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group custom-group">
                              <label className="form-label">
                                Designation
                                <strong className="text-danger">*</strong>
                              </label>
                              {designation.length === 0 ? (
                                <SkeletonTheme height="40px">
                                  <Skeleton count={1} />
                                </SkeletonTheme>
                              ) : (
                                <select
                                  className="form-select"
                                  name="designation"
                                  value={values.designation || ""}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                >
                                  {designation.map((el, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={el.id}
                                        disabled={el.id === ""}
                                      >
                                        {el.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              )}

                              {errors.designation && touched.designation ? (
                                <p className="text-danger">
                                  {errors.designation}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group custom-group">
                              <label className="form-label">Salary</label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Salary"
                                autoComplete="off"
                                name="salary"
                                value={values.salary}
                                onChange={async (e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.salary && touched.salary ? (
                                <p className="text-danger">{errors.salary}</p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="employe-card-head">
                          03.{" "}
                          <span className="ms-1">
                            {" "}
                            <span className="sub-head">Contact</span> Details
                          </span>
                        </div>
                      </div>
                      <div className="col-10 mt-3">
                        <div className="employe-card-row mt-3"></div>
                      </div>
                      <div className="col-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Phone <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Phone"
                            autoComplete="off"
                            name="phone"
                            value={values.phone}
                            onChange={async (e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.phone && touched.phone ? (
                            <p className="text-danger">{errors.phone}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Email
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Email"
                            autoComplete="off"
                            name="email"
                            value={values.email}
                            onChange={async (e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.email && touched.email ? (
                            <p className="text-danger">{errors.email}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group custom-group">
                          <label className="form-label">ID Card</label>
                          <MaskField
                            className="form-control"
                            placeholder="Enter Id Card Number"
                            name="id_card"
                            value={values.id_card}
                            onChange={async (e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            mask="_____-_______-_-"
                            replacement={{ _: /\d/ }}
                          />
                          {errors.id_card && touched.id_card ? (
                            <p className="text-danger">{errors.id_card}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Address
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Address"
                            autoComplete="off"
                            name="address"
                            value={values.address}
                            onChange={async (e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.address && touched.address ? (
                            <p className="text-danger">{errors.address}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-4"></div>
                      {/* <div className="col-2">
                        <div className="employe-card-head">
                          04.{" "}
                          <span className="ms-1">
                            {" "}
                            <span className="sub-head">Perform</span> Actions
                          </span>
                        </div>
                      </div>
                      <div className="col-10 mt-3">
                        <div className="employe-card-row mt-3"></div>
                      </div>
                      <div className="col-4">
                      <label className="form-label">
                            Change Status
                          </label>

                      </div> */}
                      <div className="col-12">
                        <button className="btn1" type="submit">
                          <span>Update</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="main-spin">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  );
}
