import React, { useEffect, useState } from "react";
import { FaHandHoldingUsd } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { AddCart, cartBill } from "../Schemas/Index";
import { useFormik } from "formik";
import useSortableData from "../../hooks/useSortableData";

import { RiDeleteBin5Line } from "react-icons/ri";
import CurrencyFormat from "react-currency-format";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import UserServices from "../../UserServices/UserServices";
import { toast } from "react-toastify";
import $ from "jquery";

export default function ProductsCart() {
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [customer, setCustomer] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);

  const handleIncrement = async (id, type) => {
    const updatedRecords = await myData.filter((record) => record.id === id);

    try {
      if (type === "incre") {
        await UserServices.editCart(id, updatedRecords[0].qty + 1, "quantity");
        getCart();
      } else if (type === "decre" && updatedRecords[0].qty !== 1) {
        await UserServices.editCart(id, updatedRecords[0].qty - 1, "quantity");
        getCart();
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Product out of stock!",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };

  const inputChangeQty = async (id, val) => {
    if (val !== "") {
      const isValidInput =
        /^\d+(\.\d{1,2})?$/.test(val) && parseFloat(val) >= 0;

      if (isValidInput) {
        try {
          await UserServices.editCart(id, val, "quantity");
          getCart();
        } catch (error) {
          toast.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "Product out of stock!",
            {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
      }
    }
  };
  const inputChangediscount = async (id, val) => {
    if (val !== "") {
      const isValidInput =
        /^\d+(\.\d{1,2})?$/.test(val) && parseFloat(val) >= 0;

      if (isValidInput) {
        try {
          await UserServices.editCart(id, val, "discount");
          getCart();
        } catch (error) {
          toast.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "Error in updating discount!",
            {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
      }
    }
  };

  const delRecord = async (id) => {
    try {
      await UserServices.deleteCartItem(id);
      getCart();
    } catch (error) {
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Error in deleting item!",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };
  const cartEmpty = async () => {
    try {
      await UserServices.emptyCart();
      getCart();
    } catch (error) {
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Error in deleting item!",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };

  const Customers = async () => {
    try {
      const responce = await UserServices.fethchCustomer("active");
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setCustomer([{ name: "Select Customer", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setCustomer([{ name: "No Customer Found", id: "" }]);
    }
  };
  const getCart = async () => {
    setTableLoading(true);

    try {
      const responce = await UserServices.fethchCart();
      let myDataarr;

      myDataarr = responce?.data?.data?.item_details;

      const res = myDataarr;
      const results = [];

      res.map((values) => {
        results.push({
          id: values.id,
          code: values.code,
          discount: values.discount,
          price: values.price,
          product_name: values.product_name,
          qty: values.qty,
          tax: values.tax,
          total: values.total,
        });
        return null;
      });
      setTableLoading(false);
      setMyData([...results]);
      setGrandTotal(responce?.data?.data?.total_bill);
    } catch (err) {
      console.log(err);
      setTableLoading(false);

      setGrandTotal(0);
      setMyData([]);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    Customers();
    getCart();
  }, []);
  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");
    const tableOptions = {
      headStyles: { fillColor: [241, 132, 24], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10;
    let pageWidth = doc.internal.pageSize.width;
    let pageHeight = doc.internal.pageSize.height;
    tables.forEach((table, index) => {
      const captionText = table.getAttribute("data-caption");
      let tableHeight = doc.autoTable.previous.finalY - currentY;
      if (currentY + tableHeight + 20 > pageHeight) {
        doc.addPage();
        currentY = 10;
      }
      doc.text(captionText, 10, currentY);
      currentY += 5;
      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20;
      doc.setDrawColor(0);
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY);
      currentY = tableBottomY + 10;
    });
    doc.save("Sales-Cart.pdf");
  };

  const Table = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <>
        {tableLoading ? (
          <SkeletonTheme height="40px">
            <Skeleton count={7} />
          </SkeletonTheme>
        ) : (
          <table
            className="table product-table table-striped"
            id="branches"
            data-caption="Sales Cart"
          >
            <thead>
              <tr>
                <th scope="col" className="no_sort">
                  Sr#
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("code")}
                  className={getClassNamesFor("code")}
                >
                  Code
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("product_name")}
                  className={getClassNamesFor("product_name")}
                >
                  Product
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("price")}
                  className={getClassNamesFor("price")}
                >
                  Price
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("discount")}
                  className={getClassNamesFor("discount")}
                >
                  Discount
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("qty")}
                  className={getClassNamesFor("qty")}
                >
                  Qty
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("tax")}
                  className={getClassNamesFor("tax")}
                >
                  Tax
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("total")}
                  className={getClassNamesFor("total")}
                >
                  Total
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("date")}
                  className={getClassNamesFor("date")}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                items.map((el, index) => (
                  <tr key={index}>
                    <td>{1 ? index + 1 : -1 * +index + 1}</td>
                    <td>{el.code}</td>
                    <td>{el.product_name}</td>
                    <td>
                      <CurrencyFormat
                        value={el.price}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control discount-no"
                        placeholder=""
                        autocomplete="off"
                        defaultValue={el.discount !== null ? el.discount : 0}
                        onBlur={(e) => {
                          inputChangediscount(el.id, e.target.value);
                        }}
                      />
                    </td>
                    <td>
                      <div className="quantity">
                        <span
                          className="quantity__minus"
                          onClick={() => handleIncrement(el.id, "decre")}
                        >
                          <span>-</span>
                        </span>
                        <input
                          name="quantity"
                          type="number"
                          className="quantity__input"
                          defaultValue={el.qty}
                          onBlur={(e) => {
                            inputChangeQty(el.id, e.target.value);
                          }}
                        />
                        <span
                          className="quantity__plus"
                          onClick={() => handleIncrement(el.id, "incre")}
                        >
                          <span>+</span>
                        </span>
                      </div>
                    </td>
                    <td>{el.tax}</td>
                    <td>
                      <CurrencyFormat
                        value={el.total}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                    <td>
                      <div className="d-flex">
                        <span
                          className="action-icons ms-2 text-danger"
                          onClick={() => delRecord(el.id)}
                        >
                          <RiDeleteBin5Line />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="14" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </>
    );
  };

  const cartAdd = async (values, action) => {
    try {
      setLoading(true);
      const response = await UserServices.AddProductToCart(values);

      if (response.status === 200) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        getCart();
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
        toast.error("Product Not Available!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 500) {
        toast.error("Unproccessable content!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 400) {
        toast.error("Product Out of Stock!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  const initialValues = {
    product_code: "",
  };
  const scan = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: AddCart,
    onSubmit: (values, action) => {
      cartAdd(values, action);
    },
  });

  const itemSell = async (values, action) => {
    try {
      setLoading(true);
      const response = await UserServices.soldItem(values);

      if (response.status === 200) {
        setLoading(false);
        toast.success("Sold Successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        getCart();
      }
    } catch (error) {
      setLoading(false);

      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Error in unholding!",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        received: grandTotal,
        date: UserServices.getFormattedDate(),
        customer_id: "",
      },
      enableReinitialize: true,
      validationSchema: cartBill,
      onSubmit: (values, action) => {
        itemSell(values, action);
      },
    });
  var clickEvent = $.Event("click");

  const holdAdd = async (values, action) => {
    try {
      setLoading(true);
      const response = await UserServices.AddProductToHold(values);

      if (response.status === 200) {
        setLoading(false);
        toast.success("Hold Successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        $("#holdeClose").trigger(clickEvent);

        getCart();
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Error in Holding Bill!",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };

  const holder = useFormik({
    initialValues: {
      product_code: "",
    },
    validationSchema: AddCart,
    onSubmit: (values, action) => {
      holdAdd(values, action);
    },
  });

  return (
    <div className="wrapper">
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">Sales</span> Cart
                </h4>
              </div>
              <div className="col-md-6 mt-2 d-flex justify-content-end">
                <Link to="/hold-list" className="button active" type="button">
                  <FaHandHoldingUsd /> Hold List
                </Link>
              </div>
            </div>
            <div className="row mt-3">
              <div className="main-card">
                <div className="card-body">
                  <div className="row">
                    <form onSubmit={scan.handleSubmit} className="col-md-6">
                      <div className="row">
                        <div className="col-md-6 mt-2 heading">
                          <div className="form-group custom-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Product Barcode"
                              autoComplete="off"
                              name="product_code"
                              value={scan.values.product_code}
                              onChange={scan.handleChange}
                              onBlur={scan.handleBlur}
                            />
                            {scan.errors.product_code &&
                            scan.touched.product_code ? (
                              <p className="text-danger">
                                {scan.errors.product_code}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="col-md-6 mt-2 d-flex justify-content-end mb-3">
                      <div
                        className="text-danger import-icons"
                        onClick={() => {
                          generatePdf(document.getElementById("products"));
                        }}
                      >
                        <FaFilePdf />
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Table data={myData} />
                  </div>
                </div>
              </div>
            </div>
            {myData?.length ? (
              <div className="row mt-3">
                <div className="main-card">
                  <div className="card-body">
                    <div className="row">
                      <form onSubmit={handleSubmit} className="col-md-9">
                        <div className="row">
                          <div className="col-md-4 mt-2">
                            <div className="form-group custom-group">
                              <label className="form-label">
                                Customer
                                <strong className="text-danger">*</strong>
                              </label>
                              {customer.length === 0 ? (
                                <SkeletonTheme height="40px">
                                  <Skeleton count={1} />
                                </SkeletonTheme>
                              ) : (
                                <select
                                  className="form-select"
                                  name="customer_id"
                                  value={values.customer_id || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  {customer.map((el, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={el.id}
                                        disabled={el.id === ""}
                                      >
                                        {el.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              )}

                              {errors.customer_id && touched.customer_id ? (
                                <p className="text-danger">
                                  {errors.customer_id}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-4 mt-2">
                            <div className="form-group custom-group">
                              <label className="form-label">
                                Total
                                <strong className="text-danger">*</strong>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Grand Total"
                                autoComplete="off"
                                name="grand_total"
                                value={grandTotal}
                                disabled={true}
                              />
                              {errors.grand_total && touched.grand_total ? (
                                <p className="text-danger">
                                  {errors.grand_total}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-4 mt-2">
                            <div className="form-group custom-group">
                              <label className="form-label">
                                Date
                                <strong className="text-danger">*</strong>
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="Enter Received Amount"
                                autoComplete="off"
                                name="date"
                                value={values.date}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.date && touched.date ? (
                                <p className="text-danger">{errors.date}</p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-4 mt-2">
                            <div className="form-group custom-group">
                              <label className="form-label">
                                Received
                                <strong className="text-danger">*</strong>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Received Amount"
                                autoComplete="off"
                                name="received"
                                value={values.received}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.received && touched.received ? (
                                <p className="text-danger">{errors.received}</p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row mt-5">
                          <div className="col-md-6 d-flex justify-content-between">
                            <button
                              className="btn1"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#holdBill"
                            >
                              <span>Hold Bill</span>
                            </button>
                            <button className="btn1" type="submit">
                              <span>Generate Bill</span>
                            </button>
                            <button
                              className="btn1"
                              type="button"
                              onClick={cartEmpty}
                            >
                              <span>Empty Cart</span>
                            </button>
                          </div>
                        </div>
                      </form>
                      <div className="col-md-3">
                        <div className="grand-total-card">
                          <div className="cart-total d-flex justify-content-between">
                            <span className="fw-bold">Items:</span>
                            <span className="me-2">{myData?.length}</span>
                          </div>
                          <div className="cart-total d-flex justify-content-between">
                            <span className="fw-bold">Discount:</span>
                            <span>
                              <CurrencyFormat
                                value={myData.reduce(
                                  (a, v) => (a = a + v.discount),
                                  0
                                )}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </span>
                          </div>

                          <div className="cart-footer d-flex justify-content-between">
                            <span className="fw-bolder">Amount:</span>
                            <span>
                              <CurrencyFormat
                                value={grandTotal}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="holdBill"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <form onSubmit={holder.handleSubmit}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Hold Bill
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="holdeClose"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group custom-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Holder Name"
                        autoComplete="off"
                        name="product_code"
                        value={holder.values.product_code}
                        onChange={holder.handleChange}
                        onBlur={holder.handleBlur}
                      />
                      {holder.errors.product_code &&
                      holder.touched.product_code ? (
                        <p className="text-danger mb-0">
                          Please enter holder name
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn1" type="submit">
                  <span>Hold Bill</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="main-spin">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  );
}
