/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { IoMdAddCircle } from "react-icons/io";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import { BiSearchAlt } from "react-icons/bi";
import { FaFilePdf } from "react-icons/fa6";
import { BsCardList } from "react-icons/bs";
import Pagination from "react-js-pagination";
import UserServices from "../../UserServices/UserServices";
import { ToastContainer, toast } from "react-toastify";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useFormik } from "formik";
import { updateUser } from "../Schemas/Index";
import $ from "jquery";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function GroupPermission() {
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [exportLink, setExportLink] = useState("");
  const [roles, setRoles] = useState([]);
  const [emailError, setEmailError] = useState("");
  // update
  const [selectedName, setSelectedName] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [recordId, setRecordId] = useState("");

  var clickEvent = $.Event("click");
  const closeButtonRef = useRef(null);
  const getRecord = async (id) => {
    setLoading(true);
    try {
      const responce = await UserServices.getUserInfo(id);
      if (responce.status === 200) {
        let data = responce.data.data.data;

        data.map((values) => {
          setSelectedName(values.name);
          setSelectedEmail(values.email);
          setSelectedRole(values.role_id);
          setRecordId(values.id);
        });
        $("#user").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const getUser = async (page, limit) => {
    setTableLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.fethchUser(newLimit, pageNo);
      let myDataarr;

      if (responce.status === 200) {
        setExportLink(process.env.REACT_APP_API_Link + "exports/export-user");
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            role: values.role,
            name: values.name,
            email: values.email,
            company: values.company,
            branch: values.branch,
            status: values.status,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setTableLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setExportLink();
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setTableLoading(false);
      } else {
        setTableLoading(false);
      }
    }
  };
  const getRoles = async () => {
    try {
      const responce = await UserServices.UserRoles();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setRoles([{ name: "Select Role", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const verifyEmail = async (e) => {
    var check_email = e.target.value;
    try {
      const response = await UserServices.checkEmailUpdate(
        check_email,
        recordId
      );
      if (response.status === 200) {
        setEmailError("");
      }
    } catch (err) {
      if (err.response?.status === 409) {
        setEmailError("Email already exists");
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getUser(currentPage, limit);
    getRoles();
  }, []);

  const delRecord = async (id) => {
    try {
      const responce = await UserServices.userActions(id, "deleted");
      if (responce.status === 200) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getUser(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const makeActive = async (user_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.userActions(user_Id, "active");
      if (responce.status === 200) {
        setLoading(false);
        toast.success("Activated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getUser(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const makeInActive = async (user_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.userActions(user_Id, "inactive");
      if (responce.status === 200) {
        setLoading(false);
        toast.error("Inactivated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getUser(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  let searchTimer;
  const searchRecord = async (e) => {
    clearTimeout(searchTimer);

    searchTimer = setTimeout(async () => {
      var search_name = e.target.value;
      setLoading(true);
      try {
        const response = await UserServices.searchUser(search_name);
        let myDataarr;
        if (response.status === 200) {
          myDataarr = response.data.data;
          const res = myDataarr;
          const results = [];
          res.map((values) => {
            results.push({
              id: values.id,
              role: values.role,
              name: values.name,
              email: values.email,
              company: values.company,
              branch: values.branch,
              status: values.status,
            });
            return null;
          });
          setMyData([...results]);
          setLoading(false);
        }
      } catch (err) {
        if (err.response?.status === 404) {
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 1000);
  };
  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    const captionText = table.getAttribute("data-caption");

    doc.text(captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [241, 132, 24], textColor: 255 },
      styles: { fontSize: 5 },
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("Users.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive ">
              {tableLoading ? (
                <SkeletonTheme height="40px">
                  <Skeleton count={8} />
                </SkeletonTheme>
              ) : (
                <>
                  <table
                    className="table product-table table-striped"
                    id="Users"
                    data-caption="View Users"
                  >
                    <thead>
                      <tr>
                        <th scope="col" className="no_sort">
                          Sr#
                        </th>
                        <th
                          scope="col"
                          onClick={() => requestSort("name")}
                          className={getClassNamesFor("name")}
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          onClick={() => requestSort("company")}
                          className={getClassNamesFor("company")}
                        >
                          Company
                        </th>
                        <th
                          scope="col"
                          onClick={() => requestSort("branch")}
                          className={getClassNamesFor("branch")}
                        >
                          Branch
                        </th>
                        <th
                          scope="col"
                          onClick={() => requestSort("role")}
                          className={getClassNamesFor("role")}
                        >
                          Role
                        </th>
                        <th
                          scope="col"
                          onClick={() => requestSort("email")}
                          className={getClassNamesFor("email")}
                        >
                          Email
                        </th>
                        <th scope="col" className="no_sort">
                          Status
                        </th>
                        <th scope="col" className="no_sort">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.length ? (
                        items.map((el, index) => (
                          <tr key={index}>
                            <td>
                              {currentPage === 1
                                ? index + 1
                                : (currentPage - 1) * limit + index + 1}
                            </td>
                            <td>{el.name}</td>
                            <td>{el.company}</td>
                            <td>{el.branch}</td>
                            <td>{el.role}</td>
                            <td>{el.email}</td>
                            <td>
                              <div className="d-none">{el.status}</div>
                              <div className="check-box mt-2">
                                <input
                                  type="checkbox"
                                  checked={el.status === "active"}
                                  onChange={() => {
                                    if (el.status === "active") {
                                      makeInActive(el.id);
                                    } else {
                                      makeActive(el.id);
                                    }
                                  }}
                                />
                              </div>
                            </td>
                            <td className="d-flex">
                              <div className="d-none">Actions</div>
                              <span
                                className="action-icons"
                                onClick={() => {
                                  getRecord(el.id);
                                }}
                              >
                                <AiOutlineEdit />
                              </span>
                              <span
                                className="action-icons ms-2 text-danger"
                                onClick={() => delRecord(el.id)}
                              >
                                <RiDeleteBin5Line />
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="14"
                            className="text-center text-capitalize"
                          >
                            No record found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="d-flex">
                    <div className=" col-md-1 mb-1 ms-1 d-flex">
                      <div className="input-group limit-input">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => getUser("1", e.target.value)}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                    </div>
                    <div className="ms-auto col-md-3">
                      {totalResults > limit && totalPages > 1 ? (
                        <Pagination
                          activePage={currentPage}
                          itemsCountPerPage={parseInt(limit)}
                          totalItemsCount={totalResults}
                          onChange={(e) => {
                            getUser(e, limit);
                          }}
                          pageRangeDisplayed={3}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
  const UpdateRecord = async (values) => {
    setLoading(true);
    try {
      const response = await UserServices.EditUser(values, recordId);
      if (response.status === 200) {
        setLoading(false);
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        getUser(currentPage, limit);
        closeButtonRef.current.click();
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const initialValues = {
    name: selectedName,
    email: selectedEmail,
    role: selectedRole,
  };
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: updateUser,
      onSubmit: (values) => {
        UpdateRecord(values);
      },
    });
  return (
    <div className="wrapper">
      <ToastContainer />
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">View</span> Users
                </h4>
                <p className="text-secondary">View/Edit Users</p>
              </div>
              <div className="col-md-6 mt-2 d-flex justify-content-end">
                <Link
                  to="/create-permissions"
                  className="button active"
                  type="button"
                >
                  <IoMdAddCircle /> Add Group Permissions
                </Link>
              </div>
            </div>
            <div className="row mt-3">
              <div className="main-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6 mt-2 heading">
                      <form className="me-3">
                        <div className="search-container">
                          <input
                            type="text"
                            placeholder="Search.."
                            onChange={(e) => {
                              searchRecord(e);
                            }}
                          />
                          <div className="search-icon">
                            <BiSearchAlt />
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-6 mt-2 d-flex justify-content-end mb-3">
                      <div
                        className="text-danger import-icons"
                        onClick={() => {
                          generatePdf(document.getElementById("Users"));
                        }}
                      >
                        <FaFilePdf />
                      </div>
                      <a
                        href={exportLink}
                        rel="noreferrer"
                        className="text-success import-icons ms-2"
                      >
                        <BsCardList />
                      </a>
                    </div>
                  </div>
                  <div className="col-12">
                    <Table data={myData} rowsPerPage={limit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="user"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update User
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="card-body">
                  <p className="text-secondary">
                    <strong className="text-danger">!</strong> The Fields With{" "}
                    <strong className="text-danger">*</strong>
                    Must Required Or Fill.
                  </p>
                  <div className="row g-3">
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Name <strong className="text-danger">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter User Name"
                          autoComplete="off"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name ? (
                          <p className="text-danger">{errors.name}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Email <strong className="text-danger">*</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter User Email"
                          autoComplete="off"
                          name="email"
                          value={values.email || ""}
                          onChange={async (e) => {
                            handleChange(e);
                            verifyEmail(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {emailError ? (
                          <p className="text-danger">{emailError}</p>
                        ) : null}
                        {errors.email && touched.email ? (
                          <p className="text-danger">{errors.email}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Role<strong className="text-danger">*</strong>
                        </label>
                        {roles ? (
                          <select
                            className="form-select"
                            name="role"
                            value={values.role || ""}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          >
                            {roles.map((el, index) => {
                              return (
                                <option key={index} value={el.id}>
                                  {el.name}
                                </option>
                              );
                            })}
                          </select>
                        ) : null}
                        {errors.role && touched.role ? (
                          <p className="text-danger">{errors.role}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 ">
                      <button className="btn1" type="submit">
                        <span>Update</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="main-spin">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  );
}
