import React, { useContext, useEffect, useRef, useState } from "react";

import { FaFilePdf } from "react-icons/fa6";

import useSortableData from "../../hooks/useSortableData";

import jsPDF from "jspdf";
import "jspdf-autotable";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import UserServices from "../../UserServices/UserServices";
import { MdPayments } from "react-icons/md";

import { useParams } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import { useFormik } from "formik";
import { billPay, purchasesBillPay } from "../Schemas/Index";
import { toast } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";
import { BsCardList } from "react-icons/bs";

export default function PurchaseOrderPaymentDetail() {
  const { state } = useContext(NoteState);
  const { branch_id, user_id } = state;
  const [myData, setMyData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [orderDetail, setorderDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportLink, setExportLink] = useState("");
  const [payments, setPayments] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const { id } = useParams();
  const closeButtonRef = useRef(null);

  const getPayments = async () => {
    setTableLoading(true);

    try {
      const responce = await UserServices.fetchPurchasePaymentDetail(id);
      let myDataarr;
      setExportLink(
        process.env.REACT_APP_API_Link +
          "exports/purchase-payments-export?user_id=" +
          user_id +
          "&branch_id=" +
          branch_id +
          "&id=" +
          id
      );
      setorderDetail(responce?.data?.data?.order_details);
      myDataarr = responce?.data?.data;
      const res = myDataarr;
      const results = [];
      if (res.payment_datails.length) {
        res.payment_datails.map((values) => {
          results.push({
            received: values.received ? values.received : 0,
            due: values.due ? values.due : 0,
            date: values.date,
            paymentType: values.paymentType,
            transaction_type: values.transaction_type,
          });
          return null;
        });
        setMyData([...results]);
      }

      setTableLoading(false);
    } catch (err) {
      console.log(err);
      setTableLoading(false);

      setMyData([]);
    }
  };
  const getPaymentTypes = async () => {
    try {
      const responce = await UserServices.fethchPaymentTypes("active");
      const res = responce.data?.data?.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setPayments([{ name: "Select Payment", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setPayments([{ name: "No Payment Found", id: "" }]);
    }
  };
  const getTransactionsTypes = async () => {
    try {
      const responce = await UserServices.fethchTransactionTypes("active");

      const res = responce.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value,
          name: value,
        });
        return null;
      });
      setTransactions([{ name: "Select Payment", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setTransactions([{ name: "No Payment Found", id: "" }]);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getPayments();
    getPaymentTypes();
    getTransactionsTypes();
  }, []);
  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");
    const tableOptions = {
      headStyles: { fillColor: [241, 132, 24], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10;
    let pageWidth = doc.internal.pageSize.width;
    let pageHeight = doc.internal.pageSize.height;
    tables.forEach((table, index) => {
      const captionText = table.getAttribute("data-caption");
      let tableHeight = doc.autoTable.previous.finalY - currentY;
      if (currentY + tableHeight + 20 > pageHeight) {
        doc.addPage();
        currentY = 10;
      }
      doc.text(captionText, 10, currentY);
      currentY += 5;
      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20;
      doc.setDrawColor(0);
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY);
      currentY = tableBottomY + 10;
    });
    doc.save("Payment-History.pdf");
  };

  const Table = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <>
        {tableLoading ? (
          <SkeletonTheme height="40px">
            <Skeleton count={7} />
          </SkeletonTheme>
        ) : (
          <table
            className="table product-table table-striped"
            id="branches"
            data-caption="Payment History"
          >
            <thead>
              <tr>
                <th scope="col" className="no_sort">
                  Sr#
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("date")}
                  className={getClassNamesFor("date")}
                >
                  Date
                </th>
                <th scope="col" className="no_sort">
                  Total
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("received")}
                  className={getClassNamesFor("received")}
                >
                  Paid
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("due")}
                  className={getClassNamesFor("due")}
                >
                  Pending
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("paymentType")}
                  className={getClassNamesFor("paymentType")}
                >
                  Payment Type
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("transaction_type")}
                  className={getClassNamesFor("transaction_type")}
                >
                  Transaction Type
                </th>
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                items.map((el, index) => (
                  <tr key={index}>
                    <td>{1 ? index + 1 : -1 * +index + 1}</td>
                    <td>{el.date}</td>
                    <td>
                      <CurrencyFormat
                        value={
                          orderDetail?.TotalBill ? orderDetail?.TotalBill : 0
                        }
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={el.received}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={el.due}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                    <td>{el.paymentType}</td>

                    <td>{el.transaction_type}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </>
    );
  };

  const payBill = async (values, action) => {
    try {
      setLoading(true);
      const response = await UserServices.payNewPurchase(values, id);

      if (response.status === 200) {
        setLoading(false);
        toast.success("Paid Successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        getPayments();
        closeButtonRef.current.click();
      }
    } catch (error) {
      setLoading(false);

      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Error in unholding!",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldError,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      received: "",
      pending: orderDetail?.TotalBill - orderDetail?.Received,
      payment_id: "",
      transaction_type: "",
    },
    enableReinitialize: true,
    validationSchema: purchasesBillPay,
    onSubmit: (values, action) => {
      if (values.received <= values.pending) {
        payBill(values, action);
      } else {
        setFieldError("received", "Maximum value exceed");
      }
    },
  });

  return (
    <div className="wrapper">
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">Payment</span> History
                </h4>
              </div>
              {orderDetail?.PaymentStatus &&
                orderDetail?.PaymentStatus !== "Paid" && (
                  <div className="col-md-6 mt-2 d-flex justify-content-end">
                    <button
                      className="button active"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      <MdPayments /> Pay Bill
                    </button>
                  </div>
                )}
            </div>
            <div className="row mt-3">
              <div className="main-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4 d-flex justify-content-end ms-auto">
                      <div
                        className="text-danger import-icons"
                        onClick={() => {
                          generatePdf(document.getElementById("products"));
                        }}
                      >
                        <FaFilePdf />
                      </div>
                      <a
                        href={exportLink}
                        rel="noreferrer"
                        className="text-success import-icons ms-2"
                      >
                        <BsCardList />
                      </a>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Table data={myData} />
                  </div>
                  <div className="row">
                    <div className="col-lg-3 ms-auto">
                      <div className="grand-total-card">
                        <div className="cart-total d-flex justify-content-between">
                          <span className="fw-bold">Total Bill</span>
                          <span>
                            <CurrencyFormat
                              value={
                                orderDetail?.TotalBill
                                  ? orderDetail?.TotalBill
                                  : 0
                              }
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </span>
                        </div>
                        <div className="cart-total d-flex justify-content-between">
                          <span className="fw-bold">Total Paid</span>
                          <span>
                            <CurrencyFormat
                              value={
                                orderDetail?.Received
                                  ? orderDetail?.Received
                                  : 0
                              }
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </span>
                        </div>
                        <div className="cart-footer d-flex justify-content-between">
                          <span className="fw-bolder">Payment Status</span>
                          <span>
                            {orderDetail?.PaymentStatus
                              ? orderDetail?.PaymentStatus
                              : 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {orderDetail?.PaymentStatus && orderDetail?.PaymentStatus !== "Paid" && (
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  Pay Bill
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeButtonRef}
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-4 mt-2">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Total <strong className="text-danger">*</strong>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Grand Total"
                          autoComplete="off"
                          name="grand_total"
                          value={
                            orderDetail?.TotalBill ? orderDetail?.TotalBill : 0
                          }
                          disabled={true}
                          readOnly={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mt-2">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Pending <strong className="text-danger">*</strong>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Pending Amount"
                          autoComplete="off"
                          name="pending"
                          value={values.pending}
                          disabled={true}
                          readOnly={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mt-2">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Payment
                          <strong className="text-danger">*</strong>
                        </label>
                        {payments.length === 0 ? (
                          <SkeletonTheme height="40px">
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        ) : (
                          <select
                            className="form-select"
                            name="payment_id"
                            value={values.payment_id || ""}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          >
                            {payments.map((el, index) => {
                              return (
                                <option
                                  key={index}
                                  value={el.id}
                                  disabled={el.id === ""}
                                >
                                  {el.name}
                                </option>
                              );
                            })}
                          </select>
                        )}

                        {errors.payment_id && touched.payment_id ? (
                          <p className="text-danger">{errors.payment_id}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4 mt-2">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Transaction Type
                          <strong className="text-danger">*</strong>
                        </label>
                        {transactions.length === 0 ? (
                          <SkeletonTheme height="40px">
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        ) : (
                          <select
                            className="form-select"
                            name="transaction_type"
                            value={values.transaction_type || ""}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          >
                            {transactions.map((el, index) => {
                              return (
                                <option
                                  key={index}
                                  value={el.id}
                                  disabled={el.id === ""}
                                >
                                  {el.name}
                                </option>
                              );
                            })}
                          </select>
                        )}

                        {errors.transaction_type && touched.transaction_type ? (
                          <p className="text-danger">
                            {errors.transaction_type}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-4 mt-2">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Paid <strong className="text-danger">*</strong>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Paid Amount"
                          autoComplete="off"
                          name="received"
                          value={values.received}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.received && touched.received ? (
                          <p className="text-danger">{errors.received}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-3 mt-3">
                      <button className="btn1 mt-md-4" type="submit">
                        <span>Pay Now</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="main-spin">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  );
}
