import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  MdLibraryAdd,
  MdMoreHoriz,
  MdOutlinePhoneInTalk,
} from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import UserServices from "../../UserServices/UserServices";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import moment from "moment";
import ImageLoader from "../Includes/ImageLoader";
import { RiDeleteBin5Line } from "react-icons/ri";
import { toast } from "react-toastify";
import { BiSearchAlt } from "react-icons/bi";
export default function ViewEmployee() {
  const [tableLoading, setTableLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEmployee = async () => {
    setTableLoading(true);
    try {
      const responce = await UserServices.fethchEmployee();
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            name: values.name,
            phone: values.phone,
            status: values.status,
            joining_date: values.joining_date,
            designation: values.designation,
            email: values.email,
            image: values.image,
            salary: values.salary,
            id_card: values.id_card,
            gender: values.gender,
            address: values.address,
          });
          return null;
        });
        setTableLoading(false);
        setMyData([...results]);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTableLoading(false);
        setMyData([]);
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getEmployee();
  }, []);
  let searchTimer;
  const searchRecord = async (e) => {
    clearTimeout(searchTimer);

    searchTimer = setTimeout(async () => {
      var search_employe = e.target.value;
      setLoading(true);
      try {
        const response = await UserServices.searchEmploye(search_employe);
        let myDataarr;
        if (response.status === 200) {
          myDataarr = response.data.data;
          const res = myDataarr;
          const results = [];
          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              phone: values.phone,
              status: values.status,
              joining_date: values.joining_date,
              designation: values.designation,
              email: values.email,
              image: values.image,
              salary: values.salary,
              id_card: values.id_card,
              gender: values.gender,
              address: values.address,
            });
            return null;
          });
          setMyData([...results]);
          setLoading(false);
        }
      } catch (err) {
        if (err.response?.status === 404) {
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 1000);
  };
  const formatPhoneNumber = (phoneNumber) => {
    const countryCode = "+92";
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    if (cleaned.length === 12 && cleaned.startsWith("92")) {
      const areaCode = cleaned.substr(2, 3);
      const firstPart = cleaned.substr(5, 3);
      const secondPart = cleaned.substr(8);
      return `(${countryCode}) ${areaCode}-${firstPart}${secondPart}`;
    }
    return phoneNumber;
  };
  const delRecord = async (id) => {
    try {
      setLoading(true);
      const responce = await UserServices.employeeActions(id, "deleted");
      if (responce.status === 200) {
        setLoading(false);
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getEmployee();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const makeActive = async (user_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.employeeActions(user_Id, "active");
      if (responce.status === 200) {
        setLoading(false);
        toast.success("Activated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getEmployee();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const makeInActive = async (user_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.employeeActions(user_Id, "inactive");
      if (responce.status === 200) {
        setLoading(false);
        toast.error("Inactivated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getEmployee();
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="wrapper">
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">Employee</span> List
                </h4>
              </div>
              <div className="col-md-6 mt-2 d-flex justify-content-end">
                <Link
                  to="/add-employee"
                  className="button active"
                  type="button"
                >
                  <MdLibraryAdd /> Add Employee
                </Link>
              </div>
              <div className="row mt-3">
                <div className="main-card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 mt-2 heading">
                        <div className="d-flex">
                          <form>
                            <div className="search-container ms-2">
                              <input
                                type="text"
                                placeholder="Search.."
                                onChange={(e) => {
                                  searchRecord(e);
                                }}
                              />
                              <div className="search-icon">
                                <BiSearchAlt />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {myData.length ? (
                        myData.map((employee, index) => (
                          <>
                            <div className="col-md-4 mt-3">
                              <div key={index} className="employe-profile-card">
                                <div className="employe-card-head ">
                                  <div className="d-flex justify-content-between">
                                    {tableLoading ? (
                                      <SkeletonTheme
                                        height="22px"
                                        baseColor="#fff"
                                        highlightColor="#c8c8c8"
                                      >
                                        <Skeleton
                                          circle={true}
                                          height={90}
                                          width={90}
                                        />
                                      </SkeletonTheme>
                                    ) : (
                                      <div className="image-section">
                                        <ImageLoader cardImg={employee.image} />
                                        {tableLoading ? null : (
                                          <div
                                            className={
                                              employee.status === "inactive"
                                                ? "employee-status-inactive"
                                                : "employee-status"
                                            }
                                          ></div>
                                        )}
                                      </div>
                                    )}
                                    <div className="view-more-section">
                                      <div className="del-employee">
                                        <span
                                          className="action-icons text-danger"
                                          onClick={() => delRecord(employee.id)}
                                        >
                                          <RiDeleteBin5Line />
                                        </span>
                                      </div>
                                      {tableLoading ? (
                                        <div className="mt-4">
                                          <SkeletonTheme
                                            height="20px"
                                            baseColor="#fff"
                                            highlightColor="#c8c8c8"
                                            width={50}
                                          >
                                            <Skeleton count={1} />
                                          </SkeletonTheme>
                                        </div>
                                      ) : (
                                        <div className="view-more mt-3">
                                          <Link
                                            to={
                                              "/employe-profile/" + employee.id
                                            }
                                          >
                                            <MdMoreHoriz />
                                          </Link>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="heading mt-2 ms-2">
                                    <div className="d-flex justify-content-between">
                                      <Link
                                        to={"/employe-profile/" + employee.id}
                                        className="fw-bolder"
                                      >
                                        {tableLoading ? (
                                          <SkeletonTheme
                                            height="22px"
                                            baseColor="#fff"
                                            highlightColor="#c8c8c8"
                                          >
                                            <Skeleton count={1} />
                                          </SkeletonTheme>
                                        ) : (
                                          <>{employee.name}</>
                                        )}
                                      </Link>
                                      <p className="employe-sr-no">
                                        Sr# {index + 1}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="employe-card-body">
                                  {tableLoading ? (
                                    <SkeletonTheme height="22px">
                                      <Skeleton count={4} />
                                    </SkeletonTheme>
                                  ) : (
                                    <>
                                      <div className="d-flex justify-content-between mt-2">
                                        <div className="body-head">
                                          Designation
                                        </div>
                                        <div className="body-head">
                                          Joining Date
                                        </div>
                                      </div>

                                      <div className="d-flex justify-content-between mt-2">
                                        <div className="body-sub-head">
                                          {employee.designation}
                                        </div>
                                        <div className="body-sub-head">
                                          {moment(employee.joining_date).format(
                                            "ll"
                                          )}
                                        </div>
                                      </div>

                                      <div className="card-detail d-flex mt-2">
                                        <span>
                                          <HiOutlineMail />
                                        </span>
                                        <p className="mt-1 ms-2">
                                          {employee.email}
                                        </p>
                                      </div>
                                      <div className="card-detail d-flex mt-2">
                                        <span>
                                          <MdOutlinePhoneInTalk />
                                        </span>
                                        <p className="mt-1 ms-2">
                                          {formatPhoneNumber(employee.phone)}
                                        </p>
                                        <div className="ms-auto">
                                          {" "}
                                          <div className="check-box mt-2">
                                            <input
                                              type="checkbox"
                                              checked={
                                                employee.status === "active"
                                              }
                                              onChange={() => {
                                                if (
                                                  employee.status === "active"
                                                ) {
                                                  makeInActive(employee.id);
                                                } else {
                                                  makeActive(employee.id);
                                                }
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ))
                      ) : (
                        <div className="col-md-4 mt-3">
                          <div className="employe-profile-card">
                            <div className="employe-card-head ">
                              <div className="d-flex justify-content-between">
                                {tableLoading ? (
                                  <SkeletonTheme
                                    height="22px"
                                    baseColor="#fff"
                                    highlightColor="#C8C8C8"
                                  >
                                    <Skeleton
                                      circle={true}
                                      height={90}
                                      width={90}
                                    />
                                  </SkeletonTheme>
                                ) : (
                                  <SkeletonTheme
                                    height="22px"
                                    baseColor="#fff"
                                    highlightColor="#C8C8C8"
                                  >
                                    <Skeleton
                                      circle={true}
                                      height={90}
                                      width={90}
                                    />
                                  </SkeletonTheme>
                                )}
                                <div className="view-more-section">
                                  <div className="del-employee">
                                    <span className="action-icons text-danger">
                                      <RiDeleteBin5Line />
                                    </span>
                                  </div>
                                  {tableLoading ? (
                                    <div className="mt-4">
                                      <SkeletonTheme
                                        height="20px"
                                        baseColor="#fff"
                                        highlightColor="#C8C8C8"
                                        width={50}
                                      >
                                        <Skeleton count={1} />
                                      </SkeletonTheme>
                                    </div>
                                  ) : (
                                    <SkeletonTheme
                                      height="20px"
                                      baseColor="#fff"
                                      highlightColor="#C8C8C8"
                                      width={50}
                                    >
                                      <Skeleton count={1} />
                                    </SkeletonTheme>
                                  )}
                                </div>
                              </div>
                              <div className="heading mt-2 ms-2">
                                <div className="d-flex justify-content-between">
                                  {tableLoading ? (
                                    <SkeletonTheme
                                      height="22px"
                                      baseColor="#fff"
                                      highlightColor="#C8C8C8"
                                    >
                                      <Skeleton count={1} />
                                    </SkeletonTheme>
                                  ) : (
                                    <SkeletonTheme
                                      height="22px"
                                      baseColor="#fff"
                                      highlightColor="#C8C8C8"
                                    >
                                      <Skeleton count={1} />
                                    </SkeletonTheme>
                                  )}
                                  <p className="employe-sr-no"></p>
                                </div>
                              </div>
                            </div>
                            <div className="employe-card-body">
                              {tableLoading ? (
                                <SkeletonTheme height="22px">
                                  <Skeleton count={4} />
                                </SkeletonTheme>
                              ) : (
                                <SkeletonTheme height="22px">
                                  <Skeleton count={4} />
                                </SkeletonTheme>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="main-spin">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  );
}
