import React from "react";
import { useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
export default function ImageLoader({ cardImg }) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      {isLoading ? (
        <SkeletonTheme height="22px" baseColor="#fff" highlightColor="#c8c8c8">
          <Skeleton circle={true} height={90} width={90} />
        </SkeletonTheme>
      ) : null}
      <img
        src={`${cardImg}`}
        alt="card pic"
        style={isLoading ? { display: "none" } : {}}
        onLoad={() => {
          setIsLoading(false);
        }}
      />
    </>
  );
}
