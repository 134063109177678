import React, { useState, useEffect, useRef, useContext } from "react";
import { useFormik } from "formik";
import { AddLia } from "../Schemas/Index";
import UserServices from "../../UserServices/UserServices";
import { ToastContainer, toast } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";

export default function AddLiabilities() {
  const [loading, setLoading] = useState(false);
  const [categoryError, setCategoryError] = useState("");
  const [category, setCategory] = useState("");
  const [payment, setPayment] = useState("");
  const [banks, setBanks] = useState("");
  const [transaction, setTransaction] = useState("");

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const res = await UserServices.CategoriesValues();
        setCategory(res.data?.data);
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    };

    const fetchPayments = async () => {
      try {
        const res = await UserServices.PaymentValues();
        setPayment(res.data?.data?.data);
      } catch (error) {
        console.error("Error fetching Payment:", error);
      }
    };

    const fetchBank = async () => {
      try {
        const res = await UserServices.BankValues();
        setBanks(res.data?.data);
      } catch (error) {
        console.error("Error fetching Payment:", error);
      }
    };

    const fetchTransaction = async () => {
      try {
        const res = await UserServices.TransactionValues();
        setTransaction(res.data);
      } catch (error) {
        console.error("Error fetching Payment:", error);
      }
    };

    // fetchTransaction();
    // fetchBank();
    // fetchPayments();
    fetchCategory();
  }, []);

  const AddLiabilitiesValues = async (values, action) => {
    if (categoryError === "") {
      try {
        setLoading(true);
        const response = await UserServices.AddLiabilities(values);
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          toast.success("Added Successfully!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          action.resetForm();
        }
      } catch (err) {
        setLoading(false);
        if (err.response.status === 422) {
          toast.error("Unproccessable content!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (err.response.status === 500) {
          toast.error("Unproccessable content!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (err.response.status === 400) {
          toast.error("Unproccessable content!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };

  const initialValues = {
    due_date: UserServices.getFormattedDate(),
    amount: "",
    category_id: "",
    description: "",
    creditor_contact: "",
    creditor_name: "",
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: AddLia,
      onSubmit: (values, action) => {
        AddLiabilitiesValues(values, action);
      },
    });

  return (
    <div className="wrapper">
      <ToastContainer />
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">Add</span> Liabilities
                </h4>
                <p className="text-secondary">Liabilities</p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="main-card">
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <p className="text-secondary">
                      <strong className="text-danger">!</strong> The Field With
                      <strong className="text-danger">*</strong>
                      Must Required Or Fill.
                    </p>
                    <div className="row g-3">
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Creditor Name{" "}
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Amount Name"
                            autoComplete="off"
                            name="creditor_name"
                            value={values.creditor_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                    
                          {errors.creditor_name && touched.creditor_name ? (
                            <p className="text-danger">
                              {errors.creditor_name}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Creditor Contact{" "}
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Creditor Contact Name"
                            autoComplete="off"
                            name="creditor_contact"
                            value={values.creditor_contact}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                    
                          {errors.creditor_contact &&
                          touched.creditor_contact ? (
                            <p className="text-danger">
                              {errors.creditor_contact}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Amount <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Amount Name"
                            autoComplete="off"
                            name="amount"
                            value={values.amount}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                    
                          {errors.amount && touched.amount ? (
                            <p className="text-danger">{errors.amount}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Date <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Enter Expense Name"
                            autoComplete="off"
                            name="due_date"
                            value={values.due_date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                    
                          {errors.due_date && touched.due_date ? (
                            <p className="text-danger">{errors.due_date}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Category
                            <strong className="text-danger">*</strong>
                          </label>
                          <select
                            className="form-select"
                            name="category_id"
                            value={values.category_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Category Type</option>
                            {category &&
                              category.map((item, index) => (
                                <option value={item.id} key={index.id}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                          {errors.category_id && touched.category_id ? (
                            <p className="text-danger">{errors.category_id}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">Description</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Description Name"
                            autoComplete="off"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                    
                          {errors.description && touched.description ? (
                            <p className="text-danger">{errors.description}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 ">
                        <button className="btn1" type="submit">
                          <span>Add</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="main-spin">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  );
}
