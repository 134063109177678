import React, { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import { FaFilePdf } from "react-icons/fa6";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useParams } from "react-router-dom";
import UserServices from "../../UserServices/UserServices";
import { ToastContainer, toast } from "react-toastify";

export default function BillingDetail() {
  const paramsValue = useParams();
  const [orderDetail, setOrderDetail] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await UserServices.billingDetail(paramsValue.id);
        setOrderDetail(res.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data. Please try again.");
      }
    };

    fetchData();
  }, [paramsValue.id]);

  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");
    const tableOptions = {
      headStyles: { fillColor: [241, 132, 24], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10;
    let pageWidth = doc.internal.pageSize.width;
    let pageHeight = doc.internal.pageSize.height;
    tables.forEach((table, index) => {
      const captionText = table.getAttribute("data-caption");
      let tableHeight = doc.autoTable.previous.finalY - currentY;
      if (currentY + tableHeight + 20 > pageHeight) {
        doc.addPage();
        currentY = 10;
      }
      doc.text(captionText, 10, currentY);
      currentY += 5;
      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20;
      doc.setDrawColor(0);
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY);
      currentY = tableBottomY + 10;
    });
    doc.save("Billing_Details.pdf");
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <div>
          <ToastContainer />
        </div>
        <table
          className="table product-table table-striped"
          data-caption="Billing Details"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("product_code")}
                className={getClassNamesFor("product_code")}
              >
                Item Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("product")}
                className={getClassNamesFor("product")}
              >
                code
              </th>
              <th
                scope="col"
                onClick={() => requestSort("price")}
                className={getClassNamesFor("price")}
              >
                Unit Price
              </th>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Quantity
              </th>

              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Discounts
              </th>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Taxes
              </th>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Total Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {orderDetail &&
              orderDetail.order_details.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>
                    <CurrencyFormat
                      value={item.code ? item.code : 0}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </td>

                  <td>
                    <CurrencyFormat
                      value={item.price}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </td>
                  <td>{item.quantity}</td>

                  <td>
                    {/* Assuming you have discount information available */}
                    <CurrencyFormat
                      value={item.discount ? item.discount : 0}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={item.tax}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </td>
                  <td>
                    <CurrencyFormat
                      value={item.total}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </>
    );
  };
  return (
    <div className="wrapper">
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">Al Rasheed</span> Store
                </h4>
                <p className="text-secondary">Managing Director</p>
              </div>
              <div className="col-md-6 mt-2 d-flex justify-content-end">
                <span className="d-head">Bill No:</span> #{orderDetail?.order_details[0]?.id}
              </div>
              <div className="col-md-2">
                <div className="bill-contact-detail">
                  <div className="bill-contact d-flex justify-content-between">
                    <span className="fw-bold">Phone:</span>
                    <span className="">
                      +{orderDetail?.customer_info?.phone}
                    </span>
                  </div>
                  {/* <div className="bill-contact d-flex justify-content-between">
                    <span className="fw-bold">Email:</span>
                    <span className="">example@gmail.com</span>
                  </div> */}
                  <div className="bill-contact d-flex justify-content-between">
                    <span className="fw-bold">Address:</span>
                    <span className="">
                      {orderDetail?.customer_info?.address}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-end mt-5 ms-auto">
                <div
                  className="text-danger import-icons"
                  onClick={() => {
                    generatePdf(document.getElementById("billing"));
                  }}
                >
                  <FaFilePdf />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="main-card">
                <div className="card-body">
                  <div className="row"></div>
                  <div className="table-responsive">
                    <Table data={[]} rowsPerPage={20} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row  mt-3">
              <div className="col-md-6">
                <div className="bill-contact-detail">
                  <div className="bill-contact">
                    <span className="fw-bold">Additional Notes:</span>
                    <span className="ms-2">
                      Any additional information, terms, or conditions related
                      to the biling items.
                      <br />
                      this can include payment terms, return policies, or
                      special instructions.
                    </span>
                  </div>
                  <div className="bill-contact mt-3">
                    <span className="fw-bold">
                      Thank You for business with us!
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 ms-auto">
                <div className="bill-total-detail bg-white px-3 py-2">
                  <div className="bill-contact d-flex justify-content-between">
                    <span className="fw-bold">Subtotal:</span>
                    <span>
                      {" "}
                      <CurrencyFormat
                        value={orderDetail?.order?.subtoTal}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </span>
                  </div>
                  <div className="bill-contact d-flex justify-content-between">
                    <span className="fw-bold">Discount:</span>
                    <span>
                      {" "}
                      <CurrencyFormat
                        value={orderDetail?.order?.discount}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </span>
                  </div>
                  <div className="bill-contact d-flex justify-content-between">
                    <span className="fw-bold">Tax:</span>
                    <span>
                      {" "}
                      <CurrencyFormat
                        value={orderDetail?.order?.tax}
                        displayType={"text"}
                        thousandSpacing={"3"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </span>
                  </div>
                </div>
                <div className="cart-footer d-flex justify-content-between">
                  <span className="fw-bold">Amount:</span>
                  <span>
                    <CurrencyFormat
                      value={orderDetail?.order?.grandTotal}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
