import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { AddProduct } from "../Schemas/Index";
// import { BiCloudUpload } from "react-icons/bi";
import { BiSearchAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import UserServices from "../../UserServices/UserServices";
import { ToastContainer, toast } from "react-toastify";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
export default function AddProducts() {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const [supplier, setSupplier] = useState([]);
  const [category, setCategory] = useState([]);

  const Suppliers = async () => {
    try {
      const responce = await UserServices.fethchSupplier("active");
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setSupplier([{ name: "Select Supplier", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setSupplier([{ name: "No Supplier Found", id: "" }]);
    }
  };
  const Categories = async () => {
    try {
      const responce = await UserServices.fethchCategory("active");
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setCategory([{ name: "Select Category", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setCategory([{ name: "No Category Found", id: "" }]);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    Suppliers();
    Categories();
  }, []);

  const addProduct = async (values, action) => {
    const formData = new FormData();

    if (file !== "") {
      formData.append("image", file);
      formData.append("imageName", file.name);
    }

    formData.append("name", values.name);
    formData.append("category_id", values.category_id);
    formData.append("supplier_id", values.supplier_id);
    formData.append("product_code", values.product_code);
    formData.append("purchase_price", values.purchase_price);
    formData.append("sale_price", values.sale_price);
    formData.append("quantity", values.quantity);
    formData.append("tax", values.tax);
    formData.append("expire_date", values.expire_date);
    try {
      setLoading(true);
      const response = await UserServices.ProductAdd(formData);

      if (response.status === 200) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  const handleUpload = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > 2048 * 1024) {
        // 2048 kilobytes = 2 megabytes
        // The selected file is too large
        toast.error("Image must not be greater than 2MB!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        e.target.value = null; // Clear the input
        setFile("");
      } else {
        // File size is within the allowed limit, you can proceed with setting the file
        setFile(selectedFile);
      }
    }
  };

  const initialValues = {
    name: "",
    category_id: "",
    supplier_id: "",
    product_code: "",
    purchase_price: "",
    sale_price: "",
    quantity: "",
    tax: "0",
    expire_date: "",
  };
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: AddProduct,
      onSubmit: (values, action) => {
        addProduct(values, action);
      },
    });
  return (
    <div className="wrapper">
      <ToastContainer />
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">Product</span> Add
                </h4>
                <p className="text-secondary">Create new product</p>
              </div>
              <div className="col-md-6 mt-2 d-flex justify-content-end">
                <Link
                  to="/product-list"
                  className="button active"
                  type="button"
                >
                  <BiSearchAlt /> View Product
                </Link>
              </div>
            </div>
            <div className="row mt-3">
              <div className="main-card">
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <p className="text-secondary">
                      <strong className="text-danger">!</strong> The Fields With{" "}
                      <strong className="text-danger">*</strong>
                      Must Required Or Fill.
                    </p>
                    <div className="row g-3">
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Product Name{" "}
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Product Name"
                            autoComplete="off"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.name && touched.name ? (
                            <p className="text-danger">{errors.name}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Supplier
                            <strong className="text-danger">*</strong>
                          </label>
                          {supplier.length === 0 ? (
                            <SkeletonTheme height="40px">
                              <Skeleton count={1} />
                            </SkeletonTheme>
                          ) : (
                            <select
                              className="form-select"
                              name="supplier_id"
                              value={values.supplier_id || ""}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                            >
                              {supplier.map((el, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={el.id}
                                    disabled={el.id === ""}
                                  >
                                    {el.name}
                                  </option>
                                );
                              })}
                            </select>
                          )}

                          {errors.supplier_id && touched.supplier_id ? (
                            <p className="text-danger">{errors.supplier_id}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Category
                            <strong className="text-danger">*</strong>
                          </label>
                          {category.length === 0 ? (
                            <SkeletonTheme height="40px">
                              <Skeleton count={1} />
                            </SkeletonTheme>
                          ) : (
                            <select
                              className="form-select"
                              name="category_id"
                              value={values.category_id || ""}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                            >
                              {category.map((el, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={el.id}
                                    disabled={el.id === ""}
                                  >
                                    {el.name}
                                  </option>
                                );
                              })}
                            </select>
                          )}

                          {errors.category_id && touched.category_id ? (
                            <p className="text-danger">{errors.category_id}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Product Code{" "}
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Product Code"
                            autoComplete="off"
                            name="product_code"
                            value={values.product_code}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.product_code && touched.product_code ? (
                            <p className="text-danger">{errors.product_code}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Purchase Price{" "}
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Purchase Price"
                            autoComplete="off"
                            name="purchase_price"
                            value={values.purchase_price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.purchase_price && touched.purchase_price ? (
                            <p className="text-danger">
                              {errors.purchase_price}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Sale Price{" "}
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Sale Price"
                            autoComplete="off"
                            name="sale_price"
                            value={values.sale_price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.sale_price && touched.sale_price ? (
                            <p className="text-danger">{errors.sale_price}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Quantity <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Quantity"
                            autoComplete="off"
                            name="quantity"
                            value={values.quantity}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.quantity && touched.quantity ? (
                            <p className="text-danger">{errors.quantity}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Tax Percentage{" "}
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Tax percentage"
                            autoComplete="off"
                            name="tax"
                            value={values.tax}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.tax && touched.tax ? (
                            <p className="text-danger">{errors.tax}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">Expire Date</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="11-03-2022"
                            name="expire_date"
                            value={values.expire_date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.expire_date && touched.expire_date ? (
                            <p className="text-danger">{errors.expire_date}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">Upload Image</label>
                          <div className="file-input-container">
                            {/* <label htmlFor="fileInput" className="upload-icon">
                              <span className="me-2 upload-image-icon">
                                <BiCloudUpload />
                              </span>
                              <span className="upload-image-label mt-2">
                                Upload Image
                              </span>
                            </label> */}
                            <input
                              id="fileInput"
                              className="form-control"
                              type="file"
                              onClick={(e) => {
                                e.target.value = null;
                                setFile("");
                              }}
                              onChange={handleUpload}
                              accept=".png, .jpg, .jpeg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 ">
                        <div className="col-12 ">
                          <button className="btn1" type="submit">
                            <span>Add</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="main-spin">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  );
}
