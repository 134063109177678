import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BiSearchAlt } from "react-icons/bi";
import { useFormik } from "formik";
import { AddPermission } from "../Schemas/Index";
import "./Permissions.css";
import UserServices from "../../UserServices/UserServices";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";

export default function CreatePermission() {
  const [loading, setLoading] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [roles, setRoles] = useState([]);
  const [menu, setMenu] = useState([]);
  const [subMenu, setSubMenu] = useState([]);
  const [selectMenus, setSelectMenus] = useState([]);

  const handleSelectAllChange = () => {
    setSelectAllChecked(!selectAllChecked);
  };
  const getRoles = async () => {
    try {
      const responce = await UserServices.UserRoles();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        return results.push({
          id: value.id,
          name: value.name,
        });
      });
      setRoles([{ name: "Select Role", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const getMenues = async () => {
    await setMenu([]);
    try {
      const responce = await UserServices.FethchMenues();
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        return results.push({
          id: value.id,
          name: value.name,
        });
      });
      setMenu([...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const getSubMenues = async () => {
    await setSubMenu([]);
    try {
      const responce = await UserServices.FethchSubMenues();
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        return results.push({
          id: value.id,
          name: value.name,
        });
      });
      setSubMenu([...results]);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getRoles();
    getMenues();
    getSubMenues();
  }, []);

  useEffect(() => {
    // Check or uncheck all checkboxes based on the "Select All" checkbox
    const checkboxes = document.querySelectorAll(
      '.permission-options input[type="checkbox"]'
    );
    checkboxes.forEach((checkbox) => {
      checkbox.checked = selectAllChecked;
    });
  }, [selectAllChecked]);

  const selectRole = async (e) => {
    var role_Id = e.target.value;

    try {
      const responce = await UserServices.seletedRole(role_Id);

      const res = responce.data.data;
      const menu = [];

      res.map((value) => {
        menu.push(value.menuId + ":" + value.subMenuId);
        return null;
      });
      console.log(menu);
      await setSelectMenus(menu);

      getMenues();
      getSubMenues();
    } catch (err) {
      console.log(err);
      await setSelectMenus([]);
      getMenues();
      getSubMenues();
    }
  };
  const permissionAdd = async (values, selectedRecords, action) => {
    try {
      setLoading(true);
      const response = await UserServices.AddPermission(
        values,
        selectedRecords
      );
      if (response && response.status === 200) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        // Manually clear the checkboxes
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
      }
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.status === 422) {
        toast.error("Unprocessable content!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response && err.response.status === 500) {
        toast.error("Unprocessable content!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response && err.response.status === 400) {
        toast.error("Unprocessable content!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        // Handle other errors
        console.error(err);
      }
    }
  };
  const UpdateRecord = async (values, selectedRecords, action) => {
    setLoading(true);

    try {
      const response = await UserServices.UpdatePermission(
        values,
        selectedRecords
      );
      if (response.status === 200) {
        setLoading(false);
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        getMenues();
        getSubMenues();
        await setSelectMenus([]);
        action.resetForm();
        // Manually clear the checkboxes
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const initialValues = {
    role: "",
  };
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: AddPermission,
      onSubmit: (values, action) => {
        var selectedRecords = $(".permission-options input:checkbox:checked")
          .map(function () {
            return this.value;
          })
          .get();

        if (selectedRecords.length) {
          selectMenus.length
            ? UpdateRecord(values, selectedRecords, action)
            : permissionAdd(values, selectedRecords, action);
          // const transformedArray = {};
          // selectedRecords.forEach((item) => {
          //   const [key, value] = item.split(":");
          //   if (!transformedArray[key]) {
          //     transformedArray[key] = [];
          //   }
          //   transformedArray[key].push(value);
          // });
          // console.log(transformedArray);
        } else {
          toast.error("Please select permissions!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      },
    });
  return (
    <div className="wrapper">
      <ToastContainer />
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">Create</span> Permissions
                </h4>
                <p className="text-secondary">Manage Create Permissions</p>
              </div>
              <div className="col-md-6 mt-2 d-flex justify-content-end">
                <Link to="/view-user" className="button active" type="button">
                  <BiSearchAlt /> View Group Permissions
                </Link>
              </div>
            </div>
            <div className="row mt-3">
              <div className="main-card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row g-3">
                      <div className="col-12 col-md-4">
                        {roles ? (
                          <div className="form-group custom-group">
                            <label className="form-label">
                              Role<strong className="text-danger">*</strong>
                            </label>
                            {roles ? (
                              <select
                                className="form-select"
                                name="role"
                                value={values.role || ""}
                                onChange={(e) => {
                                  handleChange(e);
                                  selectRole(e);
                                }}
                                onBlur={handleBlur}
                              >
                                {roles.map((el, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={el.id}
                                      disabled={el.id === ""}
                                    >
                                      {el.name}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : null}
                            {errors.role && touched.role ? (
                              <p className="text-danger">{errors.role}</p>
                            ) : null}
                          </div>
                        ) : (
                          <SkeletonTheme height="40px">
                            <Skeleton count={1} />
                          </SkeletonTheme>
                        )}
                      </div>
                      <div className="col-12 select-box">
                        <input
                          id="all"
                          name="all"
                          type="checkbox"
                          value="all"
                          checked={selectAllChecked}
                          onChange={handleSelectAllChange}
                        />
                        <label htmlFor="all">Select All</label>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="container">
                        {menu.length > 0 ? (
                          menu.map((menuItem) => (
                            <div
                              key={menuItem.id}
                              className="col-12 permission-col d-flex"
                            >
                              <div className="permissions">{menuItem.name}</div>

                              <div className="permission-options d-flex">
                                {subMenu.length > 0
                                  ? subMenu.map((subMenuItem) => (
                                      <div
                                        key={subMenuItem.id}
                                        className="options select-box ms-2"
                                      >
                                        <input
                                          className="mt-1"
                                          id={subMenuItem.id}
                                          name={subMenuItem.name}
                                          type="checkbox"
                                          value={
                                            menuItem.id + ":" + subMenuItem.id
                                          }
                                          defaultChecked={
                                            selectMenus?.includes(
                                              menuItem.id.toString() +
                                                ":" +
                                                subMenuItem.id.toString()
                                            )
                                              ? true
                                              : false
                                          }
                                        />
                                        <label
                                          htmlFor={`${subMenuItem.name}_${subMenuItem.id}`}
                                        >
                                          {subMenuItem.name}
                                        </label>
                                      </div>
                                    ))
                                  : null}
                              </div>
                            </div>
                          ))
                        ) : (
                          <SkeletonTheme height="40px">
                            <Skeleton count={5} />
                          </SkeletonTheme>
                        )}
                      </div>

                      <div className="col-12 mt-3">
                        {selectMenus.length ? (
                          <button className="btn1" type="submit">
                            <span>Update</span>
                          </button>
                        ) : (
                          <button className="btn1" type="submit">
                            <span>Add</span>
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="main-spin">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  );
}
