import React, { useState, useEffect, useRef, useContext } from "react";
import { useFormik } from "formik";
import { AddExp } from "../Schemas/Index";
import UserServices from "../../UserServices/UserServices";
import { ToastContainer, toast } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";

export default function AddExpenses() {
  const { state } = useContext(NoteState);
  const [loading, setLoading] = useState(false);
  const [categoryError, setCategoryError] = useState("");
  const [category, setCategory] = useState("");
  const [payment, setPayment] = useState("");
  const [banks, setBanks] = useState("");
  const [transaction, setTransaction] = useState("");

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const res = await UserServices.CategoriesValues();
        setCategory(res.data?.data);
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    };

    const fetchPayments = async () => {
      try {
        const res = await UserServices.PaymentValues();
        setPayment(res.data?.data?.data);
      } catch (error) {
        console.error("Error fetching Payment:", error);
      }
    };

    const fetchBank = async () => {
      try {
        const res = await UserServices.BankValues();
        setBanks(res.data?.data);
      } catch (error) {
        console.error("Error fetching Payment:", error);
      }
    };

    const fetchTransaction = async () => {
      try {
        const res = await UserServices.TransactionValues();
        setTransaction(res.data);
        console.log(res.data);
      } catch (error) {
        console.error("Error fetching Payment:", error);
      }
    };

    fetchTransaction();
    fetchBank();
    fetchPayments();
    fetchCategory();
  }, []);

 
  const AddExpensesValues = async (values, action) => {
    if (categoryError === "") {
      try {
        setLoading(true);
        const response = await UserServices.AddExpenses(values);
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          toast.success("Added Successfully!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          action.resetForm();
        }
      } catch (err) {
        setLoading(false);
        if (err.response.status === 422) {
          toast.error("Unproccessable content!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (err.response.status === 500) {
          toast.error("Unproccessable content!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (err.response.status === 400) {
          toast.error("Unproccessable content!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };

  const initialValues = {
    name: "",
    date: UserServices.getFormattedDate(),
    description: "N/A",
    amount: "",
    category_id: "",
    payment_type_id: "",
    bank_id: "",
    check_number: "",
    transaction_type: "",
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: AddExp,
      onSubmit: (values, action) => {
        AddExpensesValues(values, action);
      },
    });

  return (
    <div className="wrapper">
      <ToastContainer />
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">Add</span> Expense
                </h4>
                <p className="text-secondary">Expense</p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="main-card">
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <p className="text-secondary">
                      <strong className="text-danger">!</strong> The Field With
                      <strong className="text-danger">*</strong>
                      Must Required Or Fill.
                    </p>
                    <div className="row g-3">
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Name <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Expense Name"
                            autoComplete="off"
                            name="name"
                            value={values.name}
                            onChange={async (e) => {
                              handleChange(e);
                              
                            }}
                            onBlur={handleBlur}
                          />
                          {categoryError ? (
                            <p className="text-danger">{categoryError}</p>
                          ) : null}
                          {errors.name && touched.name ? (
                            <p className="text-danger">{errors.name}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Amount <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Amount Name"
                            autoComplete="off"
                            name="amount"
                            value={values.amount}
                            onChange={async (e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          />
                          {categoryError ? (
                            <p className="text-danger">{categoryError}</p>
                          ) : null}
                          {errors.amount && touched.amount ? (
                            <p className="text-danger">{errors.amount}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Date <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Enter Expense Name"
                            autoComplete="off"
                            name="date"
                            value={values.date}
                            onChange={async (e) => {
                              handleChange(e);
                              
                            }}
                            onBlur={handleBlur}
                          />
                          {categoryError ? (
                            <p className="text-danger">{categoryError}</p>
                          ) : null}
                          {errors.date && touched.date ? (
                            <p className="text-danger">{errors.date}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Category <strong className="text-danger">*</strong>
                          </label>
                          <select
                            className="form-select"
                            name="category_id"
                            value={values.category_id}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Category</option>
                            {category &&
                              category.map((item) => (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                          {errors.category_id && touched.category_id ? (
                            <p className="text-danger">{errors.category_id}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Transaction Type
                            <strong className="text-danger">*</strong>
                          </label>
                          <select
                            className="form-select"
                            name="transaction_type"
                            value={values.transaction_type}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Transaction Type</option>
                            {transaction &&
                              transaction.map((item, index) => (
                                <option value={item} key={index}>
                                  {item}
                                </option>
                              ))}
                          </select>
                          {errors.transaction_type &&
                          touched.transaction_type ? (
                            <p className="text-danger">
                              {errors.transaction_type}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Bank <strong className="text-danger"></strong>
                          </label>
                          <select
                            className="form-select"
                            name="bank_id"
                            value={values.bank_id}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          >
                            <option value={-1}>Select Bank</option>

                            {banks &&
                              banks.map((item) => (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                          {errors.bank_id && touched.bank_id ? (
                            <p className="text-danger">{errors.bank_id}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Payment Type{" "}
                            <strong className="text-danger">*</strong>
                          </label>
                          <select
                            className="form-select"
                            name="payment_type_id"
                            value={values.payment_type_id}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          >
                            <option value={-1}>Select Payment Type</option>

                            {payment &&
                              payment.map((item) => (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                          {errors.payment_type_id && touched.payment_type_id ? (
                            <p className="text-danger">
                              {errors.payment_type_id}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Check no <strong className="text-danger"></strong>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Check Number"
                            autoComplete="off"
                            name="check_number"
                            value={values.check_number}
                            onChange={async (e) => {
                              handleChange(e);
                              
                            }}
                            onBlur={handleBlur}
                          />
                          {categoryError ? (
                            <p className="text-danger">{categoryError}</p>
                          ) : null}
                          {errors.check_number && touched.check_number ? (
                            <p className="text-danger">{errors.check_number}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">Description</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Role Description"
                            autoComplete="off"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.description && touched.description ? (
                            <p className="text-danger">{errors.description}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 ">
                        <button className="btn1" type="submit">
                          <span>Add</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="main-spin">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  );
}
