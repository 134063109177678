/* eslint-disable array-callback-return */
import { useFormik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { CompanyAdd } from "../Schemas/Index";
import { useMask } from "@react-input/mask";
import UserServices from "../../UserServices/UserServices";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import { BiSearchAlt } from "react-icons/bi";
import { FaFilePdf } from "react-icons/fa6";
import { BsCardList } from "react-icons/bs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import $ from "jquery";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function AddCompany() {
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [exportLink, setExportLink] = useState("");
  // update
  const [recordId, setRecordId] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");

  var clickEvent = $.Event("click");
  const closeButtonRef = useRef(null);
  const companiesGet = async (id) => {
    setLoading(true);
    try {
      const responce = await UserServices.getComapnyInfo(id);
      if (responce.status === 200) {
        let data = responce.data.data.data;

        data.map((values) => {
          setRecordId(values.id);
          setSelectedName(values.name);
          setPhone(values.phone);
          setAddress(values.address);
          setDescription(values.description);
        });
        $("#roles").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const getCompany = async (page, limit) => {
    setTableLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.fethchCompany(newLimit, pageNo);
      let myDataarr;

      if (responce.status === 200) {
        setExportLink(
          process.env.REACT_APP_API_Link + "exports/export-company"
        );
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            name: values.name,
            phone: values.phone,
            address: values.address,
            status: values.status,
            description: values.description,
          });
          return null;
        });
        setTableLoading(false);
        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTableLoading(false);
        setExportLink();
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getCompany(currentPage, limit);
  }, []);
  const delRecord = async (id) => {
    try {
      const responce = await UserServices.comapnyActions(id, "deleted");
      if (responce.status === 200) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getCompany(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const makeActive = async (user_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.comapnyActions(user_Id, "active");
      if (responce.status === 200) {
        setLoading(false);
        toast.success("Activated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getCompany(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const makeInActive = async (user_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.comapnyActions(user_Id, "inactive");
      if (responce.status === 200) {
        setLoading(false);
        toast.error("Inactivated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getCompany(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  let searchTimer;
  const searchRecord = async (e) => {
    clearTimeout(searchTimer);

    searchTimer = setTimeout(async () => {
      var search_company = e.target.value;
      setLoading(true);
      try {
        const response = await UserServices.SearchComapny(search_company);
        let myDataarr;
        if (response.status === 200) {
          myDataarr = response.data.data;
          const res = myDataarr;
          const results = [];
          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              phone: values.phone,
              address: values.address,
              status: values.status,
              description: values.description,
            });
            return null;
          });
          setMyData([...results]);
          setLoading(false);
        }
      } catch (err) {
        if (err.response?.status === 404) {
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 1000);
  };
  const phone_number = useMask({
    mask: "+92 (___)-_______",
    replacement: { _: /\d/ },
  });
  const CompaniesAdd = async (values, action) => {
    try {
      setLoading(true);
      const response = await UserServices.AddCompanies(values);

      if (response.status === 200) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        getCompany(currentPage, limit);
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
        toast.error("Unproccessable content!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 500) {
        toast.error("Unproccessable content!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 400) {
        toast.error("Unproccessable content!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  const initialValues = {
    name: "",
    phone: "",
    address: "",
    description: "N/A",
  };
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: CompanyAdd,
      onSubmit: (values, action) => {
        CompaniesAdd(values, action);
      },
    });
  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    const captionText = table.getAttribute("data-caption");

    doc.text(captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [241, 132, 24], textColor: 255 },
      styles: { fontSize: 5 },
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("Roles.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive ">
              {tableLoading ? (
                <SkeletonTheme height="40px">
                  <Skeleton count={7} />
                </SkeletonTheme>
              ) : (
                <table
                  className="table product-table table-striped"
                  id="comapnies"
                  data-caption="Companies"
                >
                  <thead>
                    <tr>
                      <th scope="col" className="no_sort">
                        Sr#
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("name")}
                        className={getClassNamesFor("name")}
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("phone")}
                        className={getClassNamesFor("phone")}
                      >
                        Phone
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("address")}
                        className={getClassNamesFor("address")}
                      >
                        Address
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("description")}
                        className={getClassNamesFor("description")}
                      >
                        Description
                      </th>
                      <th scope="col" className="no_sort">
                        Status
                      </th>
                      <th scope="col" className="no_sort">
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {items.length ? (
                      items.map((el, index) => (
                        <tr key={index}>
                          <td>
                            {currentPage === 1
                              ? index + 1
                              : (currentPage - 1) * limit + index + 1}
                          </td>
                          <td>{el.name}</td>
                          <td>{el.phone}</td>
                          <td>{el.address}</td>
                          <td>{el.description}</td>
                          <td>
                            <div className="d-none">{el.status}</div>
                            <div className="check-box mt-2">
                              <input
                                type="checkbox"
                                checked={el.status === "active"}
                                onChange={() => {
                                  if (el.status === "active") {
                                    makeInActive(el.id);
                                  } else {
                                    makeActive(el.id);
                                  }
                                }}
                              />
                            </div>
                          </td>
                          <td className="d-flex">
                            <div className="d-none">Delete</div>
                            <span
                              className="action-icons"
                              onClick={() => {
                                companiesGet(el.id);
                              }}
                            >
                              <AiOutlineEdit />
                            </span>
                            <span
                              className="action-icons ms-2 text-danger"
                              onClick={() => delRecord(el.id)}
                            >
                              <RiDeleteBin5Line />
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="14"
                          className="text-center text-capitalize"
                        >
                          No record found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
              <div className="d-flex">
                <div className=" col-md-1 mb-1 ms-1 d-flex">
                  {myData.length > 0 ? (
                    <div className="input-group limit-input">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => getCompany("1", e.target.value)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  ) : null}
                </div>
                <div className="ms-auto col-md-3">
                  {totalResults > limit && totalPages > 1 ? (
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={parseInt(limit)}
                      totalItemsCount={totalResults}
                      onChange={(e) => {
                        getCompany(e, limit);
                      }}
                      pageRangeDisplayed={3}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const UpdateRecord = async (updateValues) => {
    setLoading(true);

    try {
      const response = await UserServices.UpdateCompanyRecord(
        updateValues,
        recordId
      );
      if (response.status === 200) {
        setLoading(false);
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        getCompany(currentPage, limit);
        closeButtonRef.current.click();
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const updateinitialValues = {
    name: selectedName,
    phone: phone,
    address: address,
    description: description,
  };
  const {
    values: updateValues,
    errors: updateErrors,
    touched: updateTouched,
    handleBlur: updateHandleBlur,
    handleChange: updateHandleChange,
    handleSubmit: updateHandleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: updateinitialValues,
    validationSchema: CompanyAdd,
    onSubmit: (updateValues) => {
      UpdateRecord(updateValues);
    },
  });
  return (
    <div className="wrapper">
      <ToastContainer />
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">Manage</span> Comapnies
                </h4>
                <p className="text-secondary">Add Companies</p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="main-card">
                <form onSubmit={handleSubmit}>
                  <div className="card-body">
                    <p className="text-secondary">
                      <strong className="text-danger">!</strong> The Field With
                      <strong className="text-danger">*</strong>
                      Must Required Or Fill.
                    </p>
                    <div className="row g-3">
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Company Name{" "}
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Company Name"
                            autoComplete="off"
                            name="name"
                            value={values.name}
                            onChange={async (e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.name && touched.name ? (
                            <p className="text-danger">{errors.name}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Phone <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Phone Number"
                            name="phone"
                            value={values.phone}
                            ref={phone_number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.phone && touched.phone ? (
                            <p className="text-danger">{errors.phone}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Address
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Address"
                            autoComplete="off"
                            name="address"
                            value={values.address}
                            onChange={async (e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.address && touched.address ? (
                            <p className="text-danger">{errors.address}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">Description</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Role Description"
                            autoComplete="off"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.description && touched.description ? (
                            <p className="text-danger">{errors.description}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 ">
                        <button className="btn1" type="submit">
                          <span>Add</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">View</span> Companies
                </h4>
                <p className="text-secondary">View/Edit Companies</p>
              </div>
              <div className="main-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6 mt-2 heading">
                      <form className="me-3">
                        <div className="search-container">
                          <input
                            type="text"
                            placeholder="Search.."
                            onChange={(e) => {
                              searchRecord(e);
                            }}
                          />
                          <div className="search-icon">
                            <BiSearchAlt />
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-6 mt-2 d-flex justify-content-end mb-3">
                      <div
                        className="text-danger import-icons"
                        onClick={() => {
                          generatePdf(document.getElementById("comapnies"));
                        }}
                      >
                        <FaFilePdf />
                      </div>
                      <a
                        href={exportLink}
                        rel="noreferrer"
                        className="text-success import-icons ms-2"
                      >
                        <BsCardList />
                      </a>
                    </div>
                  </div>
                  <div className="col-12">
                    <Table data={myData} rowsPerPage={limit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="roles"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update Company
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-3" onSubmit={updateHandleSubmit}>
                <div className="row g-3">
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Name <strong className="text-danger">*</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Comapny Name"
                        autoComplete="off"
                        name="name"
                        value={updateValues.name}
                        onChange={async (e) => {
                          updateHandleChange(e);
                        }}
                        onBlur={updateHandleBlur}
                      />
                      {updateErrors.name && updateTouched.name ? (
                        <p className="text-danger">{updateErrors.name}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Phone <strong className="text-danger">*</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Phone"
                        name="phone"
                        value={updateValues.phone}
                        ref={phone_number}
                        onChange={updateHandleChange}
                        onBlur={updateHandleBlur}
                      />
                      {updateErrors.phone && updateTouched.phone ? (
                        <p className="text-danger">{updateErrors.phone}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Address <strong className="text-danger">*</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Address"
                        autoComplete="off"
                        name="address"
                        value={updateValues.address}
                        onChange={async (e) => {
                          updateHandleChange(e);
                        }}
                        onBlur={updateHandleBlur}
                      />
                      {updateErrors.address && updateTouched.address ? (
                        <p className="text-danger">{updateErrors.address}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Role Description"
                        autoComplete="off"
                        name="description"
                        value={updateValues.description}
                        onChange={updateHandleChange}
                        onBlur={updateHandleBlur}
                      />
                      {updateErrors.description && updateTouched.description ? (
                        <p className="text-danger">
                          {updateErrors.description}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 ">
                    <button className="btn1">
                      <span>Update</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="main-spin">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
          <div className="shadow"></div>
        </div>
      </div>
    </div>
  );
}
