import React from "react";
import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import { MdLibraryAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import { BsCardList } from "react-icons/bs";
import { FaFilePdf } from "react-icons/fa6";
import { BiSearchAlt } from "react-icons/bi";
import jsPDF from "jspdf";
import "jspdf-autotable";
import CurrencyFormat from "react-currency-format";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";

export default function EmployeList() {
  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    const captionText = table.getAttribute("data-caption");

    doc.text(captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [241, 132, 24], textColor: 255 },
      styles: { fontSize: 5 },
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("Employees.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table product-table table-striped"
          id="employee"
          data-caption="Employess"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("emp_id")}
                className={getClassNamesFor("emp_id")}
              >
                Emp ID
              </th>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Joining Date
              </th>
              <th
                scope="col"
                onClick={() => requestSort("email")}
                className={getClassNamesFor("email")}
              >
                Email
              </th>
              <th
                scope="col"
                onClick={() => requestSort("phone")}
                className={getClassNamesFor("phone")}
              >
                Phone
              </th>
              <th
                scope="col"
                onClick={() => requestSort("designation")}
                className={getClassNamesFor("designation")}
              >
                Designation
              </th>
              <th
                scope="col"
                onClick={() => requestSort("team")}
                className={getClassNamesFor("team")}
              >
                Team
              </th>
              <th
                scope="col"
                onClick={() => requestSort("sale")}
                className={getClassNamesFor("sale")}
              >
                Sale
              </th>
              <th
                scope="col"
                onClick={() => requestSort("average")}
                className={getClassNamesFor("average")}
              >
                Average
              </th>
              <th
                scope="col"
                onClick={() => requestSort("total")}
                className={getClassNamesFor("total")}
              >
                Total
              </th>
              <th scope="col" className="no_sort">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>
                <div className="employee-table-img">
                  <img
                    src="https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg"
                    alt="img"
                  />
                  <span className="ms-2">Hammad</span>
                </div>
              </td>
              <td>#12542</td>
              <td>18 Sep 2023</td>
              <td>test@gmail.com</td>
              <td>0300#######</td>
              <td>Sales Man</td>
              <td>Sales</td>
              <td>
                <CurrencyFormat
                  value={200000}
                  displayType={"text"}
                  thousandSpacing={"3"}
                  thousandSeparator={true}
                  prefix={"Rs "}
                  suffix={"/-"}
                  renderText={(value) => <span>{value}</span>}
                />
              </td>
              <td>
                <CurrencyFormat
                  value={40}
                  displayType={"text"}
                  thousandSpacing={"3"}
                  thousandSeparator={true}
                  suffix={"%"}
                  renderText={(value) => <span>{value}</span>}
                />
              </td>
              <td>
                <CurrencyFormat
                  value={500000}
                  displayType={"text"}
                  thousandSpacing={"3"}
                  thousandSeparator={true}
                  prefix={"Rs "}
                  suffix={"/-"}
                  renderText={(value) => <span>{value}</span>}
                />
              </td>
              <td>
                <div className="d-flex">
                  <div className="d-none">Delete</div>
                  <span className="action-icons">
                    <AiOutlineEdit />
                  </span>
                  <span className="action-icons ms-2 text-danger">
                    <RiDeleteBin5Line />
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                <div className="employee-table-img">
                  <img
                    src="https://images.healthshots.com/healthshots/en/uploads/2020/12/08182549/positive-person.jpg"
                    alt="img"
                  />
                  <span className="ms-2">Asma</span>
                </div>
              </td>
              <td>#1892</td>
              <td>10 Jan 2023</td>
              <td>test@gmail.com</td>
              <td>0300#######</td>
              <td>Sales Lead</td>
              <td>Sales</td>
              <td>
                <CurrencyFormat
                  value={900000}
                  displayType={"text"}
                  thousandSpacing={"3"}
                  thousandSeparator={true}
                  prefix={"Rs "}
                  suffix={"/-"}
                  renderText={(value) => <span>{value}</span>}
                />
              </td>
              <td>
                <CurrencyFormat
                  value={80}
                  displayType={"text"}
                  thousandSpacing={"3"}
                  thousandSeparator={true}
                  suffix={"%"}
                  renderText={(value) => <span>{value}</span>}
                />
              </td>
              <td>
                <CurrencyFormat
                  value={700000}
                  displayType={"text"}
                  thousandSpacing={"3"}
                  thousandSeparator={true}
                  prefix={"Rs "}
                  suffix={"/-"}
                  renderText={(value) => <span>{value}</span>}
                />
              </td>
              <td>
                <div className="d-flex">
                  <div className="d-none">Delete</div>
                  <span className="action-icons">
                    <AiOutlineEdit />
                  </span>
                  <span className="action-icons ms-2 text-danger">
                    <RiDeleteBin5Line />
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                <div className="employee-table-img">
                  <img
                    src="https://www.tu-ilmenau.de/unionline/fileadmin/_processed_/0/0/csm_Person_Yury_Prof_Foto_AnLI_Footgrafie__2_.JPG_94f12fbf25.jpg"
                    alt="img"
                  />
                  <span className="ms-2">Ali</span>
                </div>
              </td>
              <td>#1742</td>
              <td>02 Feb 2022</td>
              <td>test@gmail.com</td>
              <td>0300#######</td>
              <td>Accounts</td>
              <td>Finance</td>
              <td>
                <CurrencyFormat
                  value={10000}
                  displayType={"text"}
                  thousandSpacing={"3"}
                  thousandSeparator={true}
                  prefix={"Rs "}
                  suffix={"/-"}
                  renderText={(value) => <span>{value}</span>}
                />
              </td>
              <td>
                <CurrencyFormat
                  value={70}
                  displayType={"text"}
                  thousandSpacing={"3"}
                  thousandSeparator={true}
                  suffix={"%"}
                  renderText={(value) => <span>{value}</span>}
                />
              </td>
              <td>
                <CurrencyFormat
                  value={300000}
                  displayType={"text"}
                  thousandSpacing={"3"}
                  thousandSeparator={true}
                  prefix={"Rs "}
                  suffix={"/-"}
                  renderText={(value) => <span>{value}</span>}
                />
              </td>
              <td>
                <div className="d-flex">
                  <div className="d-none">Delete</div>
                  <span className="action-icons">
                    <AiOutlineEdit />
                  </span>
                  <span className="action-icons ms-2 text-danger">
                    <RiDeleteBin5Line />
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                <div className="employee-table-img">
                  <img
                    src="https://static01.nyt.com/images/2022/05/19/opinion/firstpersonPromo/firstpersonPromo-superJumbo.jpg"
                    alt="img"
                  />
                  <span className="ms-2">Fatima</span>
                </div>
              </td>
              <td>#1988</td>
              <td>09 Dec 2020</td>
              <td>test@gmail.com</td>
              <td>0300#######</td>
              <td>Sales</td>
              <td>GM</td>
              <td>
                <CurrencyFormat
                  value={80000}
                  displayType={"text"}
                  thousandSpacing={"3"}
                  thousandSeparator={true}
                  prefix={"Rs "}
                  suffix={"/-"}
                  renderText={(value) => <span>{value}</span>}
                />
              </td>
              <td>
                <CurrencyFormat
                  value={20}
                  displayType={"text"}
                  thousandSpacing={"3"}
                  thousandSeparator={true}
                  suffix={"%"}
                  renderText={(value) => <span>{value}</span>}
                />
              </td>
              <td>
                <CurrencyFormat
                  value={50000}
                  displayType={"text"}
                  thousandSpacing={"3"}
                  thousandSeparator={true}
                  prefix={"Rs "}
                  suffix={"/-"}
                  renderText={(value) => <span>{value}</span>}
                />
              </td>
              <td>
                <div className="d-flex">
                  <div className="d-none">Delete</div>
                  <span className="action-icons">
                    <AiOutlineEdit />
                  </span>
                  <span className="action-icons ms-2 text-danger">
                    <RiDeleteBin5Line />
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };
  return (
    <div className="wrapper">
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">Employees</span> List
                </h4>
              </div>
              <div className="col-md-6 mt-2 d-flex justify-content-end">
                <Link
                  to="/add-employee"
                  className="button active"
                  type="button"
                >
                  <MdLibraryAdd /> Add Employee
                </Link>
              </div>
              <div className="row mt-3">
                <div className="main-card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 mt-2 heading">
                        <form>
                          <div className="search-container">
                            <input
                              type="text"
                              placeholder="Search.."
                              onChange={(e) => {
                                // searchRecord(e);
                              }}
                            />
                            <div className="search-icon">
                              <BiSearchAlt />
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-md-6 mt-2 d-flex justify-content-end mb-3">
                        <div
                          className="text-danger import-icons"
                          onClick={() => {
                            generatePdf(document.getElementById("products"));
                          }}
                        >
                          <FaFilePdf />
                        </div>
                        <span
                          //   href={exportLink}
                          rel="noreferrer"
                          className="text-success import-icons ms-2"
                        >
                          <BsCardList />
                        </span>
                      </div>
                    </div>
                    <div className="col-12 product">
                      <div className="table-responsive">
                        <Table data={[]} rowsPerPage={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
