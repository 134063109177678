import React, { useEffect, useState } from "react";
import { FaFilePdf } from "react-icons/fa6";
import { BsCardList } from "react-icons/bs";
import { BiSearchAlt } from "react-icons/bi";
import { TbFilterSearch } from "react-icons/tb";
import { FaRegEye } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { AddProduct } from "../Schemas/Index";
import { useFormik } from "formik";
import CurrencyFormat from "react-currency-format";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import UserServices from "../../UserServices/UserServices";
import secureLocalStorage from "react-secure-storage";
import Skeleton from "react-loading-skeleton";
export default function AllBills() {
  const [showRow, setShowRow] = useState(false);
  const [data, setData] = useState("");
  const toggleRow = () => {
    setShowRow(!showRow);
  };

  const API_URL = process.env.REACT_APP_API_Link;

  function HandleExport() {
    try {
      const userId = secureLocalStorage.getItem("userId");
      const branchId = secureLocalStorage.getItem("branchId");
      const url =
        API_URL +
        `exports/all-bill-export?user_id=${userId}&branch_id=${branchId}`;
      window.location.href = url;
    } catch (error) {
      console.error("Export failed:", error);
    }
  }

  useEffect(() => {
    const fetchBills = async () => {
      try {
        const response = await UserServices.getBills();

        setData(response.data?.data?.data);
      } catch (error) {
        console.error("Error fetching bills:", error);
      }
    };
    fetchBills();
  }, []);

  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");
    const tableOptions = {
      headStyles: { fillColor: [241, 132, 24], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10;
    let pageWidth = doc.internal.pageSize.width;
    let pageHeight = doc.internal.pageSize.height;
    tables.forEach((table, index) => {
      const captionText = table.getAttribute("data-caption");
      let tableHeight = doc.autoTable.previous.finalY - currentY;
      if (currentY + tableHeight + 20 > pageHeight) {
        doc.addPage();
        currentY = 10;
      }
      doc.text(captionText, 10, currentY);
      currentY += 5;
      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20;
      doc.setDrawColor(0);
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY);
      currentY = tableBottomY + 10;
    });
    doc.save("All_Bills.pdf");
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table product-table table-striped"
          data-caption="All Bills"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Bill No
              </th>
              <th
                scope="col"
                onClick={() => requestSort("product_code")}
                className={getClassNamesFor("product_code")}
              >
                Date
              </th>
              <th
                scope="col"
                onClick={() => requestSort("product")}
                className={getClassNamesFor("product")}
              >
                Total Items
              </th>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Tax
              </th>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Discount
              </th>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Total Amount
              </th>
              <th scope="col" className="no_sort">
                Details
              </th>
            </tr>
          </thead>
          <tbody>
            {data ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.date}</td>
                  <td>{item.quantity}</td>

                  <td>
                    <CurrencyFormat
                      value={item.tax}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </td>

                  <td>
                    <CurrencyFormat
                      value={item.discount}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </td>

                  <td>
                    {" "}
                    <CurrencyFormat
                      value={item.total_bill}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </td>

                  <td className="fw-bold">
                    <Link to={`/billing-details/${item.discount}`}>
                      <FaRegEye /> More
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                {[...Array(8)].map((_, index) => (
                  <td key={index}>
                    <Skeleton count={10} />
                  </td>
                ))}
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  };

  const initialValues = {};
  const { values, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: AddProduct,
    onSubmit: (values, action) => {
      // updateProduct(values, action);
    },
  });

  return (
    <div className="wrapper">
      <div className="site-main-design">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mt-2 heading">
                <h4>
                  <span className="d-head">All</span> Bills
                </h4>
              </div>
            </div>
            <div className="row mt-3">
              <div className="main-card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6 mt-2 heading">
                      <div className="d-flex">
                        <div onClick={toggleRow}>
                          {showRow ? (
                            <div className="filter-cancel">
                              <RxCross2 />
                            </div>
                          ) : (
                            <div className="filter-icon">
                              <TbFilterSearch />
                            </div>
                          )}
                        </div>
                        <form>
                          <div className="search-container ms-2">
                            <input
                              type="text"
                              placeholder="Search.."
                              onChange={(e) => {
                                //   searchRecord(e);
                              }}
                            />
                            <div className="search-icon">
                              <BiSearchAlt />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-6 mt-2 d-flex justify-content-end mb-3">
                      <div
                        className="text-danger import-icons"
                        onClick={() => {
                          generatePdf(document.getElementById("bills"));
                        }}
                      >
                        <FaFilePdf />
                      </div>
                      <span
                        rel="noreferrer"
                        className="text-success import-icons ms-2"
                        onClick={HandleExport}
                      >
                        <BsCardList />
                      </span>
                    </div>
                  </div>
                  {showRow && (
                    <div className="row mb-3">
                      <div className="col-md-2">
                        <form onSubmit={handleSubmit}>
                          <div className="form-group custom-group">
                            <select
                              className="form-select"
                              name="type"
                              value={values.type}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option disabled={true} defaultValue={true}>
                                Select Filter
                              </option>
                              <option value="available">All Bills</option>
                              <option value="Year">Year</option>
                              <option value="Month">Month</option>
                              <option value="Week">Week</option>
                              <option value="Day">Day</option>
                            </select>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                  <div className="table-responsive">
                    <Table data={data} rowsPerPage={20} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
